import { default as getHomeSetup } from "../customSections/homeSetup.js";
import { previewTextField } from "../lib/formatUtilities.js";
import { anyPathOverlap } from "../lib/hierarchyUtilities.js";
const settingsInputSection = {
    field: "overview",
    icon: "home",
    type: "custom",
    title: "Settings",
    setup: getHomeSetup({ citationNames: true })
};
const fieldFilterConverter = (storedFieldFilter) => {
    // Returns a function that is used to filter which fields are shown in a tab/subtab.
    //
    // storedFieldFilter has a typeChecks array (see StoredFormatInputFieldFilterMacro)
    // if filterArg.type is in the typeChecks array, we return true. else we return false
    return (filterArg) => {
        return storedFieldFilter.typeChecks.some((t) => t == filterArg.type);
    };
};
export class FormatWrapper {
    Title;
    code;
    template;
    defaultInput;
    input;
    output;
    preview;
    settings;
    constructor(stored) {
        this.Title = stored.title;
        this.code = stored.code;
        this.template = stored.template;
        this.defaultInput = stored.defaultInput;
        this.input = [settingsInputSection];
        for (const tab of stored.input) {
            const items = [];
            // Collect subtabs from twiz into legacy compatible array
            const subtabTypes = [];
            for (const subtab of (tab.subtabs ?? []).filter(Boolean)) {
                subtabTypes.push([subtab.key, subtab.title]);
            }
            // If there are subtabs types, and the tab type is a list, add the subtab "type: type" thing
            if (subtabTypes.length > 0 && tab.type === "list") {
                items.push({
                    type: "type",
                    types: subtabTypes
                });
            }
            // For each field in the tab and subtabs
            for (const storedField of tab.items) {
                const runtimeField = {
                    name: storedField.name,
                    label: storedField.label,
                    required: storedField.required
                };
                if (storedField.type !== "string") {
                    // Twiz has a "string" type, legacy has no defined "string" type but treats undefined as if it were "string".
                    // So we want to keep runtimeField.type as undefined if storedField.type is string.
                    runtimeField.type = storedField.type;
                }
                if (storedField.filter != undefined) {
                    // This filter is what determines if a field in a tab is shown
                    // As far as I can tell this is only used to make certain fields appear in certain subtabs and not in others
                    // See docs at `fieldFilterConverter` for more
                    runtimeField.filter = fieldFilterConverter(storedField.filter);
                }
                items.push(runtimeField);
            }
            this.input.push({
                ...tab,
                items
            });
        }
        this.output = {};
        for (const o of stored.output) {
            this.output[o.key] = o;
        }
        this.preview = [];
        for (const p of stored.preview) {
            if (p.macro === "previewTextField") {
                this.preview.push(previewTextField(p.name, {
                    prefix: p.prefix,
                    suffix: p.suffix
                }));
            }
        }
    }
    filter(cvOwner, templateList) {
        return anyPathOverlap(templateList[this.code]?.paths || [], cvOwner.requestedPaths);
    }
}
