import { logError, logInfo } from "../lib/logUtilities.js";
import { createPrintFriendlyDate } from "./dateHelpers.js";
import { logDebug } from "./logUtilities.js";
import { isFunction } from "./utilities.js";
export const SOCKET_MESSAGE = {
  REFRESH: "refresh",
  RETURN_HOME: "Return_Home",
  RECEIVED: "RECEIVED",
  INSUFFICIENT_ACCESS: "Insufficient Access"
};

// Abstraction for callback communication over sockets
export function stream(setIn, outCallback) {
  // Can log time lengths for callbacks

  var listeners = {};
  var lastCallbackNum = 0;
  var that = this;
  this.send = function (label, data, opt_callback, opt_idCallback) {
    lastCallbackNum++;
    if (isFunction(data)) {
      // Can skip the data field if no interesting data to send
      opt_callback = data;
      data = {};
    }
    if (opt_idCallback != null) {
      opt_idCallback(lastCallbackNum);
    }
    if (opt_callback != null) {
      listeners[lastCallbackNum] = [
        {
          callback: opt_callback,
          sendTime: performance.now(),
          sendTime2: Date.now()
        }
      ];
    }

    if (isFunction(label)) {
      label(data);
    } else {
      var message = {
        label: label,
        data: data,
        callbackNum: opt_callback != null ? lastCallbackNum : null
      };
      outCallback(message);
      return message;
    }
  };

  this.listen = async function (label, callback) {
    listeners[label] = listeners[label] || [];
    listeners[label].push({ callback: callback });
  };

  setIn(async function (message) {
    if (listeners[message.label] != null) {
      for (var i = 0; i < listeners[message.label].length; i++) {
        var originalSentTime = listeners[message.label][i].sendTime2;
        var response = await listeners[message.label][i].callback(
          message.data,
          message.callbackNum,
          originalSentTime,
          message.label
        );
        if (response != null && message.callbackNum != null) {
          that.send(message.callbackNum, response);
        }
      }
    }
  });
}

export function registerErrorHandler(backend, args) {
  window.onerror = (msg, url, linenumber, colnumber, error) => {
    logError(
      "window.onerror",
      "Browser Error: " + msg + " at " + url + " line " + linenumber
    );
    let stack = error?.stack || "";

    backend.send("log", {
      message: "browser error",
      path: window.location.pathname,
      url: url,
      error_message: msg,
      cvId: args.cvDef?.id || "",
      cvOwner: args.cvOwner?.id || "",
      userId: args.user?.id || "",
      stack: stack
    });
    return false;
  };
}

export function handleSocketMessage(data) {
  let result = null;

  if (typeof data === "string" || data instanceof String) {
    result = data;
  } else if (data?.message === SOCKET_MESSAGE.RETURN_HOME) {
    result = data.message;
  }

  if (result === SOCKET_MESSAGE.RECEIVED) {
    return true;
  } else if (result === SOCKET_MESSAGE.INSUFFICIENT_ACCESS) {
    alert("Insufficient Access");
    return true;
  } else if (result === SOCKET_MESSAGE.RETURN_HOME) {
    window.location.href = "/";
    return true;
  } else if (result === SOCKET_MESSAGE.REFRESH) {
    window.location.reload();
  } else {
    return false;
  }
}

let loginCheckHandle = null;
let lastKnownExpiration = null;

function handleHello(data) {
  if (handleSocketMessage(data)) {
    logInfo("handleHello", "user no longer logged in");
  } else {
    let expiration = Math.floor(Date.parse(data.expiration) / 1000);
    if (expiration > lastKnownExpiration) {
      lastKnownExpiration = expiration;
    }
    let friendly_expiration = createPrintFriendlyDate(lastKnownExpiration);
    logInfo(
      "handleHello",
      "user is logged in with expiration " + friendly_expiration
    );
  }
}

// variable set when scrolling, passed to the regular session check
class DidActivity {
  constructor() {
    this.didActivity = false;
  }

  checkAndResetActivity() {
    if (this.didActivity) {
      this.didActivity = false;
      return true;
    } else {
      return false;
    }
  }

  setDidActivity() {
    this.didActivity = true;
  }
}

let activity = new DidActivity();
const POLLING_INTERVAL = 30;
const TRIGGER_REFRESH_PROMPT_THRESHOLD = POLLING_INTERVAL; // How many seconds before session expires to trigger the prompt
let timerSecondsRemaining = TRIGGER_REFRESH_PROMPT_THRESHOLD;
let timerInterval = undefined;

function resetRefreshSessionPrompt() {
  timerSecondsRemaining = TRIGGER_REFRESH_PROMPT_THRESHOLD;
  if (timerInterval) clearInterval(timerInterval);
  timerInterval = undefined;
  $(".refresh-im-here").off("click");
  $(".refresh-sign-out").off("click");
  $("#refreshSessionFakeModal").hide();
}

// adding check if scrolling happened, default no
export function initLoginCheck(backend, document, apiVersion) {
  if (!backend) {
    logError(
      "initLoginCheck",
      "Unable to initLoginCheck due to lack of backend"
    );
    return;
  }

  if (document != null && document != undefined) {
    try {
      document.addEventListener(
        "scroll",
        () => {
          activity.setDidActivity();
        },
        true
      );
      document.addEventListener(
        "click",
        () => {
          activity.setDidActivity();
        },
        true
      );
    } catch (e) {
      logError("initLoginCheck", "Error " + e.message);
    }
  }

  loginCheckHandle = setInterval(() => {
    let now = Math.floor(Date.now() / 1000);
    if (lastKnownExpiration != null && now > lastKnownExpiration) {
      logInfo(
        "loginCheckHandle",
        `lastKnownExpiration expired (${createPrintFriendlyDate(
          now
        )} > ${createPrintFriendlyDate(lastKnownExpiration)}`
      );
      window.location.reload();
    } else {
      if (lastKnownExpiration == null) {
        logInfo(
          "loginCheckHandle",
          "setting lastKnownExpiration to " + createPrintFriendlyDate(now)
        );
        lastKnownExpiration = now;
      } else {
        if (
          lastKnownExpiration - now <= TRIGGER_REFRESH_PROMPT_THRESHOLD + 1 &&
          timerInterval === undefined
        ) {
          const fakeModal = $("#refreshSessionFakeModal");
          fakeModal.toggle(true);
          fakeModal.css("display", "flex");
          const timer = $("#refreshSessionTimer");
          $(".refresh-im-here").on("click", () => {
            backend.send("extendSession", null, handleSocketMessage);
            resetRefreshSessionPrompt();
          });
          $(".refresh-sign-out").on("click", () => {
            window.location.href = "/logout";
            resetRefreshSessionPrompt();
          });
          timerInterval = setInterval(() => {
            timer.text(
              `00:${timerSecondsRemaining.toString().padStart(2, "0")}`
            );
            if (timerSecondsRemaining < 0) {
              window.location.href = "/logout";
              resetRefreshSessionPrompt();
            }
            timerSecondsRemaining -= 1;
          }, 1000);
        }
      }
      logDebug("loginCheckHandle", "sending hello");
      if (activity.checkAndResetActivity()) {
        logDebug("loginCheckHandle", "Did user activity, extending session");
        backend.send("extendSession", null, handleSocketMessage);
      }
      backend.send("hello", { apiVersion }, handleHello);
    }
  }, POLLING_INTERVAL * 1000);
}
