import _ from "lodash";
import { escapeHtml, getProp } from "../lib/frontendUtilities.js";
import { findCVNames } from "../lib/pubMed.js";
import { handleSocketMessage } from "../lib/socketUtilities.js";
import { getKey, keys, validEmail, writeList } from "../lib/utilities.js";

export default function (opts) {
  opts = opts || {};

  // areaOfExcellence, import, citationNames

  return function ({
    contextMethods,
    contextConstants,
    contextState,
    backend
  }) {
    let { refreshSection, checkSave } = contextMethods;
    let { user, cvOwner, users, hierarchyNames, delUsers } = contextConstants;
    let { canEdit, newDef, pmidList } = contextState;

    $("#fieldList").append(
      $(
        (!canEdit
          ? ""
          : (opts.import
              ? '<h4 style="margin-top: 5px; margin-right: 10px; display: inline-block;" class="btn btn-default import">' +
                "CV Import" +
                "</h4>"
              : "") +
            '<h4 style="margin-top: 5px; margin-right: 10px; display: inline-block;" class="btn btn-default pubmedImport">' +
            "PubMed Import" +
            "</h4>" +
            '<h4 style="margin-top: 5px; margin-right: 10px; display: inline-block;" class="btn btn-default recentChanges">' +
            "CV Recent Changes" +
            "</h4>") +
          // '<h4 style="margin-top: 5px; margin-right: 10px; display: inline-block;" class="btn btn-default viewHistory">'+
          //   'CV History'+
          // '</h4>'+
          // (!!user.settings.advancedImport ? '<h4 style="margin-top: 5px; margin-right: 10px; display: inline-block;" class="btn btn-default advancedImport">'+
          //   'Excel Import / Export'+
          // '</h4>' : '')
          (opts.stanfordLine == null
            ? ""
            : cvOwner.position != "faculty"
              ? ""
              : '<div style="display: inline-block; width: 100%;">' +
                '  <h4 style="margin-top: 30px; margin-bottom: 10px;display: inline-block;">' +
                '<span class="stanfordLineTitle">Faculty Line</span>' +
                "  </h4>" +
                '  <select style="display: inline-block;width: auto;margin-left: 10px;" class="form-control stanfordLine">' +
                '    <option value="">-- Not Selected --</option>' +
                keys(opts.stanfordLine)
                  .map(function (line) {
                    return (
                      '<option value="' +
                      line +
                      '">' +
                      opts.stanfordLine[line] +
                      "</option>"
                    );
                  })
                  .join("") +
                "  </select>" +
                // '  <span class="glyphicon glyphicon-info-sign autoTip" data-tip="Area%20of%20Excellence%20selection%20is%20used%20for%20departmental%20reports" data-source="" style="margin-left: 8px;"></span>'+
                "</div>") +
          (opts.areaOfExcellence == null
            ? ""
            : cvOwner.position != "faculty"
              ? ""
              : '<div style="display: inline-block; width: 100%;">' +
                '  <h4 style="margin-top: 30px; margin-bottom: 10px;display: inline-block;" data-toggle="popover" data-content="Area of Excellence selection is used for departmental reports">' +
                '<span class="areaOfExcellenceTitle">Area of Excellence</span>' +
                '<span style="margin-left: 2px; display: inline-block; font-size: 15px;" class="glyphicon glyphicon-info-sign"></span>' +
                "  </h4>" +
                '  <select style="display: inline-block;width: auto;margin-left: 10px;" class="form-control areaOfExcellence">' +
                '    <option value="">-- Not Selected --</option>' +
                keys(opts.areaOfExcellence)
                  .map(function (aoe) {
                    return (
                      '<option value="' +
                      aoe +
                      '">' +
                      opts.areaOfExcellence[aoe] +
                      "</option>"
                    );
                  })
                  .join("") +
                "  </select>" +
                // '  <span class="glyphicon glyphicon-info-sign autoTip" data-tip="Area%20of%20Excellence%20selection%20is%20used%20for%20departmental%20reports" data-source="" style="margin-left: 8px;"></span>'+
                "</div>") +
          (!opts.citationNames
            ? ""
            : '<div style="vertical-align: top;margin-right: 5px;margin-top: 15px;display: inline-block;width: 100%;">' +
              '  <h4 style="margin-top: 6px;display: inline-block;vertical-align: top;margin-right: 5px;" data-toggle="popover" data-content="Used for highlighting your CV and importing from PubMed">Citation Name(s)' +
              '    <span style="margin-left: 2px; display: inline-block; font-size: 15px;" class="glyphicon glyphicon-info-sign"></span>' +
              "  </h4>" +
              '  <div id="homeNameList" style="display: inline; margin-left: 5px;">' +
              "  </div>" +
              (!canEdit
                ? ""
                : '<div style="display: inline-block; vertical-align: top;">' +
                  '  <div class="input-group" style="width: 150px;margin-right: 10px;margin-bottom: 5px; vertical-align: top;">' +
                  '    <input style="height: 34px;" id="homeHighlightInput" class="form-control" placeholder="ex: Levine WC">' +
                  '    <span class="input-group-btn">' +
                  '      <button class="btn btn-primary" id="homeAddHighlightName">' +
                  '        <span class="glyphicon glyphicon-plus"></span>' +
                  "      </button>" +
                  "    </span>" +
                  "  </div>" +
                  "</div>" +
                  '<div id="homeSuggestedNames" style="display: inline;"></div>') +
              "</div>" +
              '<div style="vertical-align: top;margin-right: 5px;margin-top: 10px;display: inline-block;width: 100%;">' +
              '<h4 style="margin-top: 6px;display: inline-block;vertical-align: top;margin-right: 5px;" data-toggle="popover" data-content="Used for filtering amongst common last names from PubMed">First Name(s)' +
              '  <span style="margin-left: 2px; display: inline-block;font-size: 15px;" class="glyphicon glyphicon-info-sign"></span>' +
              "</h4>" +
              '<div id="homeFirstNameList" style="display: inline; margin-left: 5px;"></div>' +
              (!canEdit
                ? ""
                : '<div style="display: inline-block; vertical-align: top;">' +
                  '  <div class="input-group" style="width: 150px;margin-right: 10px;margin-bottom: 5px; vertical-align: top;">' +
                  '    <input style="height: 34px;" id="homeHighlightFirstNameInput" class="form-control" placeholder="ex: Wilton">' +
                  '    <span class="input-group-btn">' +
                  '      <button class="btn btn-primary" id="homeAddHighlightFirstName">' +
                  '        <span class="glyphicon glyphicon-plus"></span>' +
                  "      </button>" +
                  "    </span>" +
                  "  </div>" +
                  "</div>" +
                  '<div id="homeSuggestedFirstNames" style="display: inline;"></div>') +
              "</div>") +
          '<h4 style="margin-top: 15px;">Delegates with Access:</h4>' +
          '<div id="delegateList" class="" style="width: 100%; position: relative; display: inline-block; padding-right: 0px;">' +
          cvOwner.delegates
            .map(function (id) {
              var delDef = users[id] || delUsers[id];
              if (delDef == null) return;

              if (delDef.registerCode != null) {
                return (
                  '<div data-section="overview" style="background-color: lightgrey; border-radius: 5px; margin-top: 10px;display: inline-block;width: 100%;">' +
                  '  <div style="color: black; padding-left: 10px;padding-top: 7px;padding-bottom: 7px;width: 100%;">' +
                  '    <span style="display: inline-block;margin-left: 0px;font-size: 16px;width: calc(100% - 60px);margin-top: 0px;margin-bottom: 0px;">' +
                  // '      <span style="display: inline-block; margin-right: 5px;font-weight: 500;">'+escapeHtml(delDef.email)+' (<a target="_blank" href="/register?code='+delDef.registerCode+'">registration link</a> sent)</span>'+
                  '      <span style="display: inline-block; margin-right: 5px;font-weight: 500;">' +
                  escapeHtml(delDef.email) +
                  "</span>" +
                  "    </span>" +
                  '    <span data-id="' +
                  id +
                  '" class="glyphicon glyphicon-remove removeDel" style="float: right;margin-right: 10px;display: inline-block;font-size: 18px;color: black;"></span>' +
                  "  </div>" +
                  "</div>"
                );
              } else {
                return (
                  '<div data-section="overview" style="background-color: lightgrey; border-radius: 5px; margin-top: 10px;display: inline-block;width: 100%;">' +
                  '  <div style="color: black; padding-left: 10px;padding-top: 7px;padding-bottom: 7px;width: 100%;">' +
                  '    <span style="display: inline-block;margin-left: 0px;font-size: 16px;width: calc(100% - 60px);margin-top: 0px;margin-bottom: 0px;">' +
                  '      <span style="display: inline-block; margin-right: 5px;font-weight: 500;">' +
                  escapeHtml(delDef.name) +
                  " (" +
                  escapeHtml(delDef.email) +
                  ")</span>" +
                  "    </span>" +
                  '    <span data-id="' +
                  id +
                  '" class="glyphicon glyphicon-remove removeDel" style="float: right;margin-right: 10px;display: inline-block;font-size: 18px;color: black;"></span>' +
                  "  </div>" +
                  "</div>"
                );
              }
            })
            .join("") +
          '<div data-section="overview" style="background-color: white; margin-top: 10px;">' +
          '  <div style="padding-left: 10px;padding-top: 3px;padding-bottom: 3px;display: inline-block;width: 100%;">' +
          '    <h5 style="display: inline-block;margin-right: 10px;vertical-align: top;margin-top: 8px;">Add a Delegate: </h5>' +
          '    <div style="display: inline-block; vertical-align: top;">' +
          '      <div class="input-group" style="width: 240px;margin-right: 10px;">' +
          '        <input style="height: 34px;" id="newDelegateEmail" name="username" class="form-control" placeholder="Email" required="" autofocus="" aria-describedby="addUser">' +
          '        <span class="input-group-btn">' +
          '          <button class="btn btn-default" id="addDelegate"><span class="glyphicon glyphicon-chevron-right"></span></button>' +
          "        </span>" +
          "      </div>" +
          "    </div>" +
          '    <span style="font-size: 20px; margin-left: 5px;"></span>' +
          "  </div>" +
          "</div>" +
          "</div>" +
          '<h4 style="margin-top: 20px;margin-bottom: 20px;"><span>Email Notification Settings:</span></h4>' +
          "<div>" +
          '  <div data-type="update" class="notify" style="display: block;margin-bottom: 5px;">' +
          '    <div class="btn-group" role="group" style="margin-right: 10px;vertical-align: top;margin-top: 9px;display: inline-block;">' +
          '      <button type="button" data-value="On" class="notifySelect btn btn-success btn-sm">On</button>' +
          '      <button type="button" data-value="Off" class="notifySelect btn btn-default btn-sm">Off</button>' +
          "    </div>" +
          '    <h5 style="display: inline-block;">' +
          "      <span>CV Update Reminder Every</span>" +
          '      <input class="form-control notifyInterval" value="6" style="width: 40px; text-align: center; padding-left: 5px; padding-right: 5px;height: 25px;display: inline-block;"> Months' +
          "    </h5>" +
          '    <div class="dropdown dropdown-menu-left notifyTarget" style="margin-left: 10px;vertical-align: top;display: inline-block;margin-top: 7px;">' +
          '      <button class="btn btn-default dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true" style="float: right;">' +
          '        <span class="caret"></span>' +
          '        <span class="notifyTargetTitle" style="margin-left: 5px;"> Send to Work Email, Personal Email, and Delegates</span>' +
          "      </button>" +
          '      <ul class="dropdown-menu notifyTargetList">' +
          '        <li class="toFilter" data-type="primary">' +
          '          <a href="#">' +
          '            <input type="checkbox" style="margin-right: 10px;vertical-align: top;margin-bottom: 5px;" checked="">' +
          "            Work Email" +
          "          </a>" +
          "        </li>" +
          '        <li class="toFilter" data-type="backup">' +
          '          <a href="#">' +
          '            <input type="checkbox" style="margin-right: 10px;vertical-align: top;margin-bottom: 5px;" checked="">' +
          "            Personal Email" +
          "          </a>" +
          "        </li>" +
          '        <li class="toFilter" data-type="del">' +
          '          <a href="#">' +
          '            <input type="checkbox" style="margin-right: 10px;vertical-align: top;margin-bottom: 5px;" checked="">' +
          "            Delegates" +
          "          </a>" +
          "        </li>" +
          "      </ul>" +
          "    </div>" +
          "  </div>" +
          '  <div data-type="pubmed" class="notify" style="display: block;margin-bottom: 5px;">' +
          '    <div class="btn-group" role="group"' +
          '      style="margin-right: 10px; vertical-align: top; margin-top: 9px;display: inline-block;">' +
          '      <button type="button" data-value="On" class="notifySelect btn btn-success btn-sm">On</button>' +
          '      <button type="button" data-value="Off" class="notifySelect btn btn-default btn-sm">Off</button>' +
          "    </div>" +
          '    <h5 style="display: inline-block;">' +
          "      <span>PubMed Citation Search Every</span>" +
          '      <input class="form-control notifyInterval" value="6" style="width: 40px; text-align: center; padding-left: 5px; padding-right: 5px;height: 25px;display: inline-block;"> Months' +
          "    </h5>" +
          '    <div class="dropdown dropdown-menu-left notifyTarget" style="margin-left: 10px;vertical-align: top;display: inline-block;margin-top: 7px;">' +
          '      <button class="btn btn-default dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true" style="float: right; display: inline-block;">' +
          '        <span class="caret"></span>' +
          '        <span class="notifyTargetTitle" style="margin-left: 5px;"> Send to Work Email, Personal Email, and Delegates</span>' +
          "      </button>" +
          '      <ul class="dropdown-menu notifyTargetList">' +
          '        <li class="toFilter" data-type="primary">' +
          '          <a href="#">' +
          '            <input type="checkbox" style="margin-right: 10px;vertical-align: top;margin-bottom: 5px;" checked="">Work Email' +
          "          </a>" +
          "        </li>" +
          '        <li class="toFilter" data-type="backup">' +
          '          <a href="#">' +
          '            <input type="checkbox" style="margin-right: 10px;vertical-align: top;margin-bottom: 5px;" checked="">Personal Email</a>' +
          "          </li>" +
          '        <li class="toFilter" data-type="del">' +
          '          <a href="#">' +
          '            <input type="checkbox" style="margin-right: 10px;vertical-align: top;margin-bottom: 5px;" checked="">Delegates' +
          "          </a>" +
          "        </li>" +
          "      </ul>" +
          "    </div>" +
          "  </div>" +
          '  <div data-type="newUserNotifications" class="notify" style="display: block;margin-bottom: 5px;">' +
          '    <div class="btn-group" role="group" style="margin-right: 10px;vertical-align: top;margin-top: 9px;display: inline-block;">' +
          '      <button type="button" data-value="On" class="notifySelect btn btn-success btn-sm">On</button>' +
          '      <button type="button" data-value="Off" class="notifySelect btn btn-default btn-sm">Off</button>' +
          "    </div>" +
          '    <h5 id="newUserNotificationsTitle" style="display: inline;margin-top: 15px;line-height: 45px;">New User Notifications</h5>' +
          '    <div class="dropdown dropdown-menu-left notifyTarget" style="margin-left: 10px;vertical-align: top;display: inline-block;margin-top: 7px;">' +
          '      <button class="btn btn-default dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true" style="float: right; display: inline-block;">' +
          '        <span class="caret"></span>' +
          '        <span class="notifyTargetTitle" style="margin-left: 5px;"> Send to Work Email and Delegates</span>' +
          "      </button>" +
          '      <ul class="dropdown-menu notifyTargetList">' +
          '        <li class="toFilter" data-type="primary">' +
          '          <a href="#">' +
          '            <input type="checkbox" style="margin-right: 10px;vertical-align: top;margin-bottom: 5px;" checked="">Work Email' +
          "          </a>" +
          "        </li>" +
          '        <li class="toFilter" data-type="backup">' +
          '          <a href="#">' +
          '            <input type="checkbox" style="margin-right: 10px;vertical-align: top;margin-bottom: 5px;" checked="">Personal Email' +
          "          </a>" +
          "        </li>" +
          "      </ul>" +
          "    </div>" +
          "  </div>" +
          "</div>" +
          '<h5 style="margin-top: 30px;">Got feedback? Email <a target="_blank" href="mailto:support@easycv.me">Support@EasyCV.me</a></h5>' // <p style="margin-top:10px;" class="copyright"></p>
      )
    );

    $("[data-toggle=popover]").popover({ trigger: "hover" });

    if (opts.citationNames) {
      $("#homeNameList").empty();
      var citationNames = getKey(newDef, "citationName", "")
        .split(";")
        .filter((n) => n != "");

      newDef.citationName = citationNames.join(";");
      citationNames.map(function (homeName) {
        $("#homeNameList").append(
          $(
            "" +
              '<div class="btn btn-default ' +
              (!canEdit ? "" : "removeHomeName") +
              '" data-name="' +
              escapeHtml(homeName) +
              '" style="margin-bottom: 5px;margin-right: 5px;">' +
              "  <span>" +
              escapeHtml(homeName) +
              "</span>" +
              (!canEdit
                ? ""
                : '  <span class="glyphicon glyphicon-remove" style="margin-left: 5px;"></span>') +
              "</div>"
          )
        );
      });

      if (canEdit) {
        $(".removeHomeName")
          .unbind("click")
          .click(function () {
            var name = getProp(this, "name");
            newDef.citationName = getKey(newDef, "citationName", "")
              .split(";")
              .filter((n) => n != name)
              .join(";");
            checkSave();
            refreshSection();
          });

        var possibleNames = findCVNames(newDef, pmidList).possibleNames;
        $("#homeSuggestedNames").toggle(possibleNames.length > 0);
        $("#homeSuggestedNames").empty();
        possibleNames.map(function (name) {
          $("#homeSuggestedNames").append(
            $(
              '<div class="btn btn-primary addSearchName" data-name="' +
                escapeHtml(name) +
                '" style="margin-bottom: 5px;margin-right: 5px;">' +
                '  <span class="glyphicon glyphicon-plus" style="margin-right: 5px;"></span><span>' +
                escapeHtml(name) +
                "</span> " +
                "</div>"
            )
          );
        });
        $("#homeSuggestedNames")
          .find(".addSearchName")
          .click(function () {
            var name = getProp(this, "name");
            newDef["citationName"] = newDef["citationName"]
              .split(";")
              .concat([name])
              .join(";");
            checkSave();
            refreshSection();
          });
      }

      // -- First Names --

      $("#homeFirstNameList").empty();
      var firstNames = getKey(newDef, "firstNames", "")
        .split(";")
        .filter((n) => n != "");

      var fullName = cvOwner.name.split(",")[0];
      var names = fullName.split(/ |\./).filter(function (name) {
        return name.length > 1;
      });
      if (firstNames.length == 0 && names.length > 0) firstNames.push(names[0]);

      newDef.firstNames = firstNames.join(";");
      firstNames.map(function (homeName) {
        $("#homeFirstNameList").append(
          $(
            "" +
              '<div class="btn btn-default ' +
              (!canEdit ? "" : "removeHomeFirstName") +
              '" data-name="' +
              escapeHtml(homeName) +
              '" style="margin-bottom: 5px;margin-right: 5px;">' +
              "  <span>" +
              escapeHtml(homeName) +
              "</span>" +
              (!canEdit
                ? ""
                : '  <span class="glyphicon glyphicon-remove" style="margin-left: 5px;"></span>') +
              "</div>"
          )
        );
      });

      if (canEdit) {
        $(".removeHomeFirstName")
          .unbind("click")
          .click(function () {
            var name = getProp(this, "name");
            newDef.firstNames = getKey(newDef, "firstNames", "")
              .split(";")
              .filter((n) => n != name)
              .join(";");
            checkSave();
            refreshSection();
          });

        var possibleFirstNames = findCVNames(
          newDef,
          pmidList
        ).possibleFirstNames;
        $("#homeSuggestedFirstNames").toggle(possibleFirstNames.length > 0);
        $("#homeSuggestedFirstNames").empty();
        possibleFirstNames.map(function (name) {
          $("#homeSuggestedFirstNames").append(
            $(
              '<div class="btn btn-primary addSearchFirstName" data-name="' +
                escapeHtml(name) +
                '" style="margin-bottom: 5px;margin-right: 5px;">' +
                '  <span class="glyphicon glyphicon-plus" style="margin-right: 5px;"></span><span>' +
                escapeHtml(name) +
                "</span> " +
                "</div>"
            )
          );
        });
        $("#homeSuggestedFirstNames")
          .find(".addSearchFirstName")
          .click(function () {
            var name = getProp(this, "name");
            newDef["firstNames"] = newDef["firstNames"]
              .split(";")
              .concat([name])
              .join(";");
            checkSave();
            refreshSection();
          });
      }
    }

    if (opts.areaOfExcellence != null) {
      $(".areaOfExcellence").toggle(canEdit);
      var areaOfExcellence = getKey(
        getKey(newDef, "narrative", {}),
        "area",
        ""
      );
      $(".areaOfExcellenceTitle").text(
        "Area of Excellence" +
          (canEdit
            ? " "
            : ": " + (opts.areaOfExcellence[areaOfExcellence] || ""))
      );
      $(".areaOfExcellence").val(areaOfExcellence);
      $(".areaOfExcellence")
        .unbind("change")
        .on("change", function () {
          var area = $(this).val();
          newDef.narrative.area = area;
          refreshSection();
        });
    }

    if (opts.stanfordLine != null) {
      $(".stanfordLine").prop("disabled", !canEdit);
      var stanfordLine = getKey(
        getKey(newDef, "narrative", {}),
        "stanfordLine",
        ""
      );
      $(".stanfordLineTitle").text("Faculty Line");
      $(".stanfordLine").val(stanfordLine);
      $(".stanfordLine")
        .unbind("change")
        .on("change", function () {
          var area = $(this).val();
          newDef.narrative.stanfordLine = area;
          refreshSection();
        });
    }

    // Email Notifications
    // TODO Remove all these defaults when refactoring .lean() calls
    const pubmedInfo = _.get(cvOwner, "notifications.pubmed", {
      interval: 1,
      on: true
    });
    const pubmedContactPrimary = _.get(pubmedInfo, "target.primary", true);
    const pubmedContactBackup = _.get(pubmedInfo, "target.backup", true);
    const pubmedContactDelegates = _.get(pubmedInfo, "target.del", true);

    $('.notify[data-type="pubmed"]')
      .find(".notifyInterval")
      .val(pubmedInfo.interval);
    $('.notify[data-type="pubmed"]')
      .find(".notifyTarget")
      .toggle(!!pubmedInfo.on);

    var sendToList = ["primary", "backup", "del"]
      .filter(function (n) {
        return !!pubmedInfo.target[n];
      })
      .map(function (n) {
        return {
          primary: "Work Email",
          backup: "Personal Email",
          del: "Delegates"
        }[n];
      });
    $('.notify[data-type="pubmed"]')
      .find(".notifyTargetTitle")
      .text(
        "Send to " + (sendToList.length == 0 ? "None" : writeList(sendToList))
      );
    var notifyTargetFilters = $('.notify[data-type="pubmed"]');
    notifyTargetFilters
      .find('.toFilter[data-type="primary"]')
      .find('[type="checkbox"]')
      .prop("checked", pubmedContactPrimary);
    notifyTargetFilters
      .find('.toFilter[data-type="backup"]')
      .find('[type="checkbox"]')
      .prop("checked", pubmedContactBackup);
    notifyTargetFilters
      .find('.toFilter[data-type="del"]')
      .find('[type="checkbox"]')
      .prop("checked", pubmedContactDelegates);
    notifyTargetFilters
      .find(".toFilter")
      .unbind("click")
      .click(function (e) {
        var type = getProp(this, "type");
        pubmedInfo.target[type] = !pubmedInfo.target[type];
        backend.send(
          "update",
          { id: cvOwner.id, def: cvOwner, from: user },
          function (data) {
            if (handleSocketMessage(data)) {
              return;
            }
          }
        );
        refreshSection();
        e.stopPropagation();
      });

    $('.notify[data-type="pubmed"]')
      .find('.notifySelect[data-value="On"]')
      .toggleClass("btn-success", pubmedInfo.on)
      .toggleClass("btn-default", !pubmedInfo.on);
    $('.notify[data-type="pubmed"]')
      .find('.notifySelect[data-value="Off"]')
      .toggleClass("btn-danger", !pubmedInfo.on)
      .toggleClass("btn-default", pubmedInfo.on);

    $('.notify[data-type="pubmed"]')
      .find(".notifyInterval")
      .unbind("input")
      .on("input", function () {
        var num = parseInt($(this).val());
        var numErr = isNaN(num) || num <= 0;
        $(this).parent().toggleClass("has-error", numErr);
        if (numErr) return;
        pubmedInfo.interval = num;
        backend.send(
          "update",
          { id: cvOwner.id, def: cvOwner, from: user },
          function (data) {
            if (handleSocketMessage(data)) {
              return;
            }
          }
        );
      });

    $('.notify[data-type="pubmed"]')
      .find(".notifySelect")
      .unbind("click")
      .click(function () {
        var type = getProp(this, "value");
        pubmedInfo.on = type == "On";
        backend.send(
          "update",
          { id: cvOwner.id, def: cvOwner, from: user },
          function (data) {
            if (handleSocketMessage(data)) {
              return;
            }
          }
        );
        refreshSection();
      });

    // TODO Remove all these defaults when refactoring .lean() calls
    const updateInfo = _.get(cvOwner, "notifications.update", {
      interval: 1,
      on: true
    });
    const updateContactPrimary = _.get(updateInfo, "target.primary", true);
    const updateContactBackup = _.get(updateInfo, "target.backup", true);
    const updateContactDelegates = _.get(updateInfo, "target.del", true);

    getKey(updateInfo, "target", { primary: true, backup: true, del: true });

    $('.notify[data-type="update"]')
      .find(".notifyInterval")
      .val(updateInfo.interval);
    $('.notify[data-type="update"]')
      .find(".notifyTarget")
      .toggle(!!updateInfo.on);

    var sendToList = ["primary", "backup", "del"]
      .filter(function (n) {
        return !!updateInfo.target[n];
      })
      .map(function (n) {
        return {
          primary: "Work Email",
          backup: "Personal Email",
          del: "Delegates"
        }[n];
      });
    $('.notify[data-type="update"]')
      .find(".notifyTargetTitle")
      .text(
        "Send to " + (sendToList.length == 0 ? "None" : writeList(sendToList))
      );
    var notifyTargetFilters = $('.notify[data-type="update"]');
    notifyTargetFilters
      .find('.toFilter[data-type="primary"]')
      .find('[type="checkbox"]')
      .prop("checked", updateContactPrimary);
    notifyTargetFilters
      .find('.toFilter[data-type="backup"]')
      .find('[type="checkbox"]')
      .prop("checked", updateContactBackup);
    notifyTargetFilters
      .find('.toFilter[data-type="del"]')
      .find('[type="checkbox"]')
      .prop("checked", updateContactDelegates);
    notifyTargetFilters
      .find(".toFilter")
      .unbind("click")
      .click(function (e) {
        var type = getProp(this, "type");
        updateInfo.target[type] = !updateInfo.target[type];
        backend.send(
          "update",
          { id: cvOwner.id, def: cvOwner, from: user },
          function (data) {
            if (handleSocketMessage(data)) {
              return;
            }
          }
        );
        refreshSection();
        e.stopPropagation();
      });

    $('.notify[data-type="update"]')
      .find('.notifySelect[data-value="On"]')
      .toggleClass("btn-success", updateInfo.on)
      .toggleClass("btn-default", !updateInfo.on);
    $('.notify[data-type="update"]')
      .find('.notifySelect[data-value="Off"]')
      .toggleClass("btn-danger", !updateInfo.on)
      .toggleClass("btn-default", updateInfo.on);

    $('.notify[data-type="update"]')
      .find(".notifyInterval")
      .unbind("input")
      .on("input", function () {
        var num = parseInt($(this).val());
        var numErr = isNaN(num) || num <= 0;
        $(this).parent().toggleClass("has-error", numErr);
        if (numErr) return;
        updateInfo.interval = num;
        backend.send(
          "update",
          { id: cvOwner.id, def: cvOwner, from: user },
          function (data) {
            if (handleSocketMessage(data)) {
              return;
            }
          }
        );
      });

    $('.notify[data-type="update"]')
      .find(".notifySelect")
      .unbind("click")
      .click(function () {
        var type = getProp(this, "value");
        updateInfo.on = type == "On";
        backend.send(
          "update",
          { id: cvOwner.id, def: cvOwner, from: user },
          function (data) {
            if (handleSocketMessage(data)) {
              return;
            }
          }
        );
        refreshSection();
      });

    var newUserNotifications =
      !!cvOwner.settings.newUserNotifications &&
      (cvOwner.settings.accessFilters || []).length > 0;
    $('.notify[data-type="newUserNotifications"]').toggle(newUserNotifications);
    if (newUserNotifications) {
      $("#newUserNotificationsTitle").text(
        "New User Notifications for " +
          writeList(
            (cvOwner.settings.accessFilters || []).map(function (filter) {
              return filter.length == 0
                ? "All"
                : filter.split(",").map(function (n) {
                    return hierarchyNames[n];
                  });
            })
          )
      );

      // TODO Remove all these defaults when refactoring .lean() calls
      const newUserInfo = _.get(cvOwner, "notifications.newUserNotifications", {
        interval: 1,
        on: true
      });
      const newUserContactPrimary = _.get(updateInfo, "target.primary", true);
      const newUserContactBackup = _.get(updateInfo, "target.backup", true);

      $('.notify[data-type="newUserNotifications"]')
        .find(".notifyTarget")
        .toggle(!!newUserInfo.on);

      var sendToList = ["primary", "backup"]
        .filter(function (n) {
          return !!newUserInfo.target[n];
        })
        .map(function (n) {
          return {
            primary: "Work Email",
            backup: "Personal Email"
          }[n];
        });
      $('.notify[data-type="newUserNotifications"]')
        .find(".notifyTargetTitle")
        .text(
          "Send to " + (sendToList.length == 0 ? "None" : writeList(sendToList))
        );
      var notifyTargetFilters = $('.notify[data-type="newUserNotifications"]');
      notifyTargetFilters
        .find('.toFilter[data-type="primary"]')
        .find('[type="checkbox"]')
        .prop("checked", newUserContactPrimary);
      notifyTargetFilters
        .find('.toFilter[data-type="backup"]')
        .find('[type="checkbox"]')
        .prop("checked", newUserContactBackup);
      notifyTargetFilters
        .find(".toFilter")
        .unbind("click")
        .click(function (e) {
          var type = getProp(this, "type");
          newUserInfo.target[type] = !newUserInfo.target[type];
          backend.send(
            "update",
            { id: cvOwner.id, def: cvOwner, from: user },
            function (data) {
              if (handleSocketMessage(data)) {
                return;
              }
            }
          );
          refreshSection();
          e.stopPropagation();
        });

      $('.notify[data-type="newUserNotifications"]')
        .find('.notifySelect[data-value="On"]')
        .toggleClass("btn-success", newUserInfo.on)
        .toggleClass("btn-default", !newUserInfo.on);
      $('.notify[data-type="newUserNotifications"]')
        .find('.notifySelect[data-value="Off"]')
        .toggleClass("btn-danger", !newUserInfo.on)
        .toggleClass("btn-default", newUserInfo.on);

      $('.notify[data-type="newUserNotifications"]')
        .find(".notifySelect")
        .unbind("click")
        .click(function () {
          var type = getProp(this, "value");
          newUserInfo.on = type == "On";
          backend.send(
            "update",
            { id: cvOwner.id, def: cvOwner, from: user },
            function (data) {
              if (handleSocketMessage(data)) {
                return;
              }
            }
          );
          refreshSection();
        });
    }

    $("#addDelegate")
      .unbind("click")
      .click(function () {
        var email = $("#newDelegateEmail").val().trim();
        var isErr = email.length == 0 || !validEmail.test(email);
        $("#newDelegateEmail").parent().toggleClass("has-error", isErr);
        if (isErr) return;
        backend.send(
          "update",
          {
            def: {
              type: "user",
              email: email.toLowerCase(),
              paths: ["h327"] // Default new delegates to Generic CV Hospital to avoid needing approval
            },
            from: user
          },
          function (newObj) {
            if (handleSocketMessage(newObj)) {
              return;
            }
            if (newObj.id == null) return;
            cvOwner.delegates = _.union(cvOwner.delegates || [], [newObj.id]);
            console.log("saved", newObj);

            users[newObj.id] = newObj;

            backend.send(
              "update",
              {
                id: cvOwner.id,
                def: cvOwner,
                from: user
              },
              function (newObj) {
                if (handleSocketMessage(newObj)) {
                  return;
                }
                users[newObj.id] = newObj;
                console.log("saved self");
                refreshSection();
              }
            );
          }
        );
      });

    $(".removeDel")
      .unbind("click")
      .click(function () {
        var id = $(this).attr("data-id");
        var userObj = users[id] || delUsers[id];
        if (userObj != null) {
          _.remove(cvOwner.delegates, (delegateId) => delegateId === id);

          backend.send(
            "update",
            {
              id: cvOwner.id,
              def: cvOwner,
              from: user
            },
            function (newObj) {
              if (handleSocketMessage(newObj)) {
                return;
              }
              users[newObj.id] = newObj;
              console.log("removed delegate from list");
              refreshSection();
            }
          );
        }
      });
  };
}
