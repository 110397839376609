const DEBUG = "DEBUG";
const INFO = "INFO";
const WARN = "WARN";
const ERROR = "ERROR";

let logLevel, serverBackend, stateArgs;
let isDebug, isInfo, isWarn, isError;
export function initLogLevel(
  level,
  defaultSource,
  backend = null,
  remoteLogArgs = null
) {
  logLevel = level;
  isDebug = (logLevel || INFO).toUpperCase() == DEBUG;
  isInfo = (logLevel || INFO).toUpperCase() == INFO || isDebug;
  isWarn = (logLevel || INFO).toUpperCase() == WARN || isInfo || isDebug;
  isError =
    (logLevel || INFO).toUpperCase() == ERROR || isWarn || isInfo || isDebug;

  console.log = (...args) => {
    if (args.length > 1) {
      logInfo(defaultSource, ...args);
    } else {
      logInfo(defaultSource, args[0]);
    }
  };
  serverBackend = backend;
  stateArgs = remoteLogArgs;
}

function logCommon(method, level, source, args) {
  method(`${level}: ${source}`, ...args);
}
function getArgsText(args) {
  let argsText;

  if (_.isArray(args) && args.length == 1 && typeof args[0] == "string")
    argsText = args[0];
  else if (_.isArray(args)) argsText = JSON.stringify(args);
  else argsText = args;

  return argsText;
}

export function logDebug(source, ...args) {
  if (isDebug) logCommon(console.info, DEBUG, source, args);
  if (serverBackend && stateArgs?.user?.settings?.remoteLogging)
    serverBackend.send(
      "log",
      `${source} ${DEBUG} - ${stateArgs?.user?.id} - ${getArgsText(args)}`
    );
}

export function logInfo(source, ...args) {
  if (isInfo) logCommon(console.info, INFO, source, args);
  if (serverBackend && stateArgs?.user?.settings?.remoteLogging) {
    serverBackend.send(
      "log",
      `${source} ${INFO} - ${stateArgs?.user?.id} - ${getArgsText(args)}`
    );
  }
}

export function logWarn(source, ...args) {
  if (isWarn) logCommon(console.warn, WARN, source, args);
  if (serverBackend && stateArgs?.user?.settings?.remoteLogging)
    serverBackend.send(
      "log",
      `${source} ${WARN} - ${stateArgs?.user?.id} - ${getArgsText(args)}`
    );
}

export function logError(source, ...args) {
  if (isError) logCommon(console.error, ERROR, source, args);
  if (serverBackend && stateArgs?.user?.settings?.remoteLogging)
    serverBackend.send(
      "log",
      `${source} ${ERROR} - ${stateArgs?.user?.id} - ${getArgsText(args)}`
    );
}
