import _ from "lodash";
import { FormatWrapper } from "../formats/format_wrapper.js";
import { clone } from "./utilities.js";

export const showLoadingSpinner = (target = "body") => {
  $(target).append(`<span class="loader"></span>`);
};

export const hideLoadingSpinner = () => {
  $(".loader").remove();
};

var getIndent = function (num, opts) {
  opts = opts || {};
  return (
    "<w:pPr>" +
    (num == null ? "" : '<w:ind w:left="' + num + '" w:hanging="0"/>') +
    '<w:spacing w:after="0"/>' +
    (opts.center ? '<w:jc w:val="center"/>' : "") +
    "</w:pPr>"
  ); // <w:contextualSpacing/>
};
export { getIndent };

var decodeEntities = (function () {
  // this prevents any overhead from creating the object each time
  if (typeof document == "undefined") return (str) => str;
  var element = document.createElement("div");

  function decodeHTMLEntities(str) {
    if (str && typeof str === "string") {
      str = str.replace(/<script[^>]*>([\S\s]*?)<\/script>/gim, "");
      str = str.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gim, "");
      element.innerHTML = str;
      str = element.textContent;
      element.textContent = "";
    }

    return str;
  }

  return decodeHTMLEntities;
})();

var isStrongPass = function (pass) {
  // 8+ char, cap, non cap, number, and symbol
  // !"#$%&'()*+,-./:;<=>?@[\]^_`{|}~

  var symbols = "!\"#$%&'()*+,-./:;<=>?@[]^_`{|}~";
  var uppercase = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  var lowercase = "abcdefghijklmnopqrstuvwxyz";
  var numbers = "0123456789";

  var splitPass = pass.split("");
  return (
    splitPass.length >= 8 &&
    splitPass.some(function (c) {
      return symbols.indexOf(c) >= 0;
    }) &&
    splitPass.some(function (c) {
      return uppercase.indexOf(c) >= 0;
    }) &&
    splitPass.some(function (c) {
      return lowercase.indexOf(c) >= 0;
    }) &&
    splitPass.some(function (c) {
      return numbers.indexOf(c) >= 0;
    })
  );
};
export { isStrongPass };

var preprocessText = function (contextState) {
  return function (plugin, args) {
    var noBullets =
      typeof contextState.sectionFormat !== "undefined" &&
      !!contextState.sectionFormat.noBullets;
    args.content = args.content
      .split(/<p[^>]*>/)
      .join("")
      .split("</p>")
      .join("<br>");

    var liSplit = args.content.split(/<li[^>]*>/);
    if (liSplit.length > 1) {
      liSplit = liSplit.map(function (part) {
        return (noBullets ? "" : "  - ") + part.split(/(?: | |\n)+/).join(" ");
      });
      args.content = liSplit
        .join("")
        .split("</li>")
        .join("<br>")
        .split("</li>")
        .join("<br>")
        .split(/<\/?ul>/)
        .join("");
    }

    args.content = args.content
      .split(/<div[^>]*>/)
      .join("<br>")
      .split("</div>")
      .join("");
    args.content = args.content
      .split("\t")
      .join(noBullets ? "" : "  - ")
      .split(/<(?!\/?(b|a|strong|i|u|em|br|sup|sub|p))[^>]*>/)
      .join("")
      .split(/style="[^"]+"/)
      .join("")
      .split(/style=\'[^\']+'/)
      .join("");
    // .split('\n').join('<br>');
    // Get rid of all bad xml
  };
};
export { preprocessText };

var setMaxHeight = function (element) {
  var headerBox =
    $(element + " .modal-header").length != 0
      ? $(element + " .modal-header")[0].getBoundingClientRect()
      : { height: 0, top: 0 };
  var footerBox =
    $(element + " .modal-footer").length != 0
      ? $(element + " .modal-footer")[0].getBoundingClientRect()
      : { height: 20 };
  var maxHeight =
    $(element + "").height() -
    (Math.max(headerBox.top, 10) + headerBox.height + footerBox.height + 20);
  $(element + " .modal-body").css("max-height", maxHeight);
};
export { setMaxHeight };

var getParameterByName = function (name, url) {
  if (!url) url = window.location.href;
  name = name.replace(/[\[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
};
export { getParameterByName };

var reverseEscapeText = function (txt) {
  // Gets rid of all HTML tags
  return $("<div>").html(txt).text().split("&nbsp;").join(" ");
};
export { reverseEscapeText };

var escapeText = function (txt) {
  return $("<div>").text(txt).html().split("&nbsp;").join(" ");
};
export { escapeText };

var getStyle = function (opts, adlOpts) {
  opts = opts || {};
  if (adlOpts != null) {
    opts = clone(opts);
    for (var key in adlOpts) opts[key] = adlOpts[key];
  }
  var font = opts.font != null ? opts.font : "Times New Roman";
  return (
    "<w:rPr>" + //'<w:contextualSpacing/>'+
    // '<w:spacing w:before="0" w:after="0" w:lineRule="auto" w:beforeAutospacing="0" w:afterAutospacing="0"/>'+
    (opts.link == null ? "" : '<w:rStyle w:val="Hyperlink"/>') +
    (opts.font == null
      ? ""
      : '<w:rFonts w:ascii="' +
        font +
        '" w:eastAsia="' +
        font +
        '" w:hAnsi="' +
        font +
        '" w:cs="' +
        font +
        '"/>') +
    (opts.underline ? '<w:u w:val="single"/>' : "") +
    (opts.fontSize != null ? '<w:sz w:val="' + opts.fontSize * 2 + '"/>' : "") +
    (opts.bold ? "<w:b/><w:bCs/>" : "") + // <w:b w:val="true"/>
    (opts.italic ? "<w:i/>" : "") +
    (opts.sub ? '<w:vertAlign w:val="subscript"/>' : "") +
    (opts.sup ? '<w:vertAlign w:val="superscript"/>' : "") +
    "</w:rPr>"
  );
};

var completeUrl = function (string) {
  return (
    (!/^https?:\/\//i.test(string) && !/^mailto:/i.test(string)
      ? "http://"
      : "") + string
  );
};

var isValidHttpUrl = function (string) {
  let url;

  try {
    url = new URL(completeUrl(string));
  } catch (_) {
    return false;
  }

  return ["http:", "https:", "mailto:"].indexOf(url.protocol) >= 0;
};

export { isValidHttpUrl };

var htmlToXML = function (htmlText, opts) {
  opts = opts || {};

  var links = opts.links || { lastId: 0, set: {} };

  if (htmlText == null) htmlText = "";
  var text = htmlText
    .split("<br />")
    .join("\n")
    .split("<br/>")
    .join("\n")
    .split("&nbsp;")
    .join(" ");

  var textParts = [
    {
      text: escapeText(text),
      italic: !!opts.italic,
      bold: !!opts.bold,
      underline: !!opts.underline
    }
  ];

  if (opts.citationNames) {
    var nameText = opts.citationNames != null ? opts.citationNames : "";
    var citationNames = nameText.split(";");
    // Maybe I surround the names with bold tags and then convert to xml.

    _.sortBy(citationNames, function (name) {
      return -1 * name.length; // Longest to shortest
    }).map(function (name) {
      var newParts = [];
      textParts.map(function (part) {
        if (part.bold) return newParts.push(part);
        var splitParts = part.text
          .split(String.fromCharCode(160))
          .join(" ")
          .split(name);
        splitParts.map(function (splitPart, i) {
          if (i != 0) newParts.push({ text: name, bold: true, citName: true });
          newParts.push({ text: splitPart, bold: false });
        });
      });
      textParts = newParts;
    });
  }

  var styleTypes = [
    { start: "<sub>", end: "</sub>", type: "sub" },
    { start: "<sup>", end: "</sup>", type: "sup" },
    { start: "<u>", end: "</u>", type: "underline" },
    {
      start: '<span style="text-decoration: underline;">',
      end: "</span>",
      type: "underline"
    },
    { start: "<i>", end: "</i>", type: "italic" },
    { start: "<em>", end: "</em>", type: "italic" },
    { start: '<a href="[^"]*"[^>]*>', end: "</a>", type: "link" }
    // {tag: 'a' }
  ];

  if (!opts.noBold) {
    styleTypes.push({
      start: "<strong>",
      end: "</strong>",
      bold: true,
      type: "bold"
    });
    styleTypes.push({ start: "<b>", end: "</b>", bold: true, type: "bold" });
  }

  // MD with 1st <a href=\"http://www.google.com\">Class</a> Honors (Equivalent of Summa Cum Laude)

  var applyItem = function (mainPart, item, lastData) {
    if (lastData.url != null) {
      links.lastId++;
      links.set[links.lastId] = escapeText(
        encodeURI(reverseEscapeText(lastData.url))
      );
      mainPart.link = escapeText(encodeURI(reverseEscapeText(lastData.url)));
      mainPart.linkId = links.lastId;
    }

    if (item.type != null && item.type != "link") mainPart[item.type] = true;

    // if (item.sub != null) mainPart.sub = true;
    // if (item.sup != null) mainPart.sup = true;
    // if (item.bold != null) mainPart.bold = true;
    // if (item.underline != null) mainPart.underline = true;
    // if (item.italic != null) mainPart.italic = true;
  };

  var linkErr = false;

  styleTypes.map(function (item) {
    var newParts = [];
    for (var partI = 0; partI < textParts.length; partI++) {
      var part = textParts[partI];
      var lastData = {};
      reverseEscapeText(part.text)
        .split(new RegExp("(" + item.start + ")"))
        .map(function (piece, i) {
          if (i == 0) {
            var beforePart = clone(part);
            beforePart.text = escapeText(piece);
            newParts.push(beforePart);
          } else if (i % 2 == 1) {
            if (item.type == "link") {
              var linkMatches = piece.match(/<a href="([^"]*)"[^>]*>/);
              if (linkMatches && linkMatches.length > 1) {
                var url = linkMatches[1];
                if (isValidHttpUrl(url)) lastData.url = completeUrl(url);
                else if (opts.errHandler != null && !linkErr) {
                  opts.errHandler("linkErr");
                  linkErr = true;
                }
              } else {
                console.log("Error in finding link matches");
              }
            }
          } else {
            var mainPart = clone(part);
            var afterI = piece.indexOf(item.end);

            if (afterI == -1) {
              // Look in future parts for the end.
              textParts.slice(partI + 1).some(function (nextPart, nextI) {
                var nextAfterI = nextPart.text.indexOf(escapeText(item.end));
                if (nextAfterI >= 0) {
                  // Need to split up into two parts. Maybe splice.
                  var newPart = clone(nextPart);
                  newPart.text = newPart.text.slice(
                    nextAfterI + escapeText(item.end).length
                  );
                  nextPart.text = nextPart.text.slice(0, nextAfterI);
                  applyItem(nextPart, item, lastData);

                  textParts.splice(partI + nextI + 2, 0, newPart);
                  return true;
                } else {
                  applyItem(nextPart, item, lastData);
                }
              });
              mainPart.text = escapeText(piece);
              applyItem(mainPart, item, lastData);
              if (mainPart.text.length > 0) newParts.push(mainPart);
            } else {
              mainPart.text = escapeText(piece.slice(0, afterI));
              applyItem(mainPart, item, lastData);
              if (mainPart.text.length > 0) newParts.push(mainPart);

              var afterPart = clone(part);
              afterPart.text = escapeText(
                piece.slice(afterI + item.end.length)
              );
              if (afterPart.text.length > 0) newParts.push(afterPart);
            }
          }
        });
    }
    textParts = newParts;
  });

  // textParts.map(function(part) {
  //   if (part.citName != null) {
  //     ['italic', 'underline', 'sub', 'sup'].map(function(key) {
  //       delete part[key];
  //     });
  //   }
  // })

  if (opts.justText) {
    // Remove all formatting
    return textParts
      .map(function (part) {
        return part.text;
      })
      .join("");
  }

  if (opts.noXML) {
    return textParts
      .map(function (part) {
        var matchedType = {};
        var matchingTypes = styleTypes.filter(function (item) {
          if (
            item.type != "link" &&
            !!part[item.type] &&
            matchedType[item.type] == null
          ) {
            matchedType[item.type] = true;
            return true;
          }
        });
        if (part.link != null)
          matchingTypes.push({
            start: '<a href="' + part.link + '">',
            end: "</a>",
            type: ""
          });
        var prefix = matchingTypes
          .map(function (item) {
            return item.start;
          })
          .join("");
        var suffix = matchingTypes
          .slice()
          .reverse()
          .map(function (item) {
            return item.end;
          })
          .join("");
        return prefix + part.text + suffix;
      })
      .join("");
  }

  var joinedText = textParts
    .filter(function (part) {
      return part.text.length > 0;
    })
    .map(function (part, i) {
      var styleInfo = {
        font: opts.font,
        fontSize: opts.fontSize,
        bold: part.bold,
        italic: part.italic,
        underline: part.underline,
        sub: part.sub,
        sup: part.sup
      };
      if (part.link != null) {
        if (!opts.notTable) {
          return (
            "<w:r>" +
            getStyle(styleInfo) +
            '<w:fldChar w:fldCharType="begin"/></w:r>' +
            "<w:r>" +
            getStyle(styleInfo) +
            '<w:instrText xml:space="preserve"> HYPERLINK "' +
            escapeText(encodeURI(reverseEscapeText(part.link))) +
            '" </w:instrText></w:r>' +
            "<w:r>" +
            getStyle(styleInfo) +
            '<w:fldChar w:fldCharType="separate"/></w:r>' +
            "<w:r>" +
            getStyle(styleInfo, { link: true }) +
            "<w:t>" +
            escapeText(decodeEntities(reverseEscapeText(part.text))) +
            "</w:t></w:r>" +
            "<w:r>" +
            getStyle(styleInfo) +
            '<w:fldChar w:fldCharType="end"/></w:r>'
          );
        } else {
          return (
            '<w:hyperlink r:id="rId' +
            part.linkId +
            '" w:history="1">' +
            "<w:r>" +
            getStyle(styleInfo, { link: true }) +
            '<w:t xml:space="preserve">' +
            escapeText(decodeEntities(reverseEscapeText(part.text))) +
            "</w:t></w:r></w:hyperlink>"
          );
        }
      } else {
        return (
          '<w:r><w:t xml:space="preserve">' +
          getStyle(styleInfo) +
          escapeText(decodeEntities(reverseEscapeText(part.text))) +
          "</w:t></w:r>"
        );
      }
    })
    .join("");

  return (
    "<w:p>" +
    getIndent(opts.indent != null ? opts.indent : null, {
      center: opts.center
    }) +
    joinedText.split("\n").join("<w:br/>") +
    "</w:p>"
  );
};
export { htmlToXML };

var closeOpenTags = function (bit) {
  var startTags = {};
  var endTags = {};

  var tagOptions = [
    { start: "<strong>", end: "</strong>" },
    { start: "<b>", end: "</b>" },
    { start: "<em>", end: "</em>" },
    { start: "<i>", end: "</i>" },
    { start: "<sub>", end: "</sub>" },
    { start: "<sup>", end: "</sup>" },
    { start: '<a href="[^>]*>', end: "<\\/a>", startRep: '<a href="#">' }
  ];

  tagOptions.map(function (item, i) {
    var matches = bit.split(new RegExp("(" + item.start + ")"));
    startTags[i] = (matches.length - 1) / 2;
  });

  tagOptions.map(function (item, i) {
    var matches = bit.split(new RegExp("(" + item.end + ")"));
    endTags[i] = (matches.length - 1) / 2;
  });

  var startPrefix = "";
  var endPrefix = "";
  tagOptions.map(function (item, i) {
    if (startTags[i] > endTags[i]) {
      for (var j = 0; j < startTags[i] - endTags[i]; j++) {
        endPrefix += item.end;
      }
    } else if (endTags[i] > startTags[i]) {
      for (var j = 0; j < endTags[i] - startTags[i]; j++) {
        startPrefix += item.startRep || item.start;
      }
    }
  });

  // console.log(startTags, endTags, startPrefix, endPrefix);

  var res = startPrefix + bit + endPrefix;
  tagOptions.map(function (item) {
    // Remove closed and empty tags
    var emptyTagRegExp = new RegExp(item.start + "( *)" + item.end);
    while (emptyTagRegExp.test(res)) {
      // Replace tags with just spaces with their spaces.
      var firstMatch = res.match(emptyTagRegExp);
      if (firstMatch && firstMatch.length > 1) {
        res = res.split(firstMatch[0]).join(firstMatch[1]);
      }
    }
  });

  return res;
};
export { closeOpenTags };

const allowedHTMLTags = [
  "<br>",
  "<br/>",
  "<br />",
  "<strong>",
  "</strong>",
  "<b>",
  "</b>",
  "<em>",
  "</em>",
  "<i>",
  "</i>",
  "<u>",
  "</u>",
  "<sub>",
  "</sub>",
  "<sup>",
  "</sup>",
  '<a href="[^>]*>',
  "<\\/a>"
];
var escapeHtml = function (unsafeText, opts) {
  if (unsafeText == null) return "";
  opts = opts || {};

  var splitText = [unsafeText];
  if (opts.allowTags) {
    // Let certain tags pass through for preview formatting
    splitText = closeOpenTags(unsafeText).split(
      new RegExp("(" + allowedHTMLTags.join("|") + ")")
    );
  }

  return splitText
    .map(function (text, i) {
      if (i % 2 == 1) {
        if (/<a href="[^>]*>/.test(text)) {
          if (text.indexOf('href="http') == -1)
            text = text.split('href="').join('href="//');
        }
        return text;
      }
      return escapeText(text);
    })
    .join("");
};
export { escapeHtml };

var unescapeHTML = function (htmlText, opts) {
  opts = opts || {};
  // unescape HTML so when it goes back through escapeHtml(textDef, { allowTags: true }) it looks the way it was.

  var splitText = [htmlText];
  if (opts.allowTags) {
    // Let certain tags pass through for preview formatting
    splitText = htmlText.split(
      new RegExp("(" + allowedHTMLTags.join("|") + ")")
    );
  }

  return splitText
    .map(function (text, i) {
      if (i % 2 == 1) return text;
      return reverseEscapeText(text);
    })
    .join("");
};
export { unescapeHTML };

const escapeNonHTMLCharacters = function (text = "") {
  /*
   * Escape characters that shouldn't be interpreted as HTML
   * Example:
   *  escapeNonHTMLCharacters("this string <is provided as <strong>example</strong> input")
   *                                       ^ this isn't HTML! But TinyMCE thinks it is the
   *                                       opening of a tag, so it eats all the text that follows it.
   *  This example would return the following string:
   *    "this string &lt;is provided as <strong>example</strong> input"
   *
   *  This function only checks for the following characters: < > & ' "
   *  https://www.tiny.cloud/docs/tinymce/latest/content-filtering/#entity_encoding
   */
  const linkRegex = new RegExp(/<a href="[^>]*>/);
  // for each character in the string
  for (let i = 0; i < text.length; i++) {
    // if it might be the opening of a tag
    if (text[i] == "<") {
      let close = i + 1;
      // try to find the closing of the tag, while checking if it exists in allowedHTMLTags
      let linkTag = false;
      while (text[close] != ">") {
        const chunk = text.substring(i, close);
        if (
          allowedHTMLTags.some((tag) => tag.includes(chunk)) ||
          "</a>".includes(chunk)
        ) {
          close++;
          if (close - i > 20) break; // dont loop forever
        } else {
          let linkSearch = linkRegex.exec(text.substring(i));
          if (linkSearch && linkSearch.index == 0) {
            linkTag = true;
            close = i + linkSearch[0].length;
          }
          break;
        }
      }
      const fullTag = text.substring(i, close + 1);
      if (
        allowedHTMLTags.some((tag) => tag == fullTag) ||
        linkTag ||
        "</a>".includes(fullTag)
      ) {
        // if our tag is valid, skip to the end of the tag
        i = close;
      } else {
        // if our tag is invalid, replace the opening tag with &lt; and dont skip forward
        text = text.substring(0, i) + "&lt;" + text.substring(close - 1);
      }
    } else if (text[i] == ">") {
      text = text.substring(0, i) + "&gt;" + text.substring(i + 1);
    } else if (text[i] == '"') {
      text = text.substring(0, i) + "&#34;" + text.substring(i + 1);
    } else if (text[i] == "'") {
      text = text.substring(0, i) + "&#39;" + text.substring(i + 1);
    } else if (text[i] == "&") {
      text = text.substring(0, i) + "&amp;" + text.substring(i + 1);
    }
  }
  return text;
};
export { escapeNonHTMLCharacters };

var getProp = function (that, prop) {
  return $(that)
    .closest("[data-" + prop + "]")
    .attr("data-" + prop);
};
export { getProp };

export async function addDynamicFormatsFromBrowser(formats) {
  try {
    if (typeof window != "undefined") {
      let dynamicFormats = await (await fetch("/formats")).json();
      for (let dynamicFormat of dynamicFormats) {
        formats[dynamicFormat.code] = new FormatWrapper(dynamicFormat);
      }
    }
  } catch (error) {
    logError("formats.index", error);
  }
}
