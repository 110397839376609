import _ from "lodash";
import xmldom from "xmldom";
import { numToMonths } from "./dateHelpers.js";
import { logDebug, logError, logInfo } from "./logUtilities.js";
import { after, for2, getField, getKey, keys } from "./utilities.js";

const DOMParser = new xmldom.DOMParser();

var entrezSummary = "esummary.fcgi?db=pubmed&retmode=json";
var entrezFetch = "efetch.fcgi?db=pubmed&retmode=xml";
var entrezAuthor =
  "esearch.fcgi?db=pubmed&retmode=json&usehistory=y&retmax=10000&term=";
var pmDomain = "https://eutils.ncbi.nlm.nih.gov/entrez/eutils";

export { entrezAuthor, entrezFetch, entrezSummary, pmDomain };

var langLookup = {
  afr: "Afrikaans",
  alb: "Albanian",
  amh: "Amharic",
  ara: "Arabic",
  arm: "Armenian",
  aze: "Azerbaijani",
  ben: "Bengali",
  bos: "Bosnian",
  bul: "Bulgarian",
  cat: "Catalan",
  chi: "Chinese",
  cze: "Czech",
  dan: "Danish",
  dut: "Dutch",
  eng: "English",
  epo: "Esperanto",
  est: "Estonian",
  fin: "Finnish",
  fre: "French",
  geo: "Georgian",
  ger: "German",
  gla: "Scottish Gaelic",
  gre: "Greek, Modern",
  heb: "Hebrew",
  hin: "Hindi",
  hrv: "Croatian",
  hun: "Hungarian",
  ice: "Icelandic",
  ind: "Indonesian",
  ita: "Italian",
  jpn: "Japanese",
  kin: "Kinyarwanda",
  kor: "Korean",
  lat: "Latin",
  lav: "Latvian",
  lit: "Lithuanian",
  mac: "Macedonian",
  mal: "Malayalam",
  mao: "Maori",
  may: "Malay",
  mul: "Multiple languages",
  nor: "Norwegian",
  per: "Persian, Iranian",
  pol: "Polish",
  por: "Portuguese",
  pus: "Pushto",
  rum: "Romanian, Rumanian, Moldovan",
  rus: "Russian",
  san: "Sanskrit",
  slo: "Slovak",
  slv: "Slovenian",
  spa: "Spanish",
  srp: "Serbian",
  swe: "Swedish",
  tha: "Thai",
  tur: "Turkish",
  ukr: "Ukrainian",
  und: "Undetermined",
  urd: "Urdu",
  vie: "Vietnamese",
  wel: "Welsh"
};

var getFetchData = function (data) {
  var fetchData = {
    authorList: []
  };

  // Can everything be extracted?
  // authorList, title (ArticleTitle), volume (Volume), issue (Issue), pages (MedlinePgn), source (Journal > ISOAbbreviation), pubdate (PubDate), uid (PMID)

  fetchData.title = getTagText(data, "ArticleTitle");
  fetchData.volume = getTagText(data, "Volume");
  fetchData.issue = getTagText(data, "Issue");
  fetchData.pages = getTagText(data, "MedlinePgn");
  fetchData.source =
    getTag(data, "Journal") == null
      ? ""
      : getTagText(getTag(data, "Journal"), "ISOAbbreviation");
  // fetchData.doi = getTagText(data, 'ELocationID');
  fetchData.issn = getTagText(data, "ISSN");
  fetchData.language = langLookup[getTagText(data, "Language")];

  Array.from(getTag(data, "Article").childNodes).map(function (idItem) {
    if (idItem.getAttribute("EIdType") == "pii")
      fetchData.pii = idItem.textContent;
    if (idItem.getAttribute("EIdType") == "doi")
      fetchData.doi = idItem.textContent;
  });

  var uid = getTagText(data, "PMID");
  fetchData.uid = uid;

  var extractDate = function (data, tag) {
    var dateEl = getTag(data, tag);
    var dateData = null;
    if (dateEl != null && getTagText(dateEl, "Year") != "") {
      dateData = {
        year: getTagText(dateEl, "Year"),
        month: getTagText(dateEl, "Month"),
        date: getTagText(dateEl, "Day")
      };
      // if (dateData.day == '') dateData.day = '1';
      if (numToMonths[parseInt(dateData.month)] != null) {
        dateData.monthNum = dateData.month;
        dateData.month = numToMonths[parseInt(dateData.month)];
      } else {
        for (var num in numToMonths)
          if (
            numToMonths[num].trim().toLowerCase() ==
            dateData.month.trim().toLowerCase()
          )
            dateData.monthNum = num;
      }

      var monthString = (dateData.monthNum || dateData.month || "")
        .split("-")[0]
        .trim();
      dateData.string =
        (monthString == ""
          ? ""
          : monthString +
            (dateData.date.trim().length == 0
              ? ""
              : "/" + parseInt(dateData.date)) +
            "/") + dateData.year;
      dateData.reverseString =
        dateData.year +
        " " +
        dateData.month +
        (dateData.date != "" ? " " + parseInt(dateData.date) : ""); // For citation
      if (dateData.string.trim().length == 0) {
        debugger;
        dateData = null;
      }
    }
    return dateData;
  };

  fetchData.pubdateData = extractDate(data, "PubDate");
  fetchData.articledateData = extractDate(data, "ArticleDate");
  if (fetchData.articledateData != null) {
    fetchData.articledateData.type = getTag(data, "ArticleDate").getAttribute(
      "DateType"
    );
  }

  var medlineDateEl = getTag(data, "MedlineDate");
  if (medlineDateEl != null) {
    var dateData = {
      year: medlineDateEl.textContent.split(" ")[0] || "",
      month: medlineDateEl.textContent.split(" ")[1] || "",
      date: medlineDateEl.textContent.split(" ")[2] || ""
    };
    if (numToMonths[parseInt(dateData.month)] != null) {
      dateData.monthNum = dateData.month;
      dateData.month = numToMonths[parseInt(dateData.month)];
    } else {
      for (var num in numToMonths)
        if (
          numToMonths[num].trim().toLowerCase() ==
          dateData.month.split("-")[0].trim().toLowerCase()
        )
          dateData.monthNum = num;
    }

    var monthString = (dateData.monthNum || dateData.month || "")
      .split("-")[0]
      .trim();
    dateData.string =
      (monthString == ""
        ? ""
        : monthString +
          (dateData.date.trim().length == 0
            ? ""
            : "/" + parseInt(dateData.date)) +
          "/") + dateData.year;
    dateData.reverseString = dateData.year + " " + dateData.month; // For citation
    fetchData.medlinedateData = dateData;
  }

  // Priority: ArticleDate, PubDate, MedlineDate

  // Visual for citation
  if (fetchData.pubdateData != null) {
    fetchData.pubdate = fetchData.pubdateData.reverseString;
  } else if (fetchData.articledateData != null)
    fetchData.pubdate = fetchData.articledateData.reverseString;
  else if (fetchData.medlinedateData != null)
    fetchData.pubdate = fetchData.medlinedateData.reverseString;

  // Needs to be parsable by new Date()
  if (
    fetchData.pubdateData != null &&
    (fetchData.pubdateData.month != "" || fetchData.articledateData == null)
  ) {
    fetchData.sortpubdate = fetchData.pubdateData.string;
  } else if (fetchData.articledateData != null)
    fetchData.sortpubdate = fetchData.articledateData.string;
  else if (fetchData.medlinedateData != null)
    fetchData.sortpubdate = fetchData.medlinedateData.string;

  if (fetchData.pubdate == null) {
    debugger;
    fetchData.pubdate = "";
  }
  if (fetchData.sortpubdate == null) {
    debugger;
    fetchData.sortpubdate = "";
  }

  fetchData.ids = {};
  var idList = getTag(data, "ArticleIdList");
  Array.from(idList.childNodes).map(function (idItem) {
    fetchData.ids[idItem.getAttribute("IdType")] = idItem.textContent;
  });

  var authorList = getTag(data, "AuthorList");
  (authorList == null ? [] : Array.from(authorList.childNodes)).map(
    function (author) {
      var authorData = {};
      Array.from(author.childNodes).map(function (att) {
        if (att.childNodes.length == 1)
          authorData[att.localName] = att.textContent;
        else
          Array.from(att.childNodes).map(function (attChild) {
            authorData[attChild.localName] = attChild.textContent;
          });
      });
      fetchData.authorList.push(authorData);
    }
  );

  return fetchData;
};
export { getFetchData };

var pmDataMatch = function (data, opts) {
  opts = opts || {};
  var matchFirst = !!opts.first;

  var firstNames = (opts.def["firstNames"] || "")
    .toLowerCase()
    .split(";")
    .filter((n) => n != "");
  var citNames = (opts.def["citationName"] || "")
    .toLowerCase()
    .split(";")
    .filter((n) => n != "");
  var orcid = (opts.def["orcid"] || "").split("/").slice(-1)[0].trim();
  // if (orcid != null && orcid.indexOf('http://') == -1) orcid = 'http://orcid.org/' + orcid;

  var authorList = (data.fetchData || { authorList: [] }).authorList;

  // Only add if an author that matches a citation name and also matches a first name
  var fullAuthorMatches = authorList.filter(function (author) {
    var citName = (
      author.LastName +
      " " +
      author.Initials +
      (author.Suffix != null ? " " + author.Suffix : "")
    )
      .toLowerCase()
      .split(/,|\./)
      .join("");
    var firstName = (author.ForeName || "")
      .toLowerCase()
      .split(/,|\./)
      .join("");
    return (
      citNames.some(function (n) {
        return citName.indexOf(n.split(/,|\./).join("")) >= 0;
      }) &&
      firstNames.some(function (n) {
        return firstName.indexOf(n.split(/,|\./).join("")) >= 0;
      }) &&
      (author.Identifier == null ||
        orcid.length == 0 ||
        author.Identifier.indexOf(orcid) >= 0)
    );
  });

  var citationAuthorMatches = authorList.filter(function (author) {
    var citName = (
      author.LastName +
      " " +
      author.Initials +
      (author.Suffix != null ? " " + author.Suffix : "")
    )
      .toLowerCase()
      .split(/,|\./)
      .join("");
    return (
      citNames.some(function (n) {
        return citName.indexOf(n.split(/,|\./).join("")) >= 0;
      }) &&
      (author.Identifier == null ||
        orcid.length == 0 ||
        author.Identifier.indexOf(orcid) >= 0)
    );
  });

  return (
    authorList.length == 0 ||
    (matchFirst ? fullAuthorMatches.length : citationAuthorMatches.length > 0)
  );
};
export { pmDataMatch };

// @deprecated use getPMDataV2 and refactor uses
var getPMdata = function (ids, callback, opts) {
  opts = opts || {};
  var pmidList = opts.pmidList;

  var results = {};
  var newIds = ids.filter(function (id) {
    if (/[a-zA-Z]/.test(id)) return false; // Not a real PMID
    var satisfied =
      pmidList[id] != null &&
      (!!opts.noCitationCount ||
        pmidList[id].citationCount != null ||
        !!pmidList[id].citationCountMissing) &&
      pmidList[id].fetchData != null &&
      false;
    if (satisfied) results[id] = pmidList[id];
    return !satisfied;
  });

  var blockSize = 300;
  var blocks = [];
  var i = 0;
  while (i < newIds.length) {
    var newBlock = newIds.slice(i, i + blockSize);
    blocks.push(newBlock);
    i += newBlock.length;
  }

  for2(
    { list: blocks, wait: opts.wait },
    function (block, done, block_i, wait) {
      // First update pmResults if it's missing
      // Then update citationCount if applicable
      // Finally update pmFetchData if it's missing

      after(
        function (done) {
          if (opts.noCitationCount) return done();
          var citationCountsMissing = block.filter(function (id) {
            if (opts.checkCitationCounts) return true;
            if (
              getKey(pmidList, id, {}).citationCount != null ||
              !!pmidList[id].citationCountMissing
            ) {
              getKey(results, id, {}).citationCount =
                pmidList[id].citationCount;
              getKey(results, id, {}).citationCountMissing =
                pmidList[id].citationCountMissing;
              return false;
            }
            return true;
          });
          if (citationCountsMissing.length == 0) return done();

          wait();
          after(
            function (done) {
              if (typeof $ == "undefined") {
                opts.request.get(
                  "https://icite.od.nih.gov/api/pubs?pmids=" +
                    citationCountsMissing.join(","),
                  function (error, response, body) {
                    if (error != null || response.statusCode == "503") {
                      console.log("Error: ", response.statusCode, error);
                      return done();
                    }
                    done(JSON.parse(body));
                  }
                );
              } else {
                $.ajax({
                  type: "GET",
                  url:
                    "https://icite.od.nih.gov/api/pubs?pmids=" +
                    citationCountsMissing.join(","),
                  dataType: "json",
                  success: done,
                  error: function () {
                    done();
                  }
                });
              }
            },
            function (setData) {
              if (setData == null) return done();
              (setData.data || []).map(function (item) {
                var result = getKey(results, item.pmid, {});
                result.citationCount = item.citation_count;
                result.rcr = item.relative_citation_ratio;
                delete result.citationCountMissing;

                var pmidItem = getKey(pmidList, item.pmid, {});
                pmidItem.citationCount = item.citation_count;
                pmidItem.rcr = item.relative_citation_ratio;
                delete pmidItem.citationCountMissing;

                delete pmidList[item.pmid].pmResults;
              });

              if (
                !!opts.checkCitationCounts &&
                opts.citationCountProgress != null
              ) {
                opts.citationCountProgress(block_i * blockSize + block.length);
              }

              done();
            }
          );
        },
        function () {
          var fetchMissing = block.filter(function (id) {
            // return true; // Checking all now
            if ((id || "").indexOf("/") >= 0) return false; // Not allowed
            if (
              getKey(pmidList, id, {}).fetchData != null &&
              getKey(pmidList, id, {}).fetchData.sortpubdate != null
            ) {
              getKey(results, id, {}).fetchData = pmidList[id].fetchData;
              return false;
            }
            return true; // Checking all now
          });
          if (fetchMissing.length === 0) return done();
          wait();
          setTimeout(function () {
            after(
              function (done) {
                if (typeof $ == "undefined") {
                  opts.request.get(
                    pmDomain +
                      "/" +
                      entrezFetch +
                      "&id=" +
                      fetchMissing.join(","),
                    function (error, response, body) {
                      if (error != null) {
                        console.log(error);
                        return done();
                      }
                      done(DOMParser.parseFromString(body));
                    }
                  );
                } else {
                  $.ajax({
                    type: "GET",
                    url:
                      "/PMproxy?path=" +
                      encodeURIComponent(
                        entrezFetch + "&id=" + fetchMissing.join(",") + ""
                      ),
                    dataType: "xml",
                    success: done,
                    error: done
                  });
                }
              },
              function (setData, label) {
                if (
                  setData == null ||
                  label == "error" ||
                  label == "parseerror"
                )
                  return done();
                var set = getTag(setData, "PubmedArticleSet");
                if (set == null) {
                  return done();
                }
                for2(
                  {
                    list: Array.from(set.getElementsByTagName("PubmedArticle")),
                    wait: wait
                  },
                  function (data, done, i, wait) {
                    var fetchData = getFetchData(data);
                    getKey(results, fetchData.uid, {}).fetchData = fetchData;
                    delete results[fetchData.uid].pmResults;
                    getKey(pmidList, fetchData.uid, {}).fetchData = fetchData;
                    delete pmidList[fetchData.uid].pmResults;
                    // console.log('got', fetchData.uid, pmidList[fetchData.uid]);
                    done();
                  },
                  function () {
                    done();
                  }
                );
              }
            );
          }, 800);
        }
      );
    },
    function () {
      console.log("done searching for pmData");
      callback(results);
    }
  );
};
export { getPMdata };

var getTag = function (data, tag) {
  return data == null || data.getElementsByTagName == null
    ? null
    : data.getElementsByTagName(tag)[0];
};
export { getTag };

var getTagText = function (data, tag) {
  return getTag(data, tag) == null ? "" : getTag(data, tag).textContent;
};
export { getTagText };

var getCitName = function (author) {
  if (author.CollectiveName != null) return author.CollectiveName;
  return (
    author.LastName +
    " " +
    author.Initials +
    (author.Suffix != null ? " " + author.Suffix : "")
  );
};
export { getCitName };

var expandPages = function (text) {
  if (text.indexOf("-") == -1) return text;
  var pages = text.split("-");
  var page1 = pages[0];
  var page2 = pages[1];
  return page1 + "-" + page1.slice(0, page1.length - page2.length) + page2;
};

const removeLastPeriod = (line) =>
  line.endsWith(".") ? line.slice(0, -1) : line;

export const makeCitation = (data, cvOwner, hyperlinkPMIDs = false) => {
  if (!data.title) {
    return "";
  }

  // authorList, title, volume, issue, pages, source, pubdate, uid
  // Forooghian F, Yeh S, Faia LJ, Nussenblatt RB. Uveitic foveal atrophy: clinical features and associations. Arch Ophthalmol. 2009 Feb;127(2):179-86. PubMed PMID: 19204236.
  const title = removeLastPeriod(data.title.split("&amp;amp;").join("&"));

  var authorList = (data.authorList || []).map(getCitName);

  var pages = data.pages;
  if (data.pii != null && (pages == null || pages == "")) pages = data.pii;

  var volAndPages =
    (data.volume != ""
      ? data.volume +
        (data.issue != "" ? "(" + data.issue + ")" : "") +
        (pages != null && pages != "" ? ":" : "")
      : "") + expandPages(pages);

  const pubdate = data.pubdate.trim();

  const cvOwnerSettings = _.get(cvOwner, "settings", {});
  const citationPreferences = cvOwner
    ? _.get(cvOwnerSettings, "citationPrefs", {
        pmcid: true,
        doi: true
      })
    : {};

  data.ids = data.ids || {};

  const extraIds = [];

  // Pubmed ID
  if (data.uid) {
    if (hyperlinkPMIDs) {
      extraIds.push(
        `<a href="https://pubmed.ncbi.nlm.nih.gov/${data.uid}"> PMID: ${data.uid}</a>`
      );
    } else {
      extraIds.push(`PMID: ${data.uid}`);
    }
  }

  // Pubmed Central ID
  if (data.ids["pmc"] != null && !!citationPreferences.pmcid) {
    extraIds.push(`PMCID: ${data.ids["pmc"]}`);
  }

  // DOI
  if (data.doi != null && data.doi !== "" && !!citationPreferences.doi) {
    extraIds.push(`https://doi.org/${data.doi}`);
  }

  //Epub
  if (
    data.articledateData != null &&
    data.articledateData.type === "Electronic"
  ) {
    extraIds.push(
      "Epub " +
        [
          data.articledateData.year,
          data.articledateData.month,
          data.articledateData.date
        ]
          .filter(function (n) {
            return n != null;
          })
          .join(" ")
    );
  }

  const authors =
    authorList.length === 0
      ? ""
      : authorList.map((author) => removeLastPeriod(author)).join(", ");
  const volumeAndPages = volAndPages !== "" ? ";" + volAndPages : "";

  return `${authors}. ${title}. ${data.source}. ${pubdate}${volumeAndPages}. ${extraIds.join(". ")} `.trim();
};

var findCVNames = function (def, pmidList) {
  var currentFirstNames = getKey(def, "firstNames", "")
    .split(";")
    .filter(function (n) {
      return n != "";
    });

  var currentNames = {};
  var highlightNames = getKey(def, "citationName", "")
    .split(";")
    .filter(function (n) {
      return n != "";
    });
  highlightNames.map(function (n) {
    currentNames[n.toLowerCase().trim()] = true;
  });

  // To find potentially missing first names, go through all PMIDs with last name matches but no first name matches.
  var scholarshipWithoutLastNameMatches = []; // Find PMIDs which don't match current citation names and looking through all authors for last name inclusion.

  var firstNames = {};
  getField(def, "scholarship")
    .filter(function (n) {
      return (
        n.PMID != null &&
        pmidList[n.PMID] != null &&
        pmidList[n.PMID].fetchData != null
      ); // pmidList[n.PMID].pmResults != null;
    })
    .map(function (n) {
      var lastNameMatches = (
        pmidList[n.PMID].fetchData.authorList || []
      ).filter(function (author) {
        return currentNames[getCitName(author).toLowerCase().trim()] != null;
      });

      if (lastNameMatches.length == 0)
        scholarshipWithoutLastNameMatches.push(n);
      else {
        // Only add first names to consider adding if none already match.
        if (
          lastNameMatches.every(function (author) {
            return !currentFirstNames.some(function (currentFirstName) {
              return (
                (author.ForeName || "")
                  .toLowerCase()
                  .indexOf(currentFirstName.toLowerCase()) >= 0
              );
            });
          })
        ) {
          lastNameMatches.map(function (author) {
            getKey(firstNames, author.ForeName || "", []).push(n.PMID);
          });
        }
      }

      // if ((pmidList[n.PMID].pmResults.authors || []).every(function(author) {
      //   return currentNames[author.name.toLowerCase().trim()] == null;
      // })) {
    });

  var authorNames = {};
  scholarshipWithoutLastNameMatches.map(function (n) {
    (pmidList[n.PMID].fetchData.authorList || []).map(function (author) {
      getKey(authorNames, getCitName(author).trim(), []).push(n.PMID);
    });
  });

  var realNames = (def.bio.name || "")
    .toLowerCase()
    .split(",")[0]
    .split(/ |\./)
    .filter(function (name) {
      return name.length > 1;
    });

  var possibleNames = [];

  if (realNames.length > 0) {
    // Find author names which include the last name
    var lastName = realNames.slice(-1)[0].toLowerCase();
    for (var name in authorNames) {
      if (
        name
          .toLowerCase()
          .split(/ |\./)
          .filter(function (n) {
            return n.trim().length > 0;
          })
          .indexOf(lastName) >= 0 &&
        currentNames[name.toLowerCase()] == null
      )
        possibleNames.push(name);
    }
  }

  possibleNames = _.sortBy(possibleNames, function (name) {
    return -authorNames[name].length;
  });

  var possibleFirstNames = keys(firstNames).filter(function (name) {
    return !currentFirstNames.some(function (currentFirstName) {
      return name.toLowerCase().indexOf(currentFirstName.toLowerCase()) >= 0;
    });
  });

  return {
    realName: def.bio.name,
    currentNames: highlightNames,
    possibleNames: possibleNames,
    possibleFirstNames: possibleFirstNames,
    firstNames: firstNames
  };
};
export { findCVNames };

export const getPMDataV2 = async (
  ids,
  includeCitations = true,
  retries = 5
) => {
  const pubMedMap = {};

  const results = {};
  const newIds = ids.filter((id) => {
    if (/[a-zA-Z]/.test(id)) {
      // Not a real PMID <- Confusing, are we storing bad data somewhere?
      return false;
    }

    const satisfied =
      pubMedMap[id] &&
      (pubMedMap[id].citationCount != null ||
        pubMedMap[id].citationCountMissing) &&
      pubMedMap[id].fetchData != null;
    // seems like an anti-pattern to be manipulating data inside a filter callback
    if (satisfied) {
      results[id] = pubMedMap[id];
    }
    return !satisfied;
  });

  const blockSize = 25;
  const blocks = [];
  for (let i = 0; i < newIds.length; i += blockSize) {
    blocks.push(newIds.slice(i, i + blockSize));
  }

  for (let block of blocks) {
    if (includeCitations) {
      // Handle citation counts
      const citationCountsMissing = block.filter((id) => {
        if (
          pubMedMap[id]?.citationCount != null ||
          pubMedMap[id]?.citationCountMissing
        ) {
          results[id] = {
            ...results[id],
            citationCount: pubMedMap[id].citationCount,
            citationCountMissing: pubMedMap[id].citationCountMissing
          };
          return false;
        }
        return true;
      });

      if (citationCountsMissing.length > 0) {
        const url = `https://icite.od.nih.gov/api/pubs?pmids=${citationCountsMissing.join(",")}`;
        const response = await fetchWithRetry(url, retries);
        const setData = await response.json();

        if (setData) {
          setData.data.forEach((item) => {
            const result = results[item.pmid] || {};
            result.citationCount = item.citation_count;
            result.rcr = item.relative_citation_ratio;
            delete result.citationCountMissing;
            results[item.pmid] = result;

            const pmidItem = pubMedMap[item.pmid] || {};
            pmidItem.citationCount = item.citation_count;
            pmidItem.rcr = item.relative_citation_ratio;
            delete pmidItem.citationCountMissing;
            pubMedMap[item.pmid] = pmidItem;
          });
        }
      }
    }

    // Handle fetch data
    const fetchMissing = block.filter((id) => {
      if ((id || "").indexOf("/") >= 0) return false; // Not allowed
      if (
        pubMedMap[id]?.fetchData != null &&
        pubMedMap[id]?.fetchData.sortpubdate != null
      ) {
        results[id] = { ...results[id], fetchData: pubMedMap[id].fetchData };
        return false;
      }
      return true;
    });

    if (fetchMissing.length > 0) {
      const url = `${pmDomain}/${entrezFetch}&id=${fetchMissing.join(",")}`;
      const response = await fetchWithRetry(url, retries);
      const body = await response.text();
      const setData = DOMParser.parseFromString(body);
      const set = setData.getElementsByTagName("PubmedArticleSet")[0];
      if (set) {
        Array.from(set.getElementsByTagName("PubmedArticle")).forEach(
          (data) => {
            const fetchData = getFetchData(data);
            results[fetchData.uid] = {
              ...results[fetchData.uid],
              fetchData
            };
            delete results[fetchData.uid].pmResults;

            pubMedMap[fetchData.uid] = {
              ...pubMedMap[fetchData.uid],
              fetchData
            };
            delete pubMedMap[fetchData.uid].pmResults;
          }
        );
      }
    }
  }

  return results;
};

async function fetchWithRetry(url, retries, init) {
  logDebug("fetchWithRetry", "Fetching: " + url);
  let response = null;
  try {
    response = await fetch(url, init);
  } catch (error) {
    if (retries < 1) {
      logError("fetchWithRetry", "Retries exhausted", error);
    } else {
      logInfo("fetchWithRetry", "Caught error, retrying", error);
      response = await fetchWithRetry(url, retries - 1, init);
    }
  }

  if (Number(response.headers.get("x-ratelimit-remaining")) == 0) {
    logInfo(
      "fetchWithRetry",
      `No more requests left waiting 1s - received ${response.headers.get("x-ratelimit-remaining")}`
    );
    await new Promise((resolve) => setTimeout(resolve, 1000));
  } else {
    logDebug(
      "fetchWithRetry",
      `received x-ratelimit-remaining ${response.headers.get("x-ratelimit-remaining")}`
    );
  }

  return response;
}

export const getNameMatchesV2 = async (cv, retries = 5) => {
  const citNames = getKey(cv, "citationName", "")
    .toLowerCase()
    .split(";")
    .filter((a) => a !== "");

  if (citNames.length === 0) return [];

  const citationNames = citNames
    .map((name) => encodeURIComponent(name.trim() + "[Author] "))
    .join("+OR+");

  if (citationNames.length === 0) return [];

  const url = entrezAuthor + citationNames;
  const requestUrl =
    typeof $ === "undefined"
      ? `${pmDomain}/${url}`
      : `/PMproxy?path=${encodeURIComponent(url)}`;

  try {
    const response = await fetchWithRetry(requestUrl, retries, {
      method: "GET",
      headers: {
        "Content-Type": "application/json"
      }
    });

    if (!response.ok) {
      console.error(`HTTP error! status: ${response.status}`);
      return [];
    }

    const data = await response.json();

    if (data?.esearchresult?.idlist) {
      return data.esearchresult.idlist;
    } else {
      console.log("no esearchresult? Error: ", data, url, cv.id);
      return [];
    }
  } catch (error) {
    console.error("Fetch error: ", error);
    return [];
  }
};

// @deprecated use getNameMatchesV2
var getNameMatches = function (opts, callback) {
  opts = opts || {};
  callback = callback || function () {};

  var citNames = getKey(opts.def, "citationName", "")
    .toLowerCase()
    .split(";")
    .filter((a) => a != "");
  // var firstNames = getKey(def, 'firstNames', '').toLowerCase().split(';').filter(a => a != '');

  // var citationName = def['citationName'] || '';
  if (citNames.length == 0) return callback([]);

  var citationNames = citNames
    .map(function (name) {
      return encodeURIComponent(name.trim() + "[Author] ");
    })
    .join("+OR+");
  if (citationNames.length == 0) return callback([]);

  var url = entrezAuthor + citationNames;
  if (typeof $ == "undefined") {
    opts.request.get(pmDomain + "/" + url, function (error, response, body) {
      if (error != null) {
        console.log(error);
        return callback();
      }
      try {
        var data = JSON.parse(body);
      } catch (e) {
        console.log("parse Error: " + opts.cvId);
        return done();
      }

      if (data.esearchresult == null || data.esearchresult.idlist == null) {
        console.log("no esearchresult? Error: ", data, url, opts.cvId);
        return callback();
      }
      callback(data.esearchresult.idlist);
    });
  } else {
    $.ajax({
      type: "GET",
      url: "/PMproxy?path=" + encodeURIComponent(url),
      dataType: "json",
      success: function (data) {
        if (data?.esearchresult?.idlist) callback(data.esearchresult.idlist);
        else callback([]);
      }
    });
  }
};
export { getNameMatches };

var saveAsterixes = function (oldLine, newLine) {
  var removeIgnore = function (text) {
    // return text.toLowerCase().split('*').join('').split('ǂ').join('').split('†').join('');
    return text
      .toLowerCase()
      .split(/\*|\.|†|ǂ|\<\/?[^\<\>]+\>|\(|\§|\)/)
      .join("")
      .trim();
  };

  var oldOptions = {};
  oldLine.split(/,|\:/).map(function (text) {
    oldOptions[removeIgnore(text)] = text;
  });
  return newLine
    .split(/(,|\:)/)
    .map(function (text, i) {
      if (i % 2 == 1) return text;
      var hash = removeIgnore(text);
      return oldOptions[hash] != null ? oldOptions[hash] : text;
    })
    .join("");
};
export { saveAsterixes };

export const highImpactJournalISSNs = new Set([
  "0028-4793", // The New England Journal of Medicine (Print)
  "1533-4406", // The New England Journal of Medicine (Online)
  "0140-6736", // Lancet (London, England) (Print)
  "1474-547X", // Lancet (London, England) (Online)
  "0098-7484", // JAMA - Journal of the American Medical Association (Print)
  "1538-3598", // JAMA - Journal of the American Medical Association (Online)
  "1078-8956", // Nature Medicine (Print)
  "1546-170X", // Nature Medicine (Online)
  "0028-0836", // Nature (Print)
  "1476-4687", // Nature (Online)
  "0036-8075", // Science (New York, N.Y.) (Print)
  "1095-9203", // Science (New York, N.Y.) (Online)
  "0092-8674", // Cell (Print)
  "1097-4172", // Cell (Online)
  "0009-7322", // Circulation (Print)
  "1524-4539", // Circulation (Online)
  "0959-8138", // BMJ (Clinical Research Ed.) (Print)
  "1756-1833", // BMJ (Clinical Research Ed.) (Online)
  "2214-109X", // The Lancet. Global Health (Print)
  "2214-109X", // The Lancet. Global Health (Online)
  "0031-6997", // Pharmacological Reviews (Print)
  "1521-0081", // Pharmacological Reviews (Online)
  "0003-4819", // Annals of Internal Medicine (Print)
  "1539-3704", // Annals of Internal Medicine (Online)
  "2468-2667", // The Lancet. Public Health (Print)
  "2468-2667", // The Lancet. Public Health (Online)
  "2041-1723", // Nature Communications (Print)
  "2041-1723", // Nature Communications (Online)
  "0362-1642", // Annual Review of Pharmacology and Toxicology (Print)
  "1545-4304", // Annual Review of Pharmacology and Toxicology (Online)
  "0003-4932", // Annals of Surgery (Print)
  "1528-1140", // Annals of Surgery (Online)
  "0043-1354", // Water Research (Print)
  "1879-2448", // Water Research (Online)
  "1549-1277", // PLoS Medicine (Print)
  "1549-1676", // PLoS Medicine (Online)
  "0194-911X", // Hypertension (Dallas, Tex. : 1979) (Print)
  "1524-4563", // Hypertension (Dallas, Tex. : 1979) (Online)
  "0364-5134", // Annals of Neurology (Print)
  "1531-8249" // Annals of Neurology (Online)
]);
