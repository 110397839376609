import _ from "lodash";
import { sectionSort } from "./cvDataUtilities.js";
import { day, getItemsWithinDateRange, getYears } from "./dateHelpers.js";
import { htmlToXML } from "./frontendUtilities.js";
import { getCitName, highImpactJournalISSNs } from "./pubMed.js";
import { getField, getKey } from "./utilities.js";

// ExcelJS Reference docs
// https://github.com/exceljs/exceljs?tab=readme-ov-file#styles

// TODO remove this and replace with something from lodash? Only used in _CV.js
var bInA = function (a, b) {
  var diff = JsDiff.diffWords(a.toLowerCase(), b.toLowerCase());
  var common = diff
    .filter(function (n) {
      return n.value != " " && !n.removed && !n.added;
    })
    .reduce(function (j, k) {
      return j + k.value.length;
    }, 0);
  var right = diff
    .filter(function (n) {
      return n.value != " " && !n.removed && !!n.added;
    })
    .reduce(function (j, k) {
      return j + k.value.length;
    }, 0);
  return common / (common + right) > 0.8;
};
export { bInA };

export const autoSizeAllColumns = (
  worksheet,
  minimumWidth = 10,
  maxWidth = 40
) => {
  worksheet.columns.forEach((column) => {
    autoSizeColumn(column, minimumWidth, maxWidth);
  });
};

export const autoSizeColumn = (column, minimumWidth = 10, maxWidth = 40) => {
  let maxColumnLength = 0;
  column.eachCell({ includeEmpty: true }, (cell) => {
    maxColumnLength = Math.max(
      maxColumnLength,
      minimumWidth,
      cell.value ? cell.value.toString().length : 0
    );
  });
  maxColumnLength = maxColumnLength > maxWidth ? maxWidth : maxColumnLength;
  column.width = maxColumnLength + 2;
};

const autoSizeRowHeight = (row, minimumHeight = 15) => {
  let maxRowHeight = 0;
  row.eachCell({ includeEmpty: true }, (cell) => {
    let cellHeight = 0;
    if (cell.value instanceof String) {
      cellHeight =
        Math.ceil(
          cell.value.length / (cell.worksheet.getColumn(cell.col).width - 2)
        ) * 15;
    } else if (cell.formula != null) {
      // Will be slightly longer than actual value
      cellHeight =
        Math.ceil(
          cell.formula.length /
            2 /
            (cell.worksheet.getColumn(cell.col).width - 2)
        ) * 15;
    }
    maxRowHeight = Math.max(maxRowHeight, minimumHeight, cellHeight);
  });
  row.height = maxRowHeight;
};

var makeColumns = function (mainSheet, rows, columns, opts) {
  opts = opts || {};
  var userData = opts.userData || {};
  var startRow = opts.startRow != null ? opts.startRow : 1;
  var groupNames = {};
  var groupSubNames = {};
  columns.map(function (col, i) {
    if (col.groupName != null) {
      var obj = groupNames[col.groupName] || { start: null, end: null };
      groupNames[col.groupName] = obj;
      if (obj.start == null || obj.start > i) obj.start = i;
      if (obj.end == null || obj.end < i) obj.end = i;
    }
    if (col.groupSubName != null) {
      var obj = groupSubNames[col.groupSubName] || { start: null, end: null };
      groupSubNames[col.groupSubName] = obj;
      if (obj.start == null || obj.start > i) obj.start = i;
      if (obj.end == null || obj.end < i) obj.end = i;
    }
  });

  var centering = { wrapText: true, vertical: "middle", horizontal: "center" };
  var line = { style: "thin" };
  var fullBorder = { top: line, left: line, bottom: line, right: line };

  var topRow = mainSheet.getRow(startRow);
  var subRow = mainSheet.getRow(startRow + 1);

  var hasSubRow = columns.some(function (col) {
    return col.subName != null || col.groupSubName != null;
  });

  topRow.alignment = {
    wrapText: true,
    vertical: "middle",
    horizontal: "center"
  };
  if (hasSubRow)
    subRow.alignment = {
      wrapText: true,
      vertical: "middle",
      horizontal: "center"
    };

  for (const name in groupNames) {
    const obj = groupNames[name];
    if (obj.start === obj.end) {
      // If only one column, not a group, just a name
      columns[obj.start].name = name;
      delete columns[obj.start].groupName;
    }
    const startCell = topRow.getCell(obj.start + 2);
    startCell.value = name;
    startCell.border = fullBorder;
    startCell.alignment = centering;
    mainSheet.mergeCells(startRow, obj.start + 2, startRow, obj.end + 2);
  }

  for (const name in groupSubNames) {
    const obj = groupSubNames[name];
    if (obj.start === obj.end) {
      // If only one column, not a group, just a name
      columns[obj.start].subName = name;
      delete columns[obj.start].groupSubName;
    }
    const startCell = subRow.getCell(obj.start + 2);
    startCell.value = name;
    startCell.border = fullBorder;
    startCell.alignment = centering;
    mainSheet.mergeCells(
      startRow + 1,
      obj.start + 2,
      startRow + 1,
      obj.end + 2
    );
  }

  mainSheet.getColumn(1).width = 22;
  mainSheet
    .getRow(startRow + (opts.summaryRow != null ? 2 : 1))
    .getCell(1).border = {
    top: line,
    left: line,
    bottom: hasSubRow ? line : null
  };

  columns.map(function (columnConfig, i) {
    const columnIndex = (!opts.skipNameCol ? 2 : 1) + i;
    autoSizeAllColumns(mainSheet);

    mainSheet.getRow(startRow - 1).getCell(columnIndex).border = {
      bottom: line
    };

    if (columnConfig.money) {
      // Excel number formatting explained
      // https://www.ablebits.com/office-addins-blog/custom-excel-number-format/#Understanding-Excel-number
      mainSheet.getColumn(columnIndex).numFmt = '"$"#,##0.00;[Red]-"$"#,##0.00';
    }

    if (columnConfig.bgcolor != null) {
      topRow.getCell(columnIndex).fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: columnConfig.bgcolor }
      };
    }

    if (columnConfig.name != null) {
      if (columnConfig.font != null) {
        (hasSubRow && columnConfig.groupSubName == null
          ? subRow
          : topRow
        ).getCell(columnIndex).value = {
          richText: [{ font: columnConfig.font, text: columnConfig.name }]
        };
      } else {
        (hasSubRow && columnConfig.groupSubName == null
          ? subRow
          : topRow
        ).getCell(columnIndex).value = columnConfig.name;
      }
    }
    topRow.getCell(columnIndex).border = { left: line, right: line };

    if (columnConfig.subName != null) {
      if (columnConfig.font != null) {
        subRow.getCell(columnIndex).value = {
          richText: [{ font: columnConfig.font, text: columnConfig.subName }]
        };
      } else {
        subRow.getCell(columnIndex).value = columnConfig.subName;
      }
      if (columnConfig.bgcolor != null) {
        subRow.getCell(columnIndex).fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: columnConfig.bgcolor }
        };
      }
    }
    subRow.getCell(columnIndex).border = {
      left: line,
      right: line,
      bottom: line
    };
  });

  var drawRow = function (data, row_i) {
    var row = mainSheet.getRow(
      startRow + (opts.summaryRow != null ? 2 : 1) + (hasSubRow ? 1 : 0) + row_i
    );

    if (!opts.skipNameCol) {
      row.getCell(1).value = data.name;
      row.getCell(1).alignment = {
        vertical: "middle",
        horizontal: "center",
        wrapText: true
      };
    }

    var getUserVal = function (uId, col) {
      var colData = userData[uId][col.key];
      return col.lengthKey != null ? colData[col.lengthKey].length : colData;
    };

    columns.map(function (col, col_i) {
      var cell = row.getCell((!opts.skipNameCol ? 2 : 1) + col_i);

      cell.alignment = col.customFormat
        ? col.customFormat
        : { vertical: "middle", horizontal: "center" };

      if (col.wrap) cell.alignment.wrapText = true;
      if (col.customFormating != null) col.customFormating(cell, data);

      if (col.custom) {
        cell.value = col.custom(data, userData);
      } else if (data.summary) {
        var allNums = rows
          .map(function (item) {
            var count =
              item.uId != null
                ? getUserVal(item.uId, col)
                : _.get(item, col.key);
            return count;
          })
          .filter(function (n) {
            return n > 0;
          });
        if (col.total != null) {
          cell.value = allNums.length == 0 ? "-" : _.sum(allNums);
        } else if (col.avg != null) {
          cell.value =
            allNums.length == 0 ? "-" : Math.round(_.mean(allNums) * 100) / 100;
        } else {
          cell.value = "";
        }
      } else if (col.f != null) {
        cell.value = { formula: col.f(data, userData) };
      } else {
        let cellValue =
          data.uId != null ? getUserVal(data.uId, col) : _.get(data, col.key);
        if (cellValue == null) {
          cellValue = "";
        }

        cell.value = cellValue === 0 ? "-" : cellValue;

        if ((cell.value || "").length === 0 && !!col.emptyIfNone) {
          cell.value = null;
        }
      }

      var groupObj = groupNames[col.groupName];
      var subGroupObj = groupSubNames[col.groupSubName];
      var noGroup = groupObj == null && subGroupObj == null;

      cell.border = {
        bottom: data.summary ? line : null,
        left: noGroup
          ? line
          : (groupObj == null || groupObj.start == col_i) &&
              (subGroupObj == null || subGroupObj.start == col_i)
            ? line
            : null,
        right: noGroup
          ? line
          : (groupObj == null || groupObj.end == col_i) &&
              (subGroupObj == null || subGroupObj.end == col_i)
            ? line
            : null
      };

      if (col.money) {
        // Excel number formatting explained
        // https://www.ablebits.com/office-addins-blog/custom-excel-number-format/#Understanding-Excel-number
        cell.numFmt = '"$"#,##0.00;[Red]-"$"#,##0.00';
      }
    });
  };

  if (opts.summaryRow != null) {
    drawRow({ name: opts.summaryRow, summary: true }, -1); // Header
  }

  rows.map(drawRow);

  if (!opts.skipNameCol) {
    autoSizeColumn(mainSheet.getColumn(1));
  }
  for (let row_i = 0; row_i < mainSheet.rowCount; row_i++) {
    autoSizeRowHeight(mainSheet.getRow(row_i));
  }
};
export { makeColumns };

var makeRows = function (mainSheet, rows, columns, opts) {
  // Transpose of makeColumns. Can try to merge in the future with opts.transpose = true;
  opts = opts || {};
  var userData = opts.userData || {};
  var startRow = opts.startRow != null ? opts.startRow : 1;

  // Need to make space for groupNames

  var rowTypes = [];
  var currentGroup = null;
  columns.map(function (col, i) {
    if (col.groupName != null) {
      if (col.groupName != currentGroup) rowTypes.push({ name: col.groupName });
      currentGroup = col.groupName;
      rowTypes.push({ col: col, group: currentGroup, name: col.subName });
    } else {
      currentGroup = null;
      rowTypes.push({ col: col, group: currentGroup, name: col.name });
    }
  });

  var centering = { vertical: "middle", horizontal: "center" };
  var line = { style: "thin" };
  var fullBorder = { top: line, left: line, bottom: line, right: line };

  var firstCol = mainSheet.getColumn(1);

  var hasSubRow = columns.some(function (col) {
    return col.subName != null || col.groupSubName != null;
  });

  firstCol.alignment = {
    wrapText: true,
    vertical: "middle",
    horizontal: "center"
  };

  rowTypes.map(function (rowType, i) {
    var startCell = mainSheet.getCell(startRow + i + 1, 1);

    startCell.alignment = {
      wrapText: true,
      vertical: "middle",
      horizontal: "center"
    };
    if (rowType.col == null) {
      startCell.value = {
        richText: [
          {
            font: { bold: true, color: { argb: "FFFFFFFF" } },
            text: rowType.name
          }
        ]
      };
      startCell.border = { top: line };

      startCell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FF808080" }
      };
    } else {
      startCell.value = rowType.name;
      startCell.border =
        rowType.col.group == null
          ? { right: line }
          : { top: line, right: line };
    }

    var startOfGroup = rowType.col == null;
    var inOfGroup = rowType.col != null && rowType.group != null;
    var normalLine = rowType.col != null && rowType.group == null;
    startCell.border = {
      right: line
      // top: (startOfGroup || normalLine) ? line : null,
    };
  });

  mainSheet.getColumn(1).width = 22;

  var drawRow = function (data, row_i) {
    var col_i = (opts.summaryRow != null ? 2 : 1) + row_i + 1;
    // var row = mainSheet.getColumn();
    if (!opts.skipNameCol) {
      mainSheet.getCell(startRow, col_i).value = data.name;
      mainSheet.getCell(startRow, col_i).alignment = {
        wrapText: true,
        vertical: "middle",
        horizontal: "center"
      };
    }

    mainSheet.getColumn(col_i).width = 15;

    var getUserVal = function (uId, col) {
      var colData = userData[uId][col.key];
      return col.lengthKey != null ? colData[col.lengthKey].length : colData;
    };

    rowTypes.map(function (rowType, row_i) {
      var col = rowType.col;
      var cell = mainSheet.getCell(startRow + row_i + 1, col_i);

      if (rowType.col == null) {
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FF808080" }
        };
        return;
      }

      cell.alignment = col.customFormat
        ? col.customFormat
        : { vertical: "middle", horizontal: "center" };
      if (col.wrap) cell.alignment.wrapText = true;

      if (col.custom) {
        cell.value = col.custom(data, userData);
      } else if (data.summary) {
        var allNums = rows
          .map(function (item) {
            var count =
              item.uId != null
                ? getUserVal(item.uId, col)
                : _.get(item, col.key);
            return count;
          })
          .filter(function (n) {
            return n > 0;
          });
        if (col.total != null) {
          cell.value = allNums.length === 0 ? "-" : _.sum(allNums);
        } else if (col.avg != null) {
          cell.value =
            allNums.length === 0
              ? "-"
              : Math.round(_.mean(allNums) * 100) / 100;
        } else {
          cell.value = "";
        }
      } else if (col.f != null) {
        cell.value = { formula: col.f(data, userData) };
      } else {
        var count =
          data.uId != null ? getUserVal(data.uId, col) : _.get(data, col.key);
        if (count == null) count = "";

        cell.value = count == 0 ? "-" : count;
      }

      cell.border = {
        right: data.summary ? line : null
      };
    });
  };

  if (opts.summaryRow != null) {
    drawRow({ name: opts.summaryRow, summary: true }, -1); // Header
  }
  rows.map(drawRow);
};
export { makeRows };

var stanfordDemoOptions = {
  race: [
    {
      value: "amIndian",
      name: "American Indian or Alaska Native"
    },
    {
      value: "aa",
      name: "Asian or Asian American"
    },
    {
      value: "black",
      name: "Black or African American"
    },
    {
      value: "latinx",
      name: "Hispanic or Latinola"
    },
    {
      value: "middleEast",
      name: "Middle Eastern or North African"
    },
    {
      value: "pi",
      name: "Native Hawai'ian or Pacific Islander"
    },
    {
      value: "white",
      name: "White or European"
    },
    {
      value: "decline",
      name: "Prefer not to say"
    },
    {
      value: "other",
      name: "Other Races (Total)"
    }
  ],
  gender: [
    { value: "female", name: "Woman" },
    { value: "male", name: "Man" },
    { value: "decline", name: "Prefer not to say" },
    { value: "other", name: "Other Genders (Total)" }
  ],
  trainingOptions: [
    {
      value: "resInUSA",
      name: "Anesthesiology Residency training in the USA"
    },
    {
      value: "resOutUSA",
      name: "Anesthesiology Residency training outside the USA"
    },
    {
      value: "resStanford",
      name: "Anesthesiology Residency completed at Stanford"
    },
    {
      value: "fell",
      name: "Anesthesiology Fellowship-trained"
    },
    {
      value: "fellStanford",
      name: "Anesthesiology Fellowship completed at Stanford"
    }
  ]
};

export const getUserData = function (
  uId,
  pmidData,
  startDate,
  endDate,
  { users, user, cvList, hierarchyNames }
) {
  const cvId = users[uId].cvId;
  let cvDef = cvList[cvId];

  const division = users[uId].paths
    .map(function (path) {
      return path.split("/")[2];
    })
    .filter(function (path) {
      return path != null;
    })
    .map(function (path) {
      return hierarchyNames[path];
    })
    .join(", ");

  // Lists
  const scholarship = getItemsWithinDateRange(
    getField(cvDef, "scholarship"),
    startDate,
    endDate
  );

  const editorialActivities = getItemsWithinDateRange(
    getField(cvDef, "editorialActivities"),
    startDate,
    endDate
  );

  const grantReviewActivities = getItemsWithinDateRange(
    getField(cvDef, "grantReviewActivities"),
    startDate,
    endDate
  );

  const teaching = getItemsWithinDateRange(
    getField(cvDef, "teaching"),
    startDate,
    endDate
  );

  const community = getItemsWithinDateRange(
    getField(cvDef, "community"),
    startDate,
    endDate
  );

  const communityRolesCount = community.length;

  const cvPMIDs = scholarship
    .filter(function (item) {
      return (
        item.PMID != null &&
        pmidData[item.PMID] != null &&
        pmidData[item.PMID].authorList != null
      );
    })
    .map(function (n) {
      return n.PMID;
    });

  const highImpactContributionsCount = cvPMIDs.filter((pmId) => {
    const pmEntry = pmidData[pmId];
    return highImpactJournalISSNs.has(pmEntry.issn);
  }).length;

  var areaOfExcellence =
    {
      innovation: "Clinical Expertise and Innovation",
      investigation: "Investigation",
      education: "Teaching and Educational Leadership"
    }[getKey(getKey(cvDef, "narrative", {}), "area", "")] || "-";

  var highlightNames = getKey(cvDef, "citationName", "")
    .split(";")
    .filter(function (n) {
      return n !== "";
    });

  const allScholarship = _.get(cvDef, "lists.scholarship");
  const allCVPMIds = Object.values(allScholarship)
    .filter(function (item) {
      return (
        item?.PMID != null &&
        pmidData[item?.PMID] != null &&
        pmidData[item?.PMID].authorList != null
      );
    })
    .map(function (n) {
      return n.PMID;
    });

  const allCitations = _.sortBy(
    allCVPMIds.map((pmID) => {
      return {
        id: pmID,
        count: pmidData[pmID].numCitations,
        sjrScore: pmidData[pmID].sjrScore,
        authorList: pmidData[pmID].authorList
      };
    }),
    function (n) {
      return -n.count;
    }
  );

  var hPlusIndex = allCitations.findIndex(function (n, i) {
    return n.count < i + 1;
  });
  var hIndex =
    allCitations.length === 0 || hPlusIndex <= 0 ? 0 : hPlusIndex - 1;

  var sjrScores = cvPMIDs
    .map(function (pmid) {
      return pmidData[pmid].sjrScore;
    })
    .filter(function (n) {
      return n != null;
    });

  let firstAuthorList = [];
  let lastAuthorList = [];
  let firstAuthor = 0;
  let lastAuthor = 0;
  let sjrRange = "-";

  if (sjrScores.length > 0) {
    const minScore = _.min(sjrScores);
    const maxScore = _.max(sjrScores);

    firstAuthorList = cvPMIDs.filter(function (pmid) {
      const author = pmidData[pmid].authorList[0] || {};
      const citName = getCitName(author);

      return highlightNames.some(function (hName) {
        return hName.toLowerCase() === citName.toLowerCase();
      });
    });
    firstAuthor = firstAuthorList.length;
    lastAuthorList = cvPMIDs.filter(function (pmid) {
      const author = pmidData[pmid].authorList.slice(-1)[0] || {};
      const citName = getCitName(author);
      return highlightNames.some(function (hName) {
        return hName.toLowerCase() === citName.toLowerCase();
      });
    });
    lastAuthor = lastAuthorList.length;
    if (maxScore == null || minScore == null) {
      console.error("either maxScore or minScore is null");
    }
    sjrRange = maxScore + " - " + minScore;
  }

  var totalPublications = scholarship.filter(function (item) {
    return item.PMID != null;
  }).length;

  var middleAuthor = totalPublications - firstAuthor - lastAuthor;

  const allPeerReviewed = scholarship.filter(function (item) {
    return item.type === "Research Investigations";
  }).length;
  const scholWithoutNamedAuthorship = scholarship.filter(function (item) {
    return item.type === "Scholarship without named authorship";
  }).length;
  const meetingProceedings = scholarship.filter(function (item) {
    return (
      item.type ===
      "Proceedings of meetings or other non-peer reviewed scholarship"
    );
  }).length;
  const otherPeerReviewedScholarshipCount = scholarship.filter((item) => {
    return item.type === "Other peer-reviewed scholarship";
  }).length;
  const reviews = scholarship.filter(function (item) {
    return (
      item.type === "Reviews, chapters, monographs and editorials" ||
      item.type === "reviews"
    );
  }).length;
  const chapters = scholarship.filter(function (item) {
    return item.type === "chapters";
  }).length;
  const books = scholarship.filter(function (item) {
    return (
      item.type === "Books/textbooks for the medical or scientific community" ||
      item.type === "books"
    );
  }).length;

  var posters = scholarship.filter(function (item) {
    return item.type === "posters";
  }).length;
  var nonPrint = scholarship.filter(function (item) {
    return item.type === "nonPrint";
  }).length;
  var otherPeer = scholarship.filter(function (item) {
    return item.type === "otherPeer";
  }).length;
  var otherAbstracts = scholarship.filter(function (item) {
    return item.type === "otherAbstracts";
  }).length;

  var patents = scholarship.filter(function (item) {
    return item.type === "Patents";
  }).length;
  var caseReports = scholarship.filter(function (item) {
    return item.type === "Case reports";
  }).length;
  var lettersToEditor = scholarship.filter(function (item) {
    return item.type == "Letters to the Editor";
  }).length;
  var professionalEducationMaterial = scholarship.filter(function (item) {
    return (
      item.type ==
      "Professional educational materials or reports, in print or other media"
    );
  }).length;
  var clinGuidelines = scholarship.filter(function (item) {
    return item.type == "Clinical Guidelines and Reports";
  }).length;
  var theses = scholarship.filter(function (item) {
    return item.type == "Thesis";
  }).length;
  var abstracts = scholarship.filter(function (item) {
    return (
      item.type ==
        "Abstracts, Poster Presentations and Exhibits Presented at Professional Meetings" ||
      item.type == "abstracts"
    );
  }).length;

  const otherEditorialRolesCount = editorialActivities.filter(
    (editorialActivity) => {
      return editorialActivity?.type === "Other Editorial Roles";
    }
  ).length;

  const grantReviewActivitiesCount = grantReviewActivities.length;

  var presentations = getItemsWithinDateRange(
    getField(cvDef, "presentations"),
    startDate,
    endDate
  );
  const totalLectures = presentations.length;
  const intLectures = presentations.filter(function (n) {
    return n.type === "international";
  }).length;
  const natLectures = presentations.filter(function (n) {
    return n.type === "national";
  }).length;
  const regLectures = presentations.filter(function (n) {
    return n.type === "regional";
  }).length;
  const locLectures = presentations.filter(function (n) {
    return n.type === "local";
  }).length;

  const formalTeachingOfPostDocsCount = teaching.filter((teaching) => {
    return (
      teaching?.type ===
      "Formal Teaching of Residents, Clinical Fellows and Research Fellows (post-docs)"
    );
  }).length;

  const localPresentations = locLectures + formalTeachingOfPostDocsCount;

  const totalPubAndLectures = totalPublications + totalLectures;

  const projects = getItemsWithinDateRange(
    getField(cvDef, "projects"),
    startDate,
    endDate
  );
  const ongoingProjects = projects.filter(function (project) {
    return project.time.end === "ongoing";
  });
  const totalProjectsCount = projects.length;
  const fundedProjects = projects.filter(function (n) {
    return n.type === "funded";
  }).length;
  const piOrCoPiProjects = projects.filter(function (project) {
    return (
      project.role === "Principal Investigator" ||
      project.role === "Co-Principal Investigator"
    );
  }).length;
  const projectsAsOtherRoles = totalProjectsCount - piOrCoPiProjects;

  const fundingAmounts = projects
    .filter(function (n) {
      return n.type === "funded";
    })
    .map(function (n) {
      return n.directCosts != null
        ? parseFloat(n.directCosts.split(/\$|\,/).join(""))
        : 0;
    });
  const totalFunding = fundingAmounts
    .filter(function (n) {
      return !isNaN(n);
    })
    .reduce(function (a, b) {
      return a + b;
    }, 0);
  if (isNaN(totalFunding)) {
    console.error(`Total funding is not a number: ${totalFunding}`);
  }

  /**
   * Awards/honors
    Count of admin leadership roles
    Count of committee roles
    Count of prof society roles
    */
  var numHonors = getItemsWithinDateRange(
    getField(cvDef, "honors"),
    startDate,
    endDate
  ).length;
  var numAdminRoles = getItemsWithinDateRange(
    getField(cvDef, "administrativePositions"),
    startDate,
    endDate
  ).length;
  var numCommittees = getItemsWithinDateRange(
    getField(cvDef, "committeeService"),
    startDate,
    endDate
  ).length;
  var numSocieties = getItemsWithinDateRange(
    getField(cvDef, "societies"),
    startDate,
    endDate
  ).length;

  var firstAuthorSJR = firstAuthorList.map(function (pmid) {
    return pmidData[pmid].sjrScore;
  });
  var lastAuthorSJR = lastAuthorList.map(function (pmid) {
    return pmidData[pmid].sjrScore;
  });
  var anyAuthorSJR = cvPMIDs.map(function (pmid) {
    return pmidData[pmid].sjrScore;
  });

  var sjrBuckets = function (list) {
    return [
      list.filter(function (SJR) {
        return SJR >= 0 && SJR < 3;
      }),
      list.filter(function (SJR) {
        return SJR >= 3 && SJR < 6;
      }),
      list.filter(function (SJR) {
        return SJR >= 6 && SJR < 10;
      }),
      list.filter(function (SJR) {
        return SJR >= 10;
      }),
      list.filter(function (SJR) {
        return SJR == null || SJR == 0;
      })
    ];
  };

  // TODO Rename sjrBuckets
  var firstAuthorBuckets = sjrBuckets(firstAuthorSJR);
  var lastAuthorBuckets = sjrBuckets(lastAuthorSJR);
  var anyAuthorBuckets = sjrBuckets(anyAuthorSJR);

  var currentTitle = "";
  var yearsWithTitle = "";
  var lastP = getLastPosition(cvDef);
  if (lastP != null) {
    currentTitle = lastP.title;
    yearsWithTitle = lastP.years;
  }

  var res = {
    highImpactContributionsCount,
    totalPubAndLectures,
    formalTeachingOfPostDocsCount,
    localPresentations,
    totalPublications,
    allPeerReviewed,
    firstAuthor,
    lastAuthor,
    middleAuthor,
    sjrRange,
    hIndex,
    scholWithoutNamedAuthorship,
    meetingProceedings,
    otherPeerReviewedScholarshipCount,
    reviews,
    books,
    chapters,
    patents,
    caseReports,
    posters,
    nonPrint,
    otherPeer,
    otherAbstracts,
    lettersToEditor,
    professionalEducationMaterial,
    clinGuidelines,
    theses,
    abstracts,
    otherEditorialRolesCount,
    grantReviewActivitiesCount,
    totalLectures,
    intLectures,
    natLectures,
    regLectures,
    locLectures,
    ongoingProjects,
    totalProjectsCount,
    fundedProjects,
    piOrCoPiProjects,
    projectsAsOtherRoles,
    totalFunding,
    numHonors,
    numAdminRoles,
    numCommittees,
    numSocieties,
    areaOfExcellence,
    firstAuthorBuckets,
    lastAuthorBuckets,
    anyAuthorBuckets,
    currentTitle,
    yearsWithTitle,
    division,
    communityRolesCount
  };

  if (user != null && !!(user.settings || {}).newUserDemoAccess) {
    var bio = cvDef.bio || {};
    var birthYear = parseInt(bio.birthYear);
    var year = 1000 * 60 * 60 * 24 * 365;
    var age =
      birthYear == null
        ? null
        : Math.floor(
            (new Date().getTime() - new Date(birthYear, 1, 1).getTime()) / year
          );

    res.age = age || "";
    res.gender = stanfordDemoOptions.gender
      .filter(function (n) {
        return !!(bio.gender || {})[n.value];
      })
      .map(function (n) {
        return n.name;
      })
      .join(", ");

    res.race = stanfordDemoOptions.race
      .filter(function (n) {
        return !!(bio.race || {})[n.value];
      })
      .map(function (n) {
        return n.name;
      })
      .join(", ");

    res.trainingOptions = stanfordDemoOptions.trainingOptions
      .filter(function (n) {
        return !!(bio.training1 || {})[n.value];
      })
      .map(function (n) {
        return n.name;
      })
      .join(", ");
  }

  return res;
};

var getLastPosition = function (cvObj, opts) {
  opts = opts || {};
  var facultyAppointments = _.orderBy(
    getField(cvObj, "facultyAppointments"),
    sectionSort({})
  );

  var namesByFormat = {
    hms: ["hms", "harvard", "brigham", "mgh", "mass gen", "bch", "boston"],
    hms_dom: ["hms", "harvard", "brigham", "mgh", "mass gen", "bch", "boston"],
    stanford: ["stanford"],
    stanford_anesthesia: ["stanford"],
    stanford_obgyn: ["stanford"]
  };

  if (
    typeof opts.format !== "undefined" &&
    namesByFormat[opts.format] != null
  ) {
    // Filter by site names
    facultyAppointments = facultyAppointments.filter(function (item) {
      if ((item.location || "").trim().length == 0) return true;
      return namesByFormat[opts.format].some(function (siteName) {
        return item.location.toLowerCase().indexOf(siteName) >= 0;
      });
    });
  }

  var roles = [
    {
      type: "fellow",
      filter: function (text) {
        return text.toLowerCase().indexOf("fellow") >= 0;
      }
    },
    {
      type: "instructor",
      filter: function (text) {
        return text.toLowerCase().indexOf("instructor") >= 0;
      }
    },
    {
      type: "assistant",
      filter: function (text) {
        return text.toLowerCase().indexOf("assistant") >= 0;
      }
    },
    {
      type: "associate",
      filter: function (text) {
        return text.toLowerCase().indexOf("associate") >= 0;
      }
    },
    {
      type: "full",
      filter: function (text) {
        return (
          ["fellow", "instructor", "assistant", "associate"].every(
            function (key) {
              return text.toLowerCase().indexOf(key) == -1;
            }
          ) && text.toLowerCase().indexOf("professor") >= 0
        );
      }
    }
  ];

  const ongoingFacultyAppointments = facultyAppointments.filter(
    (appointment) => {
      return ((appointment || {}).time || {}).end == "ongoing";
    }
  );
  facultyAppointments = _.orderBy(
    ongoingFacultyAppointments.length
      ? ongoingFacultyAppointments
      : facultyAppointments,
    [
      (item) => (item.preferred ? 1 : 0),
      function (item) {
        return roles.findIndex(function (t) {
          return t.filter(item.title || "");
        });
      }
    ]
  );
  var last = facultyAppointments.slice(-1)[0];
  if (last == null || last.title == null) return null;

  var role = roles.find((t) => t.filter(last.title));
  var roleI = roles.findIndex((t) => t.filter(last.title));

  var plainTitle = htmlToXML(last.title, { justText: true }).trim();
  var res = {
    years: 0,
    time: last.time,
    location: last.location,
    title: plainTitle,
    role: (role || {}).type,
    roleI: roleI
  };
  if (last.time.start == null) return res;
  res.years =
    ((last.time.end == null || last.time.end == "ongoing"
      ? getYears(
          {
            month: new Date().getMonth() + 1,
            year: new Date().getFullYear()
          },
          { limitOngoing: true }
        )
      : getYears(last.time.end, { limitOngoing: true })) -
      getYears(last.time.start, { limitOngoing: true })) /
    (day * 365);
  res.years = Math.round(res.years * 100) / 100;
  return res;
};
export { getLastPosition };

export const yearsSinceDate = function (startDate) {
  return (
    (new Date().getTime() - startDate.getTime()) /
    (1000 * 60 * 60 * 24 * 365)
  ).toFixed(2);
};

export const removeRichTextTags = (data) => {
  const link_match =
    /<a href=(["'])(.*?)\1 target=(["']).*?\3 rel=(["']).*?\4>(.*?)<\/a>/g;
  const richTextParsed = reportParseRichText(data);
  let str = "";
  for (const richText of Object.values(richTextParsed)) {
    for (const entry of richText) {
      str += entry.text;
    }
  }
  if (link_match.test(str)) {
    str = str.replace(link_match, "$5");
  }
  return str;
};
export const reportParseRichText = (data) => {
  const richText = [];
  let chunk = "";
  const richTextTags = {
    "<em>": { italic: true },
    "</em>": { italic: true },
    "<strong>": { bold: true },
    "</strong>": { bold: true },
    "<u>": { underline: true },
    "</u>": { underline: true },
    "<sup>": { vertAlign: "superscript" },
    "</sup>": { vertAlign: "superscript" },
    "<sub>": { vertAlign: "subscript" },
    "</sub>": { vertAlign: "subscript" }
  };
  for (let i = 0; i < data.length; ++i) {
    if (data.charAt(i) === "<") {
      let close = i + 1;
      while (data.charAt(close) != ">") {
        const part = data.substring(i, close);
        if (Object.keys(richTextTags).some((tag) => tag.includes(part))) {
          close++;
          if (close - i > 20) break;
        } else {
          break;
        }
      }
      const tag = data.substring(i, close + 1);
      if (Object.keys(richTextTags).some((rtt) => rtt == tag)) {
        if (tag.includes("/")) {
          richText.push({
            font: richTextTags[tag],
            text: chunk
          });
        } else {
          richText.push({
            text: chunk
          });
        }
        chunk = "";
        i = close;
      } else {
        chunk += data.charAt(i);
      }
    } else {
      chunk += data.charAt(i);
    }
  }
  if (chunk.length) {
    richText.push({ text: chunk });
  }
  return { richText: richText };
};
