import { DEFAULT_TAB_SECTION, StoredSubtabFieldSource } from "@easycv/common/types/storedFormat";
import { default as getAcgmeTemplateSection } from "../customSections/acgme.js";
import { default as getBiosketchSection } from "../customSections/biosketch.js";
import { default as getHomeSetup } from "../customSections/homeSetup.js";
import { getBiosOutput, getExtraPINameField, previewSection, previewTextField, serverACGMEResult } from "../lib/formatUtilities.js";
import { anyPathOverlap } from "../lib/hierarchyUtilities.js";
const settingsInputSection = {
    field: "overview",
    icon: "home",
    type: "custom",
    title: "Settings",
    setup: getHomeSetup({ citationNames: true })
};
export class FormatWrapper {
    Title;
    code;
    template;
    defaultInput;
    input;
    output;
    preview;
    settings;
    outputSettings;
    constructor(stored, formats) {
        this.Title = stored.title;
        this.code = stored._id.toString();
        this.defaultInput = "overview";
        this.input = [settingsInputSection];
        this.output = {};
        this.outputSettings = { font: "Times New Roman", fontSize: 12 };
        this.preview = [];
        if (stored.outputSourceType == "static") {
            this.output = formats[stored.outputSource].output;
            this.preview = formats[stored.outputSource].preview;
            this.template =
                stored.outputFile ?? formats[stored.outputSource].template;
            this.applyOutputOverrides(stored.output);
        }
        else {
            this.template =
                stored.outputFile ?? "/templateFiles/generic_template_v1_4.docx";
        }
        const sectionCounts = {};
        for (const tab of stored.tabs) {
            if (tab.conflicting != undefined)
                continue;
            if (tab.section == DEFAULT_TAB_SECTION)
                delete tab.section;
            if (tab.section != undefined) {
                sectionCounts[tab.section] ??= 0;
                if (sectionCounts[tab.section] != 0) {
                    tab.section = tab.section + "-" + sectionCounts[tab.section];
                }
                sectionCounts[tab.section]++;
            }
            if (tab.type === "custom") {
                if (tab.key == "acgme") {
                    this.input.push(getAcgmeTemplateSection(serverACGMEResult));
                }
                else if (tab.key == "biosketch") {
                    this.input.push(getBiosketchSection());
                }
            }
            else {
                const items = [];
                // Collect subtabs from twiz into legacy compatible array
                const subtabTypes = [];
                for (const subtab of (tab.subtabs ?? []).filter(Boolean)) {
                    subtabTypes.push([subtab.key, subtab.title]);
                }
                // If there are subtabs types, and the tab type is a list, add the subtab "type: type" thing
                if (subtabTypes.length > 0 && tab.type === "list") {
                    items.push({
                        type: "type",
                        types: subtabTypes
                    });
                }
                for (const storedField of tab.fields) {
                    const overrides = {};
                    const rejections = [];
                    for (const storedSubtab of tab.subtabs) {
                        for (const subtabOverride of storedSubtab.overrideFields) {
                            if (subtabOverride.key === storedField.key) {
                                for (const subtabOverrideKey of Object.keys(subtabOverride)) {
                                    overrides[subtabOverrideKey] = {
                                        ...overrides[subtabOverrideKey],
                                        [storedSubtab.key]: subtabOverride[subtabOverrideKey]
                                    };
                                }
                            }
                        }
                        if (storedSubtab.rejectFields.includes(storedField.key)) {
                            rejections.push(storedSubtab.key);
                        }
                    }
                    if (overrides["sublist"] != undefined) {
                        for (const k of Object.keys(overrides["sublist"])) {
                            overrides["sublist"][k] = {
                                ...overrides["sublist"][k]
                            };
                        }
                    }
                    const runtimeField = {
                        name: storedField.key,
                        type: storedField.type === "string" ? undefined : storedField.type,
                        label: (item) => overrides.title?.[item.type] ?? storedField.title,
                        required: (item) => overrides.required?.[item.type] ?? storedField.required,
                        ...(storedField.type === "select"
                            ? {
                                options: storedField.options,
                                allowOther: storedField.allowOther
                            }
                            : {}),
                        ...(storedField.type === "list"
                            ? {
                                sublist: storedField.sublist
                            }
                            : {}),
                        filter: (item) => !rejections.includes(item.type)
                    };
                    items.push(runtimeField);
                    if (storedField.type === "select") {
                        if (storedField.extraPI === true) {
                            items.push(getExtraPINameField());
                        }
                    }
                }
                const privateFields = {};
                for (const storedSubtab of tab.subtabs) {
                    const indicies = storedSubtab.fieldOrdering
                        .map((fO, i) => fO === StoredSubtabFieldSource.Private ? i : undefined)
                        .filter((i) => i != undefined);
                    indicies.reverse();
                    for (const pf of storedSubtab.privateFields ?? []) {
                        const index = indicies.pop();
                        privateFields[index] ??= [];
                        privateFields[index].push({
                            name: pf.key,
                            type: pf.type === "string" ? undefined : pf.type,
                            label: pf.title,
                            required: pf.required,
                            ...(pf.type === "select"
                                ? {
                                    options: pf.options
                                }
                                : {}),
                            ...(pf.type === "list"
                                ? {
                                    sublist: pf.sublist
                                }
                                : {}),
                            filter: (item) => {
                                return item.type === storedSubtab.key;
                            }
                        });
                    }
                }
                items.reverse();
                const combined = [];
                for (const ki of Object.keys(privateFields)) {
                    while (combined.length < Number(ki) && items.length > 0) {
                        combined.push(items.pop());
                    }
                    combined.push(privateFields[ki]);
                }
                while (items.length > 0) {
                    combined.push(items.pop());
                }
                this.input.push({
                    type: tab.type,
                    title: tab.title,
                    field: tab.key,
                    section: tab.section,
                    items: combined.flat(Infinity)
                });
            }
            if (stored.outputSourceType == undefined ||
                stored.outputSourceType == "dynamic") {
                this.generateOutputObject(tab);
                this.generatePreviewObject(tab);
            }
        }
        for (const p of stored.preview) {
            if (p.macro === "previewTextField") {
                this.preview.push(previewTextField(p.name, {
                    prefix: p.prefix,
                    suffix: p.suffix
                }));
            }
        }
        this.applyNonParsables(stored, formats);
    }
    generateOutputObject(tab) {
        // Generate the Output object
        if (tab.key == "bio") {
            // Bio is special for some reason
            this.output["bio"] = {
                field: "bio",
                map: {
                    bios: getBiosOutput(tab.fields.map((item) => {
                        return { key: item.key, title: item.title };
                    }), { font: "Times New Roman", fontSize: 12 })
                }
            };
            tab.fields.forEach((item) => {
                this.output["bio"].map["bio_" + item.key] = item.key;
            });
        }
        else {
            this.output[tab.key] = {
                field: tab.key,
                format: { time: ["yearRange"] } // TODO how to determine how to show dates
            };
            tab.fields.forEach((item, index) => {
                this.output[tab.key].format[(index + 1).toString()] = [item.key];
            });
        }
    }
    generatePreviewObject(tab) {
        if (tab.type == "obj" && tab.key == "bio") {
            tab.fields.forEach((item) => {
                this.preview.push(previewTextField("bio_" + item.key, { prefix: item.title + ": " }));
            });
        }
        else if (tab.type == "obj" && tab.key != "bio") {
            Object.keys(this.output[tab.key].format).forEach((item) => {
                const formatItem = this.output[tab.key].format[item];
                this.preview.push(previewTextField(item, {
                    prefix: formatItem.join(", ")
                }));
            });
        }
        else if (tab.type == "list") {
            this.preview.push(
            // create preview section with all fields, put time fields first
            previewSection(tab.key, tab.title, [
                ...Object.keys(this.output[tab.key].format).filter((k) => ["t", "time"].includes(k)),
                ...Object.keys(this.output[tab.key].format).filter((k) => !["t", "time"].includes(k))
            ]));
        }
    }
    applyOutputOverrides(overrides) {
        const preferLeft = (left, right) => {
            for (const leftKey of Object.keys(left)) {
                if (right[leftKey] == undefined) {
                    right[leftKey] = left[leftKey];
                }
                else {
                    if (typeof right[leftKey] === "object" &&
                        typeof left[leftKey] === "object") {
                        right[leftKey] = preferLeft(left[leftKey], right[leftKey]);
                    }
                }
            }
            return right;
        };
        preferLeft(overrides, this.output);
    }
    applyNonParsables(stored, formats) {
        if (stored.nonParsable == undefined || stored.nonParsable.length === 0) {
            return;
        }
        for (const rule of stored.nonParsable) {
            if (rule._noParseType === "tab") {
                const index = this.input.findIndex((i) => {
                    return (i.field === rule._attachToPartialTab.key &&
                        (i.section ?? DEFAULT_TAB_SECTION) ===
                            rule._attachToPartialTab.section);
                });
                if (index != -1) {
                    this.input[index] = Object.fromEntries(Object.entries(rule).filter(([k, _]) => !k.startsWith("_")));
                }
            }
            else if (rule._noParseType === "tabReference") {
                const format = formats[rule._referenceFormat];
                if (format != undefined) {
                    const thisIndex = this.input.findIndex((i) => {
                        return (i.field === rule._attachToPartialTab.key &&
                            (i.section ?? DEFAULT_TAB_SECTION) ===
                                rule._attachToPartialTab.section);
                    });
                    const refIndex = format.input.findIndex((i) => {
                        return (i.section === rule._referenceTabSection &&
                            i.field === rule._referenceTabKey);
                    });
                    if (thisIndex != -1 && refIndex != -1) {
                        this.input[thisIndex] = format.input[refIndex];
                    }
                }
            }
        }
    }
    filter(cvOwner, templateList) {
        return anyPathOverlap(templateList[this.code]?.paths || [], cvOwner.requestedPaths);
    }
}
