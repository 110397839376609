import { default as getHomeSetup } from "../customSections/homeSetup.js";
import { previewTextField } from "../lib/formatUtilities.js";
import { anyPathOverlap } from "../lib/hierarchyUtilities.js";

const settingsInputSection = {
  field: "overview",
  icon: "home",
  type: "custom",
  title: "Settings",
  setup: getHomeSetup({ citationNames: true })
};

export class FormatWrapper {
  constructor(format) {
    this.Title = format.title;
    this.code = format.code;
    this.defaultInput = format.defaultInput;
    this.input = format.input;
    this.input.splice(0, 0, settingsInputSection);
    this.output = format.output;
    this.preview = format.preview.map((preview) => {
      if (preview.macro == "previewTextField")
        return previewTextField(preview.name, { prefix: preview.prefix });
    });
  }
  filter(cvOwner, templateList) {
    return anyPathOverlap(
      templateList[this.code]?.paths || [],
      cvOwner.requestedPaths
    );
  }
}
