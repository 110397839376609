import { Schema } from "mongoose";
export const langLookup = {
    afr: "Afrikaans",
    alb: "Albanian",
    amh: "Amharic",
    ara: "Arabic",
    arm: "Armenian",
    aze: "Azerbaijani",
    ben: "Bengali",
    bos: "Bosnian",
    bul: "Bulgarian",
    cat: "Catalan",
    chi: "Chinese",
    cze: "Czech",
    dan: "Danish",
    dut: "Dutch",
    eng: "English",
    epo: "Esperanto",
    est: "Estonian",
    fin: "Finnish",
    fre: "French",
    geo: "Georgian",
    ger: "German",
    gla: "Scottish Gaelic",
    gre: "Greek, Modern",
    heb: "Hebrew",
    hin: "Hindi",
    hrv: "Croatian",
    hun: "Hungarian",
    ice: "Icelandic",
    ind: "Indonesian",
    ita: "Italian",
    jpn: "Japanese",
    kin: "Kinyarwanda",
    kor: "Korean",
    lat: "Latin",
    lav: "Latvian",
    lit: "Lithuanian",
    mac: "Macedonian",
    mal: "Malayalam",
    mao: "Maori",
    may: "Malay",
    mul: "Multiple languages",
    nor: "Norwegian",
    per: "Persian, Iranian",
    pol: "Polish",
    por: "Portuguese",
    pus: "Pushto",
    rum: "Romanian, Rumanian, Moldovan",
    rus: "Russian",
    san: "Sanskrit",
    slo: "Slovak",
    slv: "Slovenian",
    spa: "Spanish",
    srp: "Serbian",
    swe: "Swedish",
    tha: "Thai",
    tur: "Turkish",
    ukr: "Ukrainian",
    und: "Undetermined",
    urd: "Urdu",
    vie: "Vietnamese",
    wel: "Welsh"
};
/**
 * request data structure for server responses
 */
export class PubMedSearchRequestData {
    url;
    key;
    status;
    pmids;
    articles;
    constructor(key, status) {
        this.url = `/pubmed/search_response?key=${key}`;
        this.key = key;
        this.status = status;
        this.pmids = [];
        this.articles = [];
    }
}
export class PubMedTitleMatchRequestData extends PubMedSearchRequestData {
    matches;
    constructor(key, status) {
        super(key, status);
        this.matches = [];
    }
}
/**
 * Data collected from PubMed on scholarship
 */
export class PubMedData {
    citationCount;
    citationCountMissing;
    rcr;
    articleData;
}
export const articleSchema = new Schema({
    pmid: String,
    citationName: (Array),
    articleData: Object
}, {
    minimize: false,
    timestamps: true,
    strict: "throw"
});
export function hydrateArticleData(obj) {
    const new_a = new ArticleData();
    Object.assign(new_a, obj);
    for (const key of ["pubdateData", "articledateData", "medlinedateData"]) {
        if (obj[key] != null) {
            const new_date = new PubMedDate(obj[key].month, obj[key].date, obj[key].year);
            new_a[key] = new_date;
        }
    }
    return new_a;
}
/**
 * Specific article data sourced from PubMed
 */
export class ArticleData {
    uid;
    title;
    volume;
    issue;
    pages;
    source;
    journal;
    language;
    issn;
    pii;
    doi;
    pubdateData;
    articledateData;
    medlinedateData;
    authorList;
    ids;
    constructor(pmid = "") {
        this.uid = pmid;
        this.authorList = [];
        this.ids = {};
    }
    get pubdate() {
        if (this.pubdateData != null) {
            return this.pubdateData.reverseString;
        }
        else if (this.articledateData != null) {
            return this.articledateData.reverseString;
        }
        else if (this.medlinedateData != null) {
            return this.medlinedateData.reverseString;
        }
        else {
            return "";
        }
    }
    get sortpubdate() {
        if (this.pubdateData != null &&
            (this.pubdateData.month || this.articledateData == null)) {
            return this.pubdateData.string;
        }
        else if (this.articledateData != null) {
            return this.articledateData.string;
        }
        else if (this.medlinedateData != null) {
            return this.medlinedateData.string;
        }
        else {
            return "";
        }
    }
}
const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec"
];
const numToMonths = {
    1: "Jan",
    2: "Feb",
    3: "Mar",
    4: "Apr",
    5: "May",
    6: "Jun",
    7: "Jul",
    8: "Aug",
    9: "Sep",
    10: "Oct",
    11: "Nov",
    12: "Dec"
};
const isMonth = (x) => months.some((a) => a === x);
export class PubMedDate {
    constructor(month, date, year) {
        if (numToMonths[parseInt(month)] != null) {
            this.monthNum = month;
            this.month = numToMonths[parseInt(month)];
        }
        else if (month != null) {
            const firstMonth = month.split("-")[0].trim();
            if (isMonth(firstMonth)) {
                this.month = firstMonth;
            }
            const monthIndex = Object.values(numToMonths).indexOf(month.trim());
            if (monthIndex >= 0) {
                this.monthNum = Object.keys(numToMonths)[monthIndex].toString();
            }
        }
        this.date = date || "";
        this.year = year || "";
        const monthString = this.monthNum || this.month || "";
        this.string =
            (monthString == ""
                ? ""
                : monthString +
                    (this.date.trim().length == 0 ? "" : "/" + parseInt(this.date)) +
                    "/") + this.year;
    }
    get reverseString() {
        return (this.year +
            " " +
            (this.month || "") +
            (this.date != "" ? " " + parseInt(this.date) : "")); // For citation
    }
    month;
    year; // yyyy
    date; // dd
    monthNum;
    string; // monthNum/date/year
    type;
}
