export const allowedHTMLTagExpressions = [
    "<br>",
    "<br/>",
    "<br />",
    "<strong>",
    "</strong>",
    "<b>",
    "</b>",
    "<em>",
    "</em>",
    "<i>",
    "</i>",
    "<u>",
    "</u>",
    "<sub>",
    "</sub>",
    "<sup>",
    "</sup>",
    '<a href="[^>]*>',
    "<\\/a>"
];
