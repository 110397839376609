import {
  ArticleData,
  PubMedData,
  PubMedDate,
  PubMedSearchRequestData,
  PubMedTitleMatchRequestData,
  langLookup
} from "@easycv/common/types/pubmed.ts";
import { XMLParser } from "fast-xml-parser";
import _ from "lodash";
import * as xmldom from "xmldom";

import { numToMonths } from "./dateHelpers.js";
import { logDebug, logError, logInfo } from "./logUtilities.js";
import { after, for2, getField, getKey, keys } from "./utilities.js";

var entrezSummary = "esummary.fcgi?db=pubmed&retmode=json";
var entrezFetch = "efetch.fcgi?db=pubmed&retmode=xml";
const entrezAuthorBase =
  "esearch.fcgi?db=pubmed&retmode=json&usehistory=y&retmax=10000";
const entrezAuthorTerm = "&term=";

var entrezAuthor = entrezAuthorBase + entrezAuthorTerm;

var pmDomain = "https://eutils.ncbi.nlm.nih.gov/entrez/eutils";

export { entrezAuthor, entrezFetch, entrezSummary, pmDomain };

function getAttributeValue(obj, key, attribute) {
  if (obj[key] && obj[key][attribute]) {
    return obj[key][attribute];
  } else if (typeof obj == "object") {
    for (let k of Object.keys(obj)) {
      let tmp = getAttributeValue(obj[k], key, attribute);
      if (tmp) {
        return tmp;
      }
    }
  }
  return null;
}

function getPropertyValue(obj, key) {
  // logInfo("getProperty", key);
  if (!obj) return null;
  if (obj[key] && obj[key]["#text"]) {
    return obj[key]["#text"];
  } else if (obj[key]) {
    return obj[key];
  } else if (typeof obj == "object") {
    for (let k of Object.keys(obj)) {
      let tmp = getPropertyValue(obj[k], key);
      if (tmp) {
        return tmp;
      }
    }
  }
  return null;
}
export function extractArticleData(article) {
  /**
   * @type ArticleData
   */
  let result = new ArticleData();

  result.uid = (getPropertyValue(article, "PMID") || "").toString();
  result.title = getPropertyValue(article, "ArticleTitle");
  result.volume = (getPropertyValue(article, "Volume") || "").toString();
  result.issue = (getPropertyValue(article, "Issue") || "").toString();
  result.pages = (getPropertyValue(article, "MedlinePgn") || "").toString();
  result.journal = getPropertyValue(article, "Journal");

  result.source = getPropertyValue(result.journal, "ISOAbbreviation") || "";
  result.issn = getPropertyValue(article, "ISSN") || "";
  result.language = langLookup[getPropertyValue(article, "Language")] || "";
  let ELocationID = getPropertyValue(article, "ELocationID");

  if (_.isArray(ELocationID)) {
    for (let eloc of ELocationID) {
      if (eloc["@_EIdType"] == "pii") {
        result.pii = (eloc["#text"] || "").toString();
      }
      if (eloc["@_EIdType"] == "doi") {
        result.doi = (eloc["#text"] || "").toString();
      }
    }
  } else if (ELocationID) {
    if (getAttributeValue(article, "ELocationID", "@_EIdType") == "pii") {
      result.pii = ELocationID.toString();
    } else if (
      getAttributeValue(article, "ELocationID", "@_EIdType") == "doi"
    ) {
      result.doi = ELocationID.toString();
    }
  }
  // pubdateData from PubDate
  let pubdate = getPropertyValue(article, "PubDate");
  if (pubdate) {
    result.pubdateData = new PubMedDate(
      pubdate?.Month?.toString(),
      pubdate?.Day?.toString(),
      pubdate?.Year?.toString()
    );
  }

  // articledateData from ArticleDate
  let articledate = getPropertyValue(article, "ArticleDate");
  if (articledate) {
    result.articledateData = new PubMedDate(
      articledate?.Month?.toString(),
      articledate?.Day?.toString(),
      articledate?.Year?.toString()
    );

    result.articledateData.type = getAttributeValue(
      article,
      "ArticleDate",
      "@_DateType"
    );
  }

  // medlinedateData from MedlineDate
  let medlinedate = getPropertyValue(article, "MedLineDate");
  if (medlinedate) {
    let [y, m, d] = medlinedate.split(" ");
    result.medlinedateData = new PubMedDate(m, d, y);
  }

  // AuthorList
  let authorList = getPropertyValue(article, "AuthorList")["Author"];
  if (authorList && Object.values(authorList).length > 0) {
    for (let index = 0; index < Object.values(authorList).length; index++) {
      let author = Object.values(authorList)[index];
      /**
       * @type import("@easycv/common/types/pubmed.ts").AuthorData
       */
      var authorData = {
        LastName: "",
        ForeName: "",
        Initials: ""
      };
      // For each attribute like LastName, ForeName, Initials
      authorData.LastName = author["LastName"] || "";
      authorData.ForeName = author["ForeName"] || "";
      authorData.Initials = author["Initials"] || "";
      authorData.Suffix = author["Suffix"];
      if (author.AffiliationInfo?.Affiliation) {
        authorData.AffiliationInfo = author.AffiliationInfo.Affiliation;
      }
      if (getPropertyValue(author, "Identifier")) {
        authorData.Identifier = getPropertyValue(
          author,
          "Identifier"
        ).toString();
      }
      result.authorList.push(authorData);
    }
  }

  // ArticleIDList
  let idList = getPropertyValue(article, "ArticleIdList").ArticleId;
  if (_.isArray(idList)) {
    for (let id of idList) {
      result.ids[id["@_IdType"]] = (id["#text"] || "").toString();
    }
  } else if (idList["@_IdType"]) {
    result.ids[idList["@_IdType"]] = (idList["#text"] || "").toString();
  }

  return result;
}

var getFetchData = function (data) {
  let fetchData = {
    authorList: []
  };

  // Can everything be extracted?
  // authorList, title (ArticleTitle), volume (Volume), issue (Issue), pages (MedlinePgn), source (Journal > ISOAbbreviation), pubdate (PubDate), uid (PMID)

  fetchData.title = getTagText(data, "ArticleTitle");
  fetchData.volume = getTagText(data, "Volume");
  fetchData.issue = getTagText(data, "Issue");
  fetchData.pages = getTagText(data, "MedlinePgn");
  fetchData.source =
    getTag(data, "Journal") == null
      ? ""
      : getTagText(getTag(data, "Journal"), "ISOAbbreviation");
  // fetchData.doi = getTagText(data, 'ELocationID');
  fetchData.issn = getTagText(data, "ISSN");
  fetchData.language = langLookup[getTagText(data, "Language")];

  Array.from(getTag(data, "Article").childNodes).map(function (idItem) {
    if (idItem.getAttribute("EIdType") == "pii")
      fetchData.pii = idItem.textContent;
    if (idItem.getAttribute("EIdType") == "doi")
      fetchData.doi = idItem.textContent;
  });

  let uid = getTagText(data, "PMID");
  fetchData.uid = uid;

  const extractDate = function (data, tag) {
    let dateEl = getTag(data, tag);
    /**
     * @type PubMedDate?
     */
    let dateData = null;
    if (dateEl != null && getTagText(dateEl, "Year") != "") {
      dateData = {
        year: getTagText(dateEl, "Year"),
        month: getTagText(dateEl, "Month"),
        date: getTagText(dateEl, "Day")
      };
      // if (dateData.day == '') dateData.day = '1';
      if (numToMonths[parseInt(dateData.month)] != null) {
        dateData.monthNum = dateData.month;
        dateData.month = numToMonths[parseInt(dateData.month)];
      } else {
        for (var num in numToMonths)
          if (
            numToMonths[num].trim().toLowerCase() ==
            dateData.month.trim().toLowerCase()
          )
            dateData.monthNum = num;
      }

      let monthString = (dateData.monthNum || dateData.month || "")
        .split("-")[0]
        .trim();
      dateData.string =
        (monthString == ""
          ? ""
          : monthString +
            (dateData.date.trim().length == 0
              ? ""
              : "/" + parseInt(dateData.date)) +
            "/") + dateData.year;
      dateData.reverseString =
        dateData.year +
        " " +
        dateData.month +
        (dateData.date != "" ? " " + parseInt(dateData.date) : ""); // For citation
      if (dateData.string.trim().length == 0) {
        debugger;
        dateData = null;
      }
    }
    return dateData;
  };

  fetchData.pubdateData = extractDate(data, "PubDate");
  fetchData.articledateData = extractDate(data, "ArticleDate");
  if (fetchData.articledateData != null) {
    fetchData.articledateData.type = getTag(data, "ArticleDate").getAttribute(
      "DateType"
    );
  }

  let medlineDateEl = getTag(data, "MedlineDate");
  if (medlineDateEl != null) {
    let dateData = {
      year: medlineDateEl.textContent.split(" ")[0] || "",
      month: medlineDateEl.textContent.split(" ")[1] || "",
      date: medlineDateEl.textContent.split(" ")[2] || ""
    };
    if (numToMonths[parseInt(dateData.month)] != null) {
      dateData.monthNum = dateData.month;
      dateData.month = numToMonths[parseInt(dateData.month)];
    } else {
      for (var num in numToMonths)
        if (
          numToMonths[num].trim().toLowerCase() ==
          dateData.month.split("-")[0].trim().toLowerCase()
        )
          dateData.monthNum = num;
    }

    var monthString = (dateData.monthNum || dateData.month || "")
      .split("-")[0]
      .trim();
    dateData.string =
      (monthString == ""
        ? ""
        : monthString +
          (dateData.date.trim().length == 0
            ? ""
            : "/" + parseInt(dateData.date)) +
          "/") + dateData.year;
    dateData.reverseString = dateData.year + " " + dateData.month; // For citation
    fetchData.medlinedateData = dateData;
  }

  // Priority: ArticleDate, PubDate, MedlineDate

  // Visual for citation
  if (fetchData.pubdateData != null) {
    fetchData.pubdate = fetchData.pubdateData.reverseString;
  } else if (fetchData.articledateData != null)
    fetchData.pubdate = fetchData.articledateData.reverseString;
  else if (fetchData.medlinedateData != null)
    fetchData.pubdate = fetchData.medlinedateData.reverseString;

  // Needs to be parsable by new Date()
  if (
    fetchData.pubdateData != null &&
    (fetchData.pubdateData.month != "" || fetchData.articledateData == null)
  ) {
    fetchData.sortpubdate = fetchData.pubdateData.string;
  } else if (fetchData.articledateData != null)
    fetchData.sortpubdate = fetchData.articledateData.string;
  else if (fetchData.medlinedateData != null)
    fetchData.sortpubdate = fetchData.medlinedateData.string;

  if (fetchData.pubdate == null) {
    debugger;
    fetchData.pubdate = "";
  }
  if (fetchData.sortpubdate == null) {
    debugger;
    fetchData.sortpubdate = "";
  }

  fetchData.ids = {};
  let idList = getTag(data, "ArticleIdList");

  // Was
  // Array.from(idList.childNodes).map(function (idItem) {
  for (let index = 0; index < idList.childNodes.length; index++) {
    let idItem = idList.childNodes[index];
    fetchData.ids[idItem.getAttribute("IdType")] = idItem.textContent;
  }

  let authorList = getTag(data, "AuthorList");
  // Was
  // authorList.childNodes.forEach(function (author) {
  if (authorList && authorList.hasChildNodes()) {
    for (let index = 0; index < authorList.childNodes.length; index++) {
      let author = authorList.childNodes[index];
      // logInfo("getFetchData", author.toString());
      var authorData = {};
      // Was
      // Array.from(author.childNodes).map(function (att) {
      for (
        let authorIndex = 0;
        authorIndex < author.childNodes.length;
        authorIndex++
      ) {
        let att = author.childNodes[authorIndex];
        if (att.childNodes.length == 1)
          authorData[att.localName] = att.textContent;
        else {
          // Array.from(att.childNodes).map(function (attChild) {
          for (let attIndex = 0; attIndex < att.childNodes.length; attIndex++) {
            let attChild = att.childNodes[attIndex];
            authorData[attChild.localName] = attChild.textContent;
          }
        }
      }
      fetchData.authorList.push(authorData);
    }
    authorList = null;
    idList = null;
  }
  // logDebug("fetchData", `Returning ${JSON.stringify(fetchData)}`);
  return JSON.parse(JSON.stringify(fetchData));
};
export { getFetchData };

/**
 *
 * @param { ArticleData } article
 * @param { { first?: boolean, def: {firstNames?: string, citNames?: string, orcid?:string }}} opts
 */
export function isAuthorInAuthorList(article, opts = { def: {} }) {
  let matchFirst = opts.first;

  let firstNames = (opts.def["firstNames"] || "")
    .toLowerCase()
    .split(";")
    .filter((n) => n != "");
  let citNames = (opts.def["citationName"] || "")
    .toLowerCase()
    .split(";")
    .filter((n) => n != "");
  let orcid = (opts.def["orcid"] || "").split("/").slice(-1)[0].trim();

  /**
   * @type import("@easycv/common/types/pubmed.ts").AuthorData[]
   */
  let authorList = article.authorList || [];

  // Only add if an author that matches a citation name and also matches a first name
  let fullAuthorMatches = authorList.filter(function (author) {
    var citName = (
      author.LastName +
      " " +
      author.Initials +
      (author.Suffix != null ? " " + author.Suffix : "")
    )
      .toLowerCase()
      .split(/,|\./)
      .join("")
      .trim();
    var firstName = (author.ForeName || "")
      .toLowerCase()
      .split(/,|\./)
      .join("")
      .trim();
    return (
      citNames.some(function (n) {
        return citName.indexOf(n.split(/,|\./).join("")) >= 0;
      }) &&
      firstNames.some(function (n) {
        return firstName.indexOf(n.split(/,|\./).join("")) >= 0;
      }) &&
      ((author.Identifier || "") == "" ||
        orcid.length == 0 ||
        author.Identifier.indexOf(orcid) >= 0)
    );
  });

  let citationAuthorMatches = authorList.filter(function (author) {
    var citName = (
      author.LastName +
      " " +
      author.Initials +
      (author.Suffix != null ? " " + author.Suffix : "")
    )
      .toLowerCase()
      .split(/,|\./)
      .join("");
    return (
      citNames.some(function (n) {
        return citName.indexOf(n.split(/,|\./).join("")) >= 0;
      }) &&
      (author.Identifier == null ||
        orcid.length == 0 ||
        author.Identifier.indexOf(orcid) >= 0)
    );
  });

  return (
    authorList.length == 0 ||
    (matchFirst
      ? fullAuthorMatches.length > 0
      : citationAuthorMatches.length > 0)
  );
}

/**
 * Intending to replace with isAuthorInAuthorList
 * @param {*} data
 * @param {*} opts
 * @returns
 */
var pmDataMatch = function (data, opts) {
  opts = opts || {};
  var matchFirst = !!opts.first;

  var firstNames = (opts.def["firstNames"] || "")
    .toLowerCase()
    .split(";")
    .filter((n) => n != "");
  var citNames = (opts.def["citationName"] || "")
    .toLowerCase()
    .split(";")
    .filter((n) => n != "");
  var orcid = (opts.def["orcid"] || "").split("/").slice(-1)[0].trim();
  // if (orcid != null && orcid.indexOf('http://') == -1) orcid = 'http://orcid.org/' + orcid;

  /**
   * @type import("@easycv/common/types/pubmed.ts").AuthorData[]
   */
  var authorList = (data.fetchData || { authorList: [] }).authorList;

  // Only add if an author that matches a citation name and also matches a first name
  var fullAuthorMatches = authorList.filter(function (author) {
    var citName = (
      author.LastName +
      " " +
      author.Initials +
      (author.Suffix != null ? " " + author.Suffix : "")
    )
      .toLowerCase()
      .split(/,|\./)
      .join("");
    var firstName = (author.ForeName || "")
      .toLowerCase()
      .split(/,|\./)
      .join("");
    return (
      citNames.some(function (n) {
        return citName.indexOf(n.split(/,|\./).join("")) >= 0;
      }) &&
      firstNames.some(function (n) {
        return firstName.indexOf(n.split(/,|\./).join("")) >= 0;
      }) &&
      (author.Identifier == null ||
        orcid.length == 0 ||
        author.Identifier.indexOf(orcid) >= 0)
    );
  });

  var citationAuthorMatches = authorList.filter(function (author) {
    var citName = (
      author.LastName +
      " " +
      author.Initials +
      (author.Suffix != null ? " " + author.Suffix : "")
    )
      .toLowerCase()
      .split(/,|\./)
      .join("");
    return (
      citNames.some(function (n) {
        return citName.indexOf(n.split(/,|\./).join("")) >= 0;
      }) &&
      (author.Identifier == null ||
        orcid.length == 0 ||
        author.Identifier.indexOf(orcid) >= 0)
    );
  });

  return (
    authorList.length == 0 ||
    (matchFirst ? fullAuthorMatches.length : citationAuthorMatches.length > 0)
  );
};
export { pmDataMatch };

/**
 * Intending to replace or consolidate with getPMdata2
 * @param { string[] } ids
 * @param { (pmData: object) => void } callback
 * @param { { checkCitationCounts?: boolean,
 *            wait?: function,
 *            noCitationCount?: boolean,
 *            citationCountProgress?: (progressIndex: number) => void,
 *            pmidList: object } } opts
 */
var getPMdata = function (ids, callback, opts) {
  opts = opts || { pmidList: {} };
  var pmidList = opts.pmidList;

  var results = {};
  var newIds = ids.filter(function (id) {
    if (/[a-zA-Z]/.test(id)) return false; // Not a real PMID
    var satisfied =
      pmidList[id] != null &&
      (!!opts.noCitationCount ||
        pmidList[id].citationCount != null ||
        !!pmidList[id].citationCountMissing) &&
      pmidList[id].fetchData != null &&
      false;
    if (satisfied) results[id] = pmidList[id];
    return !satisfied;
  });

  var blockSize = 300;
  var blocks = [];
  var i = 0;
  while (i < newIds.length) {
    var newBlock = newIds.slice(i, i + blockSize);
    blocks.push(newBlock);
    i += newBlock.length;
  }

  for2(
    { list: blocks, wait: opts.wait },
    function (block, done, block_i, wait) {
      // First update pmResults if it's missing
      // Then update citationCount if applicable
      // Finally update pmFetchData if it's missing

      after(
        function (done) {
          if (opts.noCitationCount) return done();
          var citationCountsMissing = block.filter(function (id) {
            if (opts.checkCitationCounts) return true;
            if (
              getKey(pmidList, id, {}).citationCount != null ||
              !!pmidList[id].citationCountMissing
            ) {
              getKey(results, id, {}).citationCount =
                pmidList[id].citationCount;
              getKey(results, id, {}).citationCountMissing =
                pmidList[id].citationCountMissing;
              return false;
            }
            return true;
          });
          if (citationCountsMissing.length == 0) return done();

          wait();
          after(
            function (done) {
              if (typeof $ == "undefined") {
                opts.request.get(
                  "https://icite.od.nih.gov/api/pubs?pmids=" +
                    citationCountsMissing.join(","),
                  function (error, response, body) {
                    if (error != null || response.statusCode == "503") {
                      console.log("Error: ", response.statusCode, error);
                      return done();
                    }
                    done(JSON.parse(body));
                  }
                );
              } else {
                $.ajax({
                  type: "GET",
                  url:
                    "https://icite.od.nih.gov/api/pubs?pmids=" +
                    citationCountsMissing.join(","),
                  dataType: "json",
                  success: done,
                  error: function () {
                    done();
                  }
                });
              }
            },
            function (setData) {
              if (setData == null) return done();
              (setData.data || []).map(function (item) {
                /**
                 * @type { { citationCount?: string, rcr?: string, citationCountMissing?: boolean }}
                 */
                var result = getKey(results, item.pmid, {});
                result.citationCount = item.citation_count;
                result.rcr = item.relative_citation_ratio;
                delete result.citationCountMissing;

                /**
                 * @type { { citationCount?: string, rcr?: string, citationCountMissing?: boolean }}
                 */
                var pmidItem = getKey(pmidList, item.pmid, {});
                pmidItem.citationCount = item.citation_count;
                pmidItem.rcr = item.relative_citation_ratio;
                delete pmidItem.citationCountMissing;

                delete pmidList[item.pmid].pmResults;
              });

              if (
                !!opts.checkCitationCounts &&
                opts.citationCountProgress != null
              ) {
                opts.citationCountProgress(block_i * blockSize + block.length);
              }

              done();
            }
          );
        },
        function () {
          var fetchMissing = block.filter(function (id) {
            // return true; // Checking all now
            if ((id || "").indexOf("/") >= 0) return false; // Not allowed
            if (
              getKey(pmidList, id, {}).fetchData != null &&
              getKey(pmidList, id, {}).fetchData.sortpubdate != null
            ) {
              getKey(results, id, {}).fetchData = pmidList[id].fetchData;
              return false;
            }
            return true; // Checking all now
          });
          if (fetchMissing.length === 0) return done();
          wait();
          const DOMParser = new xmldom.DOMParser();
          setTimeout(function () {
            after(
              function (done) {
                if (typeof $ == "undefined") {
                  opts.request.get(
                    pmDomain +
                      "/" +
                      entrezFetch +
                      "&id=" +
                      fetchMissing.join(","),
                    function (error, response, body) {
                      if (error != null) {
                        console.log(error);
                        return done();
                      }
                      done(DOMParser.parseFromString(body));
                    }
                  );
                } else {
                  $.ajax({
                    type: "GET",
                    url:
                      "/PMproxy?path=" +
                      encodeURIComponent(
                        entrezFetch + "&id=" + fetchMissing.join(",") + ""
                      ),
                    dataType: "xml",
                    success: done,
                    error: done
                  });
                }
              },
              function (setData, label) {
                if (
                  setData == null ||
                  label == "error" ||
                  label == "parseerror"
                )
                  return done();
                var set = getTag(setData, "PubmedArticleSet");
                if (set == null) {
                  return done();
                }
                for2(
                  {
                    list: Array.from(set.getElementsByTagName("PubmedArticle")),
                    wait: wait
                  },
                  function (data, done, i, wait) {
                    var fetchData = getFetchData(data);
                    getKey(results, fetchData.uid, {}).fetchData = fetchData;
                    delete results[fetchData.uid].pmResults;
                    getKey(pmidList, fetchData.uid, {}).fetchData = fetchData;
                    delete pmidList[fetchData.uid].pmResults;
                    // console.log('got', fetchData.uid, pmidList[fetchData.uid]);
                    done();
                  },
                  function () {
                    done();
                  }
                );
              }
            );
          }, 800);
        }
      );
    },
    function () {
      console.log("done searching for pmData");
      callback(results);
    }
  );
};
export { getPMdata };

var getTag = function (data, tag) {
  return data == null || data.getElementsByTagName == null
    ? null
    : data.getElementsByTagName(tag)[0];
};
export { getTag };

var getTagText = function (data, tag) {
  return getTag(data, tag) == null ? "" : getTag(data, tag).textContent;
};
export { getTagText };

var getCitName = function (author) {
  if (author.CollectiveName != null) return author.CollectiveName;
  return (
    author.LastName +
    " " +
    author.Initials +
    (author.Suffix != null ? " " + author.Suffix : "")
  );
};
export { getCitName };

var expandPages = function (text) {
  if (typeof text == "number") text = text.toString();

  if (text.indexOf("-") == -1) return text;
  var pages = text.split("-");
  var page1 = pages[0];
  var page2 = pages[1];
  return page1 + "-" + page1.slice(0, page1.length - page2.length) + page2;
};

const removeLastPeriod = (line) =>
  line.endsWith(".") ? line.slice(0, -1) : line;

export const makeCitation = (data, cvOwner, hyperlinkPMIDs = false) => {
  if (!data.title) {
    return "";
  }
  // authorList, title, volume, issue, pages, source, pubdate, uid
  // Forooghian F, Yeh S, Faia LJ, Nussenblatt RB. Uveitic foveal atrophy: clinical features and associations. Arch Ophthalmol. 2009 Feb;127(2):179-86. PubMed PMID: 19204236.
  const title = removeLastPeriod(data.title.split("&amp;amp;").join("&"));

  var authorList = (data.authorList || []).map(getCitName);

  var pages = data.pages;
  if (data.pii != null && (pages == null || pages == "")) pages = data.pii;

  var volAndPages =
    (data.volume != ""
      ? data.volume +
        (data.issue != "" ? "(" + data.issue + ")" : "") +
        (pages != null && pages != "" ? ":" : "")
      : "") + expandPages(pages);

  const pubdate = data.pubdate.trim();

  const cvOwnerSettings = _.get(cvOwner, "settings", {});
  const citationPreferences = cvOwner
    ? _.get(cvOwnerSettings, "citationPrefs", {
        pmcid: true,
        doi: true
      })
    : {};

  data.ids = data.ids || {};

  const extraIds = [];

  // Pubmed ID
  if (data.uid) {
    if (hyperlinkPMIDs) {
      extraIds.push(
        `<a href="https://pubmed.ncbi.nlm.nih.gov/${data.uid}"> PMID: ${data.uid}</a>`
      );
    } else {
      extraIds.push(`PMID: ${data.uid}`);
    }
  }

  // Pubmed Central ID
  if (data.ids["pmc"] != null && !!citationPreferences.pmcid) {
    extraIds.push(`PMCID: ${data.ids["pmc"]}`);
  }

  // DOI
  if (data.doi != null && data.doi !== "" && !!citationPreferences.doi) {
    extraIds.push(`https://doi.org/${data.doi}`);
  }

  //Epub
  if (
    data.articledateData != null &&
    data.articledateData.type === "Electronic"
  ) {
    extraIds.push(
      "Epub " +
        [
          data.articledateData.year,
          data.articledateData.month,
          data.articledateData.date
        ]
          .filter(function (n) {
            return n != null;
          })
          .join(" ")
    );
  }

  const authors =
    authorList.length === 0
      ? ""
      : authorList.map((author) => removeLastPeriod(author)).join(", ");
  const volumeAndPages = volAndPages !== "" ? ";" + volAndPages : "";

  return `${authors}. ${title}. ${data.source}. ${pubdate}${volumeAndPages}. ${extraIds.join(". ")} `.trim();
};

var findCVNames = function (def, pmidList) {
  var currentFirstNames = getKey(def, "firstNames", "")
    .split(";")
    .filter(function (n) {
      return n != "";
    });

  var currentNames = {};
  var highlightNames = getKey(def, "citationName", "")
    .split(";")
    .filter(function (n) {
      return n != "";
    });
  highlightNames.map(function (n) {
    currentNames[n.toLowerCase().trim()] = true;
  });

  // To find potentially missing first names, go through all PMIDs with last name matches but no first name matches.
  var scholarshipWithoutLastNameMatches = []; // Find PMIDs which don't match current citation names and looking through all authors for last name inclusion.

  var firstNames = {};
  getField(def, "scholarship")
    .filter(function (n) {
      return (
        n.PMID != null &&
        pmidList[n.PMID] != null &&
        pmidList[n.PMID].fetchData != null
      ); // pmidList[n.PMID].pmResults != null;
    })
    .map(function (n) {
      var lastNameMatches = (
        pmidList[n.PMID].fetchData.authorList || []
      ).filter(function (author) {
        return currentNames[getCitName(author).toLowerCase().trim()] != null;
      });

      if (lastNameMatches.length == 0)
        scholarshipWithoutLastNameMatches.push(n);
      else {
        // Only add first names to consider adding if none already match.
        if (
          lastNameMatches.every(function (author) {
            return !currentFirstNames.some(function (currentFirstName) {
              return (
                (author.ForeName || "")
                  .toLowerCase()
                  .indexOf(currentFirstName.toLowerCase()) >= 0
              );
            });
          })
        ) {
          lastNameMatches.map(function (author) {
            getKey(firstNames, author.ForeName || "", []).push(n.PMID);
          });
        }
      }

      // if ((pmidList[n.PMID].pmResults.authors || []).every(function(author) {
      //   return currentNames[author.name.toLowerCase().trim()] == null;
      // })) {
    });

  var authorNames = {};
  scholarshipWithoutLastNameMatches.map(function (n) {
    (pmidList[n.PMID].fetchData.authorList || []).map(function (author) {
      getKey(authorNames, getCitName(author).trim(), []).push(n.PMID);
    });
  });

  var realNames = (def.bio.name || "")
    .toLowerCase()
    .split(",")[0]
    .split(/ |\./)
    .filter(function (name) {
      return name.length > 1;
    });

  var possibleNames = [];

  if (realNames.length > 0) {
    // Find author names which include the last name
    var lastName = realNames.slice(-1)[0].toLowerCase();
    for (var name in authorNames) {
      if (
        name
          .toLowerCase()
          .split(/ |\./)
          .filter(function (n) {
            return n.trim().length > 0;
          })
          .indexOf(lastName) >= 0 &&
        currentNames[name.toLowerCase()] == null
      )
        possibleNames.push(name);
    }
  }

  possibleNames = _.sortBy(possibleNames, function (name) {
    return -authorNames[name].length;
  });

  var possibleFirstNames = keys(firstNames).filter(function (name) {
    return !currentFirstNames.some(function (currentFirstName) {
      return name.toLowerCase().indexOf(currentFirstName.toLowerCase()) >= 0;
    });
  });

  return {
    realName: def.bio.name,
    currentNames: highlightNames,
    possibleNames: possibleNames,
    possibleFirstNames: possibleFirstNames,
    firstNames: firstNames
  };
};
export { findCVNames };

/**
 *
 * @param {string[]} ids
 * @param {boolean} includeCitations
 * @param {number} retries
 * @returns
 */
export const getPMDataV2 = async (
  ids,
  includeCitations = true,
  retries = 5
) => {
  /**
   * @type import("@easycv/common/types/pubmed.ts").PubMedMap
   */
  const pubMedMap = {};

  /**
   * @type import("@easycv/common/types/pubmed.ts").PubMedMap
   */
  const results = {};
  const newIds = ids.filter((id) => {
    if (/[a-zA-Z]/.test(id)) {
      // Not a real PMID <- Confusing, are we storing bad data somewhere?
      return false;
    }
    return true;
  });

  const blockSize = 25;
  /**
   * @type string[][]
   */
  const blocks = [];
  for (let i = 0; i < newIds.length; i += blockSize) {
    blocks.push(newIds.slice(i, i + blockSize));
  }

  let whichBlock = 0;
  for (let block of blocks) {
    logInfo(
      "getPMDataV2",
      `Processing ${whichBlock * blockSize} to ${(whichBlock++ + 1) * blockSize}`
    );
    reportMemoryUsage();
    if (includeCitations) {
      // Handle citation counts
      for (let id of block) {
        if (
          pubMedMap[id]?.citationCount != null ||
          pubMedMap[id]?.citationCountMissing
        ) {
          results[id].citationCount = pubMedMap[id].citationCount;
          results[id].citationCountMissing = pubMedMap[id].citationCountMissing;
        }
      }
      const citationCountsMissing = block.filter(
        (id) =>
          pubMedMap[id]?.citationCount == null ||
          pubMedMap[id]?.citationCountMissing
      );

      if (citationCountsMissing.length > 0) {
        const url =
          `https://icite.od.nih.gov/api/pubs?pmids=${citationCountsMissing.join(",")}` +
          (process.env.PM_KEY ? "&api_key=" + process.env.PM_KEY : "");
        const response = await fetchWithRetry(url, retries);
        const setData = await response.json();

        if (setData) {
          setData.data.forEach((item) => {
            /**
             * @type import("@easycv/common/types/pubmed.ts").PubMedData
             */
            const result = results[item.pmid] || {};
            result.citationCount = item.citation_count;
            result.rcr = item.relative_citation_ratio;
            delete result.citationCountMissing;
            results[item.pmid] = result;
            pubMedMap[item.pmid] = { ...result };
          });
        }
      }
    }

    // Handle fetch data
    const fetchMissing = block.filter((id) => {
      if ((id || "").indexOf("/") >= 0) return false; // Not allowed
      if (
        pubMedMap[id]?.articleData != null &&
        pubMedMap[id]?.articleData.sortpubdate != null
      ) {
        if (!results[id]) {
          results[id] = new PubMedData();
        }
        results[id].articleData = pubMedMap[id].articleData;
        return false;
      }
      return true;
    });
    function reportMemoryUsage() {
      logInfo(
        "reportMemoryUsage",
        process.memoryUsage().heapUsed / (1024 * 1024),
        "mb"
      );
    }

    if (fetchMissing.length > 0) {
      const url =
        `${pmDomain}/${entrezFetch}&id=${fetchMissing.join(",")}` +
        (process.env.PM_KEY ? "&api_key=" + process.env.PM_KEY : "");
      let response = await fetchWithRetry(url, retries);
      let body = await response.text();
      logInfo("getPMDataV2", `PubMed response is ${body.length / 1024} kb`);

      let parser = new XMLParser({
        ignoreAttributes: false,
        attributeNamePrefix: "@_"
      });
      let tmp = parser.parse(body);
      let articles;
      if (_.isArray(tmp.PubmedArticleSet.PubmedArticle)) {
        articles = tmp.PubmedArticleSet.PubmedArticle;
      } else {
        articles = [tmp.PubmedArticleSet.PubmedArticle];
      }

      if (_.isArray(articles)) {
        for (let art of articles) {
          let extractedData = extractArticleData(art);
          if (extractedData) {
            if (!results[extractedData.uid]) {
              results[extractedData.uid] = new PubMedData();
            }
            results[extractedData.uid].articleData = extractedData;
          }
        }
      }
    }
  }

  return results;
};

/**
 *
 * @param { string } url
 * @param { number } retries
 * @param { object } init
 * @returns
 */
export async function fetchWithRetry(url, retries, init) {
  logDebug("fetchWithRetry", "Fetching: " + url);
  let response = null;
  try {
    response = await fetch(url, init);
  } catch (error) {
    if (retries < 1) {
      logError("fetchWithRetry", "Retries exhausted", error);
    } else {
      logInfo("fetchWithRetry", "Caught error, retrying", error);
      response = await fetchWithRetry(url, retries - 1, init);
    }
  }

  if (Number(response.headers.get("x-ratelimit-remaining")) == 0) {
    logInfo(
      "fetchWithRetry",
      `No more requests left waiting 1s - received ${response.headers.get("x-ratelimit-remaining")}`
    );
    await new Promise((resolve) => setTimeout(resolve, 1000));
  } else {
    logDebug(
      "fetchWithRetry",
      `received x-ratelimit-remaining ${response?.headers?.get("x-ratelimit-remaining")}`
    );
  }

  return response;
}

export const getNameMatchesV2 = async (citNames, retries = 5) => {
  if (citNames.length === 0) return [];

  const citationNames = citNames
    .map((name) => encodeURIComponent(name.trim() + "[Author] "))
    .join("+OR+");

  if (citationNames.length === 0) return [];
  const entrezAuthorWithAPIKey =
    entrezAuthorBase +
    (process.env.PM_KEY ? "&api_key=" + process.env.PM_KEY : "") +
    entrezAuthorTerm;

  const url = entrezAuthorWithAPIKey + citationNames;
  const requestUrl =
    typeof $ === "undefined"
      ? `${pmDomain}/${url}`
      : `/PMproxy?path=${encodeURIComponent(url)}`;

  try {
    const response = await fetchWithRetry(requestUrl, retries, {
      method: "GET",
      headers: {
        "Content-Type": "application/json"
      }
    });

    if (!response.ok) {
      console.error(`HTTP error! status: ${response.status}`);
      return [];
    }

    const data = await response.json();
    logDebug(
      "getNameMachesV2",
      `Response is ${JSON.stringify(data).length / 1024} kb`
    );
    if (data?.esearchresult?.idlist) {
      logInfo(
        "getNameMachesV2",
        `Returning is ${data.esearchresult.idlist.length} PMIDs`
      );
      return data.esearchresult.idlist;
    } else {
      console.log("no esearchresult? Error: ", data, url, citNames);
      return [];
    }
  } catch (error) {
    console.error("Fetch error: ", error);
    return [];
  }
};

const errorReturn = new PubMedSearchRequestData("error", "error");
/**
 * function that calls to check on long-running request every second
 * Used by getArticleData and getNameMatches
 * @param {PubMedSearchRequestData} data
 * @param {(data:PubMedSearchRequestData) => void} callback
 */
function pollForPubmedSearchResults(data, callback, delay) {
  setTimeout(async () => {
    logDebug("pollForPubmedSearchResults", `Delay passed is ${delay}`);
    let response = await fetchWithRetry(data.url, 5, {
      method: "GET",
      headers: {
        "Content-Type": "application/json"
      }
    });
    if (response) {
      const response_data = await response.json();
      if (response_data.status == "complete") {
        logDebug("pollForPubmedSearchResults", "Complete");
        callback(response_data);
      } else if (response_data.status == "pending") {
        callback(response_data);
        pollForPubmedSearchResults(data, callback, 1);
      } else {
        logError("pollForPubmedSearchResults", response_data);
        callback(errorReturn);
      }
    }
  }, delay);
}
/**
 *
 * @param {string[]} pmids
 * @param {(result) => void} callback
 */
export async function getArticleData(pmids = [], callback) {
  if (pmids.length == 0)
    return callback(new PubMedSearchRequestData("complete", "complete"));
  if (typeof $ == "undefined") return callback(errorReturn);

  const url = `/pubmed/fetch?pmids=${pmids.join(",")}`;
  let response = await fetchWithRetry(url, 5, {
    method: "GET",
    headers: {
      "Content-Type": "application/json"
    }
  });
  if (response) {
    const data = await response.json();
    callback(data);
    if (data.status == "pending") {
      pollForPubmedSearchResults(data, callback, 0);
    }
  }
}

/**
 * gets matches for a given cvId and list of item keys in "scholarship" (eg "i110,i120,i130")
 * @param { string } cvId
 * @param { string[] } items
 * @param { (data: PubMedTitleMatchRequestData) => void } callback
 */
export function getTitleMatchesByCvId(cvId, items, callback) {
  if (items.length == 0) {
    return callback(new PubMedTitleMatchRequestData("complete", "complete"));
  }

  if (cvId) {
    $.ajax({
      type: "GET",
      url: `/pubmed/search_title?cvId=${cvId}&items=${encodeURIComponent(items.join(","))}`,
      dataType: "json",
      success: function (data) {
        callback(data);
        if (data.status == "pending") {
          pollForPubmedSearchResults(data, callback, 0);
        }
      }
    });
  }
}
/**
 *
 * @param { string } title
 * @param { import("@easycv/common/types/cv.js").CV} def
 * @param { ( data:PubMedSearchRequestData) => void } callback
 */
export function getTitleMatches(citationNames, title, def, callback) {
  if (title.length == 0) {
    return callback(errorReturn);
  }

  if (def) {
    $.ajax({
      type: "GET",
      url: `/pubmed/search?citationnames=${citationNames}&title=${encodeURIComponent(title)}&cvId=${def.id}`,
      dataType: "json",
      success: function (data) {
        callback(data);
        if (data.status == "pending") {
          pollForPubmedSearchResults(data, callback, 0);
        }
      }
    });
  }
}

/**
 *
 * @param { { def?: import("@easycv/common/types/cv.js").CV }} opts
 * @param { ( data:PubMedSearchRequestData) => void } callback
 * @returns { void }
 */
function getNameMatches(opts, callback) {
  opts = opts || {};
  callback = callback || function () {};
  if (opts.def) {
    var citNames = getKey(opts.def, "citationName", "")
      .toLowerCase()
      .split(";")
      .filter((a) => a != "");
  } else {
    return callback(errorReturn);
  }
  // var firstNames = getKey(def, 'firstNames', '').toLowerCase().split(';').filter(a => a != '');

  // var citationName = def['citationName'] || '';
  if (citNames.length == 0) return callback(errorReturn);

  var citationNames = citNames
    .map(function (name) {
      return encodeURIComponent(name.trim() + "[Author] ");
    })
    .join("+OR+");
  if (citationNames.length == 0) return callback(errorReturn);

  var url = entrezAuthor + citationNames;
  if (typeof $ == "undefined") {
    if (!opts.request)
      throw "getNameMatches failed due to lack of opts.request parameter";
    opts.request.get(pmDomain + "/" + url, function (error, response, body) {
      if (error != null) {
        console.log(error);
        return callback(errorReturn);
      }
      try {
        var data = JSON.parse(body);
      } catch (e) {
        console.log("parse Error: " + opts.def?.id);
        return callback(errorReturn);
      }

      if (data.esearchresult == null || data.esearchresult.idlist == null) {
        console.log("no esearchresult? Error: ", data, url, opts.def?.id);
        return callback(errorReturn);
      }
      callback(data.esearchresult.idlist);
    });
  } else {
    let matchFirst = true;
    if (!opts.matchFirst) {
      matchFirst = false;
    }

    $.ajax({
      type: "GET",
      url: `/pubmed/search?citationnames=${encodeURIComponent(citNames.join(";"))}&cvId=${opts.def.id}&matchFirst=${matchFirst}`,
      dataType: "json",
      success: function (data) {
        callback(data);
        if (data.status == "pending") {
          pollForPubmedSearchResults(data, callback, 1);
        }
      }
    });
  }
}
export { getNameMatches };

var saveAsterixes = function (oldLine, newLine) {
  var removeIgnore = function (text) {
    // return text.toLowerCase().split('*').join('').split('ǂ').join('').split('†').join('');
    return text
      .toLowerCase()
      .split(/\*|\.|†|ǂ|\<\/?[^\<\>]+\>|\(|\§|\)/)
      .join("")
      .trim();
  };

  var oldOptions = {};
  oldLine.split(/,|\:/).map(function (text) {
    oldOptions[removeIgnore(text)] = text;
  });
  return newLine
    .split(/(,|\:)/)
    .map(function (text, i) {
      if (i % 2 == 1) return text;
      var hash = removeIgnore(text);
      return oldOptions[hash] != null ? oldOptions[hash] : text;
    })
    .join("");
};
export { saveAsterixes };

export const highImpactJournalISSNs = new Set([
  "0028-4793", // The New England Journal of Medicine (Print)
  "1533-4406", // The New England Journal of Medicine (Online)
  "0140-6736", // Lancet (London, England) (Print)
  "1474-547X", // Lancet (London, England) (Online)
  "0098-7484", // JAMA - Journal of the American Medical Association (Print)
  "1538-3598", // JAMA - Journal of the American Medical Association (Online)
  "1078-8956", // Nature Medicine (Print)
  "1546-170X", // Nature Medicine (Online)
  "0028-0836", // Nature (Print)
  "1476-4687", // Nature (Online)
  "0036-8075", // Science (New York, N.Y.) (Print)
  "1095-9203", // Science (New York, N.Y.) (Online)
  "0092-8674", // Cell (Print)
  "1097-4172", // Cell (Online)
  "0009-7322", // Circulation (Print)
  "1524-4539", // Circulation (Online)
  "0959-8138", // BMJ (Clinical Research Ed.) (Print)
  "1756-1833", // BMJ (Clinical Research Ed.) (Online)
  "2214-109X", // The Lancet. Global Health (Print)
  "2214-109X", // The Lancet. Global Health (Online)
  "0031-6997", // Pharmacological Reviews (Print)
  "1521-0081", // Pharmacological Reviews (Online)
  "0003-4819", // Annals of Internal Medicine (Print)
  "1539-3704", // Annals of Internal Medicine (Online)
  "2468-2667", // The Lancet. Public Health (Print)
  "2468-2667", // The Lancet. Public Health (Online)
  "2041-1723", // Nature Communications (Print)
  "2041-1723", // Nature Communications (Online)
  "0362-1642", // Annual Review of Pharmacology and Toxicology (Print)
  "1545-4304", // Annual Review of Pharmacology and Toxicology (Online)
  "0003-4932", // Annals of Surgery (Print)
  "1528-1140", // Annals of Surgery (Online)
  "0043-1354", // Water Research (Print)
  "1879-2448", // Water Research (Online)
  "1549-1277", // PLoS Medicine (Print)
  "1549-1676", // PLoS Medicine (Online)
  "0194-911X", // Hypertension (Dallas, Tex. : 1979) (Print)
  "1524-4563", // Hypertension (Dallas, Tex. : 1979) (Online)
  "0364-5134", // Annals of Neurology (Print)
  "1531-8249" // Annals of Neurology (Online)
]);
