import { getNewId } from "../lib/cvDataUtilities.js";
import { getDate, getYears, timeScore } from "../lib/dateHelpers.js";
import {
  escapeHtml,
  getProp,
  htmlToXML,
  preprocessText,
  setMaxHeight,
  unescapeHTML
} from "../lib/frontendUtilities.js";
import { getLastPosition } from "../lib/reportUtilities.js";
import { clone, getField, getKey, isDiff, keys } from "../lib/utilities.js";

let newDef, refreshSection;
var biosketchSections = {};
var biosketchSearchItems = {};
var biosketchSearchQuery = "";
var biosketchModalData = {
  biosketchId: null,
  sectionKey: null,
  index: null
};
var biosketchHistoryData = { type: null, history: [], index: 0 };

var tips = {
  edu: `Complete the education block. Begin with the baccalaureate or other initial professional education, such as nursing. Include postdoctoral, residency, and clinical fellowship training, as applicable, listing each separately.

For each entry provide:
<ul>
<li>the name and location of the institution</li>
<li>the degree received (if applicable)</li>
<li>the month and year of end date (or expected end date). For fellowship applicants only, also include the month and year of start date.</li>
<li>the field of study (for residency entries, the field of study should reflect the area of residency training)</li>
</ul>Following the education block, complete Sections A-D of the biographical sketch.`,
  personalText: `Briefly describe why you are well-suited for your role(s) in this project. Relevant factors may include: aspects of your training; your previous experimental work on this specific topic or related topics; your technical expertise; your collaborators or scientific environment; and/or your past performance in this or related fields, including ongoing and completed research projects from the past three years that you want to draw attention to (previously captured under Section D. Research Support).

You may cite up to four publications or research products that highlight your experience and qualifications for this project. Research products can include, but are not limited to, audio or video products; conference proceedings such as meeting abstracts, posters, or other presentations; patents; data and research materials; databases; educational aids or curricula; instruments or equipment; models; protocols; and software or netware. Use of hyperlinks and URLs to cite these items is not allowed.

You are allowed to cite interim research products. <b>Note:</b> interim research products have specific citation requirements. See related <a target="_blank" href="http://grants.nih.gov/grants/interim_product_faqs.htm">Frequently Asked Questions</a> for more information.

<b>Note the following additional instructions for ALL applicants/candidates:</b>
<ul>
<li>If you wish to explain factors that affected your past productivity, such as family care responsibilities, illness, disability, or military service, you may address them in this "A. Personal Statement" section.</li>
<li>Indicate whether you have published or created research products under another name.</li>
<li>You may mention specific contributions to science that are not included in Section C. Do not present or expand on materials that should be described in other sections of this Biosketch or application.</li>
<li>Figures, tables, or graphics are not allowed.</li>
</ul>
<b>Note the following instructions for specific subsets of applicants/candidates:</b>
<ul>
<li>For institutional research training, institutional career development, or research education grant applications, faculty who are not senior/key persons are encouraged, but not required, to complete the "A. Personal Statement" section.</li>
<li>Applicants for dissertation research awards (e.g., R36) should, in addition to addressing the points noted above, also include a description of their career goals, their intended career trajectory, and their interest in the specific areas of research designated in the FOA.</li>
<li>Candidates for research supplements to promote diversity in health-related research should, in addition to addressing the points noted above, also include a description of their general scientific achievements and/or interests, specific research objectives, and career goals. Indicate any current source(s) of educational funding.</li>
</ul>`,
  positions: `List in reverse chronological order all current positions and scientific appointments both domestic and foreign, including affiliations with foreign entities or governments. This includes titled academic, professional, or institutional appointments whether or not remuneration is received, and whether full-time, part-time, or voluntary (including adjunct, visiting, or honorary). High school students and undergraduates may include any previous positions. For individuals who are not currently located at the applicant organization, include the expected position at the applicant organization and the expected start date.

List any relevant academic and professional achievements and honors. In particular:
<ul>
<li>Students, postdoctorates, and junior faculty should include scholarships, traineeships, fellowships, and development awards, as applicable.</li>
<li>Clinicians should include information on any clinical licensures and specialty board certifications that they have achieved.</li></ul>`,
  sciContribution: `<b>Who should complete the "Contributions to Science" section:</b>
All senior/key persons should complete the "Contributions to Science" section except candidates for research supplements to promote diversity in health-related research who are high school students, undergraduates, and post-baccalaureates.

<b>Format:</b>
Briefly describe up to five of your most significant contributions to science. The description of each contribution should be no longer than one half page, including citations.

While all applicants may describe up to five contributions, graduate students and postdoctorates may wish to consider highlighting two or three they consider most significant.

<b>Content:</b>
For each contribution, indicate the following:
<ul>
<li>the historical background that frames the scientific problem;</li>
<li>the central finding(s);</li>
<li>the influence of the finding(s) on the progress of science or the application of those finding(s) to health or technology; and</li>
<li>your specific role in the described work.</li>
<li>Figures, tables, or graphics are not allowed.</li></ul>
For each contribution, you may cite up to four publications or research products that are relevant to the contribution. If you are not the author of the product, indicate what your role or contribution was. Note that while you may mention manuscripts that have not yet been accepted for publication as part of your contribution, you may cite only published papers to support each contribution. Research products can include audio or video products (see the <a href="https://grants.nih.gov/grants/policy/nihgps/HTML5/section_2/2.3.7_policies_affecting_applications.htm#Post-Sub">NIH Grants Policy Statement, Section 2.3.7.7: Post-Submission Grant Application Materials</a>); conference proceedings such as meeting abstracts, posters, or other presentations; patents; data and research materials; databases; educational aids or curricula; instruments or equipment; models; protocols; and software or netware. Use of hyperlinks and URLs to cite these items is not allowed.

You are allowed to cite interim research products. Note: interim research products have specific citation requirements. See related <a href="http://grants.nih.gov/grants/interim_product_faqs.htm">Frequently Asked Questions</a> for more information.

You may provide a hyperlinked URL to a full list of your published work. This hyperlinked URL must be to a Federal Government website (a .gov suffix). NIH recommends using <a href="http://www.ncbi.nlm.nih.gov/books/NBK53595/">My Bibliography</a>. Providing a URL to a list of published work is not required.

Descriptions of contributions may include a mention of research products under development, such as manuscripts that have not yet been accepted for publication. These contributions do not have to be related to the project proposed in this application.`,
  schPerf: `<b>Note that only the following types of applicants must complete this section:</b>
<ul>
<li>applicants for predoctoral and postdoctoral fellowships</li>
<li>applicants to dissertation research grants (e.g., R36)</li>
<li>candidates for research supplements to promote diversity in health-related research from the undergraduate through postdoctoral levels</li></ul>
<b>Predoctoral applicants/candidates (including undergraduates and post-baccalaureates):</b> List by institution and year <b>all</b> undergraduate and graduate courses, with grades. In addition, explain any grading system used if it differs from a 1-100 scale; an A, B, C, D, F system; or a 0-4.0 scale. Also indicate the levels required for a passing grade.

<b>Postdoctoral applicants:</b> List by institution and year <b>all</b> graduate scientific and/or professional courses with grades. In addition, explain any grading system used if it differs from a 1-100 scale; an A, B, C, D, F system; or a 0-4.0 scale. Also indicate the levels required for a passing grade`
};

var makeBiosketchTable = function (sectionKey, newDef, opts) {
  opts = opts || {}; // index, prefix
  var dataSet =
    opts.dataSet != null
      ? opts.dataSet
      : opts.index == null
        ? opts.biosketch[sectionKey]
        : opts.biosketch[sectionKey][opts.index].set;
  var itemList = _.orderBy(
    _.flatten(
      keys(dataSet).map(function (fieldPlus) {
        var field = fieldPlus.split("+")[0];
        return keys(dataSet[fieldPlus])
          .map(function (hash) {
            var id = hash.split("+")[0];
            var item = newDef.lists[field][id];
            if (item == null)
              return { id: id, field: field, fieldPlus: fieldPlus, hash: hash };

            var subKey = fieldPlus.split("+")[1];
            var subI = hash.split("+")[1];
            var subItem =
              subKey != null && subI != null ? item[subKey][subI] : null;
            return {
              id: id,
              field: field,
              fieldPlus: fieldPlus,
              hash: hash,
              time: item.time,
              item: item,
              subItem: subItem
            };
          })
          .filter(function (n) {
            return n != null;
          });
      })
    ),
    [
      function (info) {
        // Start
        return getYears((info.time || {}).start) * (opts.flipChron ? -1 : 1);
      },
      function (info) {
        // End
        return getYears((info.time || {}).end) * (opts.flipChron ? -1 : 1);
      }
    ]
  );

  return itemList
    .map(function (info, i) {
      var item = info.item;
      var subItem = info.subItem;

      if (
        item == null ||
        (!opts.edu && biosketchSections[sectionKey][info.fieldPlus] == null)
      ) {
        if (!!opts.noRemove) {
          return (
            '<tr><td></td><td style="font-weight: bold;">Content Removed</td>' +
            (!!opts.edu ? "<td></td><td></td>" : "") +
            "</tr>"
          );
        } else {
          delete dataSet[info.fieldPlus][info.hash];
        }
        return;
      }

      return (
        '<tr data-hash="' +
        escapeHtml(info.hash) +
        '" data-field="' +
        escapeHtml(info.fieldPlus) +
        '" data-key="' +
        escapeHtml(sectionKey) +
        '" ' +
        (opts.index != null ? 'data-index="' + opts.index + '"' : "") +
        ">" +
        (!!opts.edu
          ? '<td><a href="#" class="jump" data-id="' +
            info.id +
            '" data-field="' +
            info.field +
            '">' +
            escapeHtml(item.location || "", { allowTags: true }) +
            '<span class="glyphicon glyphicon-share-alt" style="margin-left: 5px;"></span></a></td>' +
            "<td>" +
            escapeHtml(
              info.field == "education" ? item.degree || "" : item.title || "",
              { allowTags: true }
            ) +
            "</td>" +
            (opts.biosketch.version != "2026_fellow"
              ? ""
              : "<td>" +
                (item?.time?.start == null
                  ? ""
                  : getDate(
                      { time: { start: item?.time?.start } },
                      "monthAndFullYear"
                    )) +
                "</td>") +
            "<td>" +
            getDate(
              item.time.end != null && item.time.end != "ongoing"
                ? { time: { start: item.time.end } }
                : item,
              "monthAndFullYear"
            ) +
            "</td>" +
            "<td>" +
            escapeHtml(
              info.field == "education"
                ? item.secondaryInfo || ""
                : item.field || "",
              { allowTags: true }
            ) +
            "</td>"
          : // '  <td><a href="#" class="jump" data-id="'+info.id+'" data-field="'+info.field+'"">'+(opts.prefix == 'index' ? (i + 1) + '.' : getDate(subItem || item, 'monthAndFullYear'))+'<span class="glyphicon glyphicon-share-alt" style="margin-left: 5px;"></span></a></td>'+
            // '  <td>'+escapeHtml(biosketchSections[sectionKey][info.fieldPlus](item, subItem).trim(), { allowTags: true }).split('\n').join('<br>')+'</td>'
            '  <td><a href="#" class="jump" data-id="' +
            info.id +
            '" data-field="' +
            info.field +
            '"">' +
            (opts.prefix == "index"
              ? i + 1 + "."
              : getDate(subItem || item, "monthAndFullYear")) +
            '<span class="glyphicon glyphicon-share-alt" style="margin-left: 5px;"></span></a></td>' +
            "  <td>" +
            escapeHtml(
              biosketchSections[sectionKey][info.fieldPlus](
                item,
                subItem
              ).trim(),
              { allowTags: true }
            )
              .split("\n")
              .join("<br>") +
            "</td>") +
        (!!opts.noRemove
          ? ""
          : '  <td><span class="glyphicon glyphicon-remove removeBiosketchItem" style="cursor: pointer;"></span></td>') +
        "</tr>"
      );
    })
    .join("");
};

var refreshBiosketchModal = function () {
  var biosketchData = getKey(newDef, "biosketch", {});
  var biosketch = biosketchData[biosketchModalData.biosketchId];

  var data = biosketch[biosketchModalData.sectionKey];
  if (data[biosketchModalData.index] != null)
    data = data[biosketchModalData.index].set;
  var potentialData = biosketchSearchItems[biosketchModalData.sectionKey];

  $("#biosketchModalList").empty();

  _.orderBy(
    potentialData.filter(function (item) {
      return (
        biosketchSearchQuery.length == 0 ||
        item.text.toLowerCase().indexOf(biosketchSearchQuery.toLowerCase()) >= 0
      );
    }),
    function (item) {
      return timeScore(item.time);
    }
  ).map(function (item) {
    var exists = (data[item.field] || {})[item.i] != null;
    $("#biosketchModalList").append(
      $(
        "" +
          '<div data-field="' +
          escapeHtml(item.field) +
          '" data-id="' +
          item.i +
          '" class="biosketchModalItem" style="display: inline-block;width: 100%;border-radius: 10px;background-color: ' +
          (exists ? "#99c8f3" : "#dfdfdf") +
          ';margin-bottom: 10px;padding: 10px; cursor: pointer;">' +
          (exists
            ? '<span style="display: inline-block; color: black; float: right;" class="glyphicon glyphicon-remove"></span>'
            : '<span style="display: inline-block; color: #4a9544; float: right;" class="glyphicon glyphicon-plus"></span>') +
          '  <span style="display: inline-block;width: calc(100% - 20px);color: black;margin: 0px;">' +
          escapeHtml(item.text, { allowTags: true }) +
          "</span>" +
          "</div>"
      )
    );
  });
  // Go through all potential data, filtered by query, and show if they are in the data or not.

  $(".biosketchModalItem")
    .unbind("click")
    .click(function () {
      var field = getProp(this, "field");
      var id = getProp(this, "id");
      var exists = (data[field] || {})[id] != null;
      if (exists) delete data[field][id];
      else getKey(data, field, {})[id] = true;
      refreshBiosketchModal();
    });

  $("#biosketchSelectAll")
    .unbind("click")
    .click(function () {
      var biosketchData = getKey(newDef, "biosketch", {});
      var biosketch = biosketchData[biosketchModalData.biosketchId];
      var data = biosketch[biosketchModalData.sectionKey];
      if (data[biosketchModalData.index] != null)
        data = data[biosketchModalData.index].set;
      var potentialData = biosketchSearchItems[biosketchModalData.sectionKey];
      potentialData
        .filter(function (item) {
          return (
            biosketchSearchQuery.length == 0 ||
            item.text
              .toLowerCase()
              .indexOf(biosketchSearchQuery.toLowerCase()) >= 0
          );
        })
        .map(function (item) {
          getKey(data, item.field, {})[item.i] = true;
        });
      refreshBiosketchModal();
    });

  $("#biosketchClear")
    .unbind("click")
    .click(function () {
      var biosketchData = getKey(newDef, "biosketch", {});
      var biosketch = biosketchData[biosketchModalData.biosketchId];
      var data = biosketch[biosketchModalData.sectionKey];
      if (data[biosketchModalData.index] != null)
        data = data[biosketchModalData.index].set;
      for (var field in data)
        for (var id in data[field]) delete data[field][id];
      refreshBiosketchModal();
    });

  $("#biosketchSave")
    .unbind("click")
    .click(function () {
      refreshSection();
    });
};

export default function (opts) {
  opts = opts || {}; // Will need template-specific places to look for content.

  return {
    field: "biosketch",
    type: "custom",
    title: "NIH Biosketch",
    noContentCheck: true,
    filter: function ({ contextState }) {
      return !!contextState.canEdit;
    },
    setup: function ({
      contextMethods,
      contextConstants,
      contextState,
      backend
    }) {
      let { autoTipSetup, checkSave } = contextMethods;
      let { user, cvOwner, users } = contextConstants;
      let { section, flipChron, sectionState, format } = contextState;
      newDef = contextState.newDef;
      refreshSection = contextMethods.refreshSection;

      $("#fieldList").empty();

      var biosketchData = getKey(newDef, "biosketch", {});
      var biosketchList = keys(biosketchData).filter(
        (k) => biosketchData[k] != null
      );

      var refreshBiosketchHistoryModal = function () {
        var change = biosketchHistoryData.history[biosketchHistoryData.index];
        $("#biosketchHistoryContent").empty();
        if (change == null)
          return setTimeout(function () {
            $("#biosketchHistoryModal").modal("hide");
          }, 0);

        var toObj = {};
        try {
          toObj = JSON.parse(change.to);
        } catch (e) {}

        var dateText = new Date(change.time).toLocaleString("en-US", {
          weekday: "short",
          year: "numeric",
          month: "short",
          day: "numeric",
          hour: "2-digit",
          minute: "2-digit"
        });
        var userText = escapeHtml((users[change.userid] || {}).name || "");

        var title = dateText + (userText != "" ? " (by " + userText + ")" : "");
        $("#biosketchHistoryTitle").text(title);
        $("#biosketchHistoryPrev")
          .css("visibility", biosketchHistoryData.index > 0 ? "" : "hidden")
          .unbind("click")
          .click(function () {
            biosketchHistoryData.index--;
            refreshBiosketchHistoryModal();
          });
        $("#biosketchHistoryNext")
          .css(
            "visibility",
            biosketchHistoryData.index < biosketchHistoryData.history.length - 1
              ? ""
              : "hidden"
          )
          .unbind("click")
          .click(function () {
            biosketchHistoryData.index++;
            refreshBiosketchHistoryModal();
          });

        if (biosketchHistoryData.type == "edu") {
          var tableEl = $(
            '<table style="width: 100%; margin-bottom: 10px;"></table>'
          );
          tableEl.append(
            $(
              makeBiosketchTable("edu", newDef, {
                dataSet: toObj,
                edu: true,
                noRemove: true,
                flipChron
              })
            )
          );
          $("#biosketchHistoryContent").append(tableEl);
        } else if (biosketchHistoryData.type == "personalText") {
          $("#biosketchHistoryContent").html(
            escapeHtml(toObj, { allowTags: true })
          );
        } else if (biosketchHistoryData.type == "positions") {
          var tableEl = $(
            '<table style="width: 100%; margin-bottom: 10px;"></table>'
          );
          tableEl.append(
            $(
              makeBiosketchTable("positions", newDef, {
                dataSet: toObj,
                noRemove: true,
                flipChron: true
              })
            )
          );
          $("#biosketchHistoryContent").append(tableEl);
        } else if (biosketchHistoryData.type == "honors") {
          var tableEl = $(
            '<table style="width: 100%; margin-bottom: 10px;"></table>'
          );
          tableEl.append(
            $(
              makeBiosketchTable("honors", newDef, {
                dataSet: toObj,
                noRemove: true,
                flipChron: true
              })
            )
          );
          $("#biosketchHistoryContent").append(tableEl);
        } else if (biosketchHistoryData.type == "sciContribution") {
          toObj.map(function (sectionItem, i) {
            var sectionEl = $(
              '<div><span class="biosketchHistoryText" style="width: 100%; display: inline-block; margin-bottom: 10px;"></span><table class="biosketchHistoryTable" style="width: 100%; margin-bottom: 10px;"></table></div>'
            );
            sectionEl
              .find(".biosketchHistoryText")
              .html(escapeHtml(sectionItem.text, { allowTags: true }));
            sectionEl.find(".biosketchHistoryTable").append(
              $(
                makeBiosketchTable("sciContribution", newDef, {
                  dataSet: sectionItem.set,
                  noRemove: true,
                  flipChron
                })
              )
            );
            $("#biosketchHistoryContent").append(sectionEl);
          });
        } else if (biosketchHistoryData.type == "addInfo") {
          toObj.map(function (sectionItem, i) {
            var sectionEl = $(
              '<div><span class="biosketchHistoryText" style="width: 100%; display: inline-block; margin-bottom: 10px;"></span><table class="biosketchHistoryTable" style="width: 100%; margin-bottom: 10px;"></table></div>'
            );
            sectionEl
              .find(".biosketchHistoryText")
              .html(escapeHtml(sectionItem.text, { allowTags: true }));
            sectionEl.find(".biosketchHistoryTable").append(
              $(
                makeBiosketchTable("addInfo", newDef, {
                  dataSet: sectionItem.set,
                  noRemove: true,
                  flipChron
                })
              )
            );
            $("#biosketchHistoryContent").append(sectionEl);
          });
        } else if (biosketchHistoryData.type == "schPerf") {
          $("#biosketchHistoryContent").append(
            '<table style="margin-top: 10px; margin-bottom: 10px;width: 100%;">' +
              '<tr><td style="width: 150px; font-weight: bold;">Year</td><td style="font-weight: bold;">Course Title</td><td style="font-weight: bold; width: 150px;">Grade</td></tr>' +
              toObj
                .map(function (rowData, i) {
                  return (
                    '<tr data-rowI="' +
                    i +
                    '">' +
                    "<td>" +
                    escapeHtml(rowData.year || "") +
                    "</td>" +
                    "<td>" +
                    escapeHtml(rowData.title || "") +
                    "</td>" +
                    "<td>" +
                    escapeHtml(rowData.grade || "") +
                    "</td>" +
                    "</tr>"
                  );
                })
                .join("") +
              "</table>"
          );
        }

        autoTipSetup();
      };

      if (biosketchList.length == 0) {
        var bId = getNewId(biosketchData, "b");
        biosketchData[bId] = {
          id: bId,
          title: "Default Biosketch",
          name: newDef.bio.name || "",
          username: "",
          personalText: "",
          positions: {},
          honors: {},
          sciContribution: [{ text: "", set: {} }],
          addInfo: [{ text: "", set: {} }]
        };
        biosketchList.push(bId);
      }

      var overviewState = getKey(sectionState, section, {});
      if (
        overviewState.biosketchId == null ||
        biosketchData[overviewState.biosketchId] == null
      )
        overviewState.biosketchId = biosketchList[0];
      var biosketch = biosketchData[overviewState.biosketchId];

      biosketchSections = opts.sections;
      biosketchModalData.biosketchId = overviewState.biosketchId;

      $("#biosketchModalSearch")
        .unbind("input")
        .on("input", function () {
          biosketchSearchQuery = $(this).val().trim();
          refreshBiosketchModal();
        });

      var emptySet = function (set) {
        var idList = [];
        for (var field in set) for (var id in set[field]) idList.push(id);
        return idList.length == 0;
      };

      biosketch.sciContribution = biosketch.sciContribution.filter(
        function (bSection, i) {
          return i == 0 || !emptySet(bSection.set) || bSection.text.length > 0;
        }
      );
      biosketch.addInfo = biosketch.addInfo.filter(function (bSection, i) {
        return i == 0 || !emptySet(bSection.set) || bSection.text.length > 0;
      });

      biosketchSearchItems = {};

      for (var sectionKey in biosketchSections) {
        biosketchSearchItems[sectionKey] = [];
        for (var fieldPlus in biosketchSections[sectionKey]) {
          var field = fieldPlus.split("+")[0];
          var subKey = fieldPlus.split("+")[1];
          getField(newDef, field, { addId: true }).map(function (item) {
            if (subKey != null) {
              (item[subKey] || []).map(function (subItem, subI) {
                var text = biosketchSections[sectionKey][fieldPlus](
                  item,
                  subItem
                );
                if (text == null || text.length == 0) return;
                var dateText = getDate(subItem, "monthAndFullYear");
                biosketchSearchItems[sectionKey].push({
                  text: dateText + "  " + text,
                  time: subItem.time,
                  field: fieldPlus,
                  i: item.id,
                  subI: subI
                });
              });
            } else {
              var text = biosketchSections[sectionKey][fieldPlus](item);
              if (text == null || text.length == 0) return;
              var dateText = getDate(item, "monthAndFullYear");
              biosketchSearchItems[sectionKey].push({
                text: dateText + "  " + text,
                time: item.time,
                field: fieldPlus,
                i: item.id
              });
            }
          });
        }
      }

      biosketchSearchItems.edu = getField(newDef, "education", { addId: true })
        .map(function (item) {
          return {
            text: [item.location || "", item.degree || ""].join(", "),
            field: "education",
            location: item.location || "",
            degree: item.degree || "",
            time: item.time,
            secondary: item.secondaryInfo || "",
            i: item.id
          };
        })
        .concat(
          getField(newDef, "postDoctoralTraining", { addId: true }).map(
            function (item) {
              return {
                text: [item.location || "", item.title || ""].join(", "),
                field: "postDoctoralTraining",
                location: item.location || "",
                degree: item.title || "",
                time: item.time,
                secondary: item.field || "",
                i: item.id
              };
            }
          )
        );

      if (biosketch.edu == null) {
        // Fill up if empty.
        biosketch.edu = {};
        biosketchSearchItems.edu.map(function (item) {
          getKey(biosketch.edu, item.field, {})[
            item.i + (item.subI != null ? "+" + item.subI : "")
          ] = true;
        });
      }

      var last = getLastPosition(newDef, { format });
      $("#fieldList").append(
        "" +
          "<div>" +
          '  <h4 style="display: inline-block;margin-right: 10px;margin-bottom: 20px;">Saved Biosketches:</h4>' +
          '  <select id="biosketchListChange" class="form-control" style="width: auto; margin-right: 10px; margin-bottom: 10px;">' +
          biosketchList
            .map(function (n) {
              return (
                "<option " +
                (n == overviewState.biosketchId ? "selected " : "") +
                'value="' +
                n +
                '">' +
                escapeHtml(biosketchData[n].title) +
                "</option>"
              );
            })
            .join("") +
          '<option value="copy">Copy This Biosketch</option>' +
          '<option value="new">New Biosketch</option>' +
          "  </select>" +
          '  <span id="biosketchListRename" class="glyphicon glyphicon-pencil" style="margin-right: 10px; margin-bottom: 10px;"></span>' +
          '  <span id="biosketchListRemove" class="glyphicon glyphicon-remove" style="margin-right: 10px; margin-bottom: 10px;"></span>' +
          '  <select id="biosketchVersion" class="form-control" style="width: auto; margin-right: 10px; margin-bottom: 10px;">' +
          "  </select>" +
          '  <div id="biosketchListDownload" class="btn btn-primary" style="margin-right: 10px;margin-bottom: 10px;display: inline-block;vertical-align: inherit;">' +
          '    <span class="glyphicon glyphicon-download" style="margin-right: 10px;"></span>Download' +
          "  </div>" +
          "</div>"
      );
      $("#fieldList").append(
        "<div>" +
          '<div style="vertical-align: top;margin-right: 5px;display: inline-block;margin-bottom: 5px;">' +
          '  <h5 style="margin-top: 9px;display: inline-block;white-space: pre-wrap;vertical-align: top;">Name:</h5>    ' +
          '  <input id="biosketchName" value="' +
          escapeHtml(biosketch.name || "") +
          '" class="form-control" type="text" style="display: inline-block;width: 223px;vertical-align: top;">  ' +
          "</div>" +
          '<div style="vertical-align: top;margin-right: 5px;display: inline-block;margin-bottom: 5px;">' +
          '  <h5 style="margin-top: 9px;display: inline-block;white-space: pre-wrap;vertical-align: top;">eRA Commons User Name (credential, e.g. agency login): </h5>    ' +
          '  <input id="biosketchUsername" value="' +
          escapeHtml(biosketch.username || "") +
          '" class="form-control" type="text" style="display: inline-block;width: 150px;vertical-align: top;">  ' +
          "</div>" +
          '<div style="vertical-align: top;margin-right: 5px;display: inline-block;margin-bottom: 5px;">' +
          '  <h5 style="margin-top: 9px;display: inline-block;white-space: pre-wrap;vertical-align: top;">Position Title: <a href="#" class="sectionTab" data-section="facultyAppointments">' +
          (last == null
            ? "No Title"
            : escapeHtml(last.title) + " (" + getDate(last) + ")") +
          '<span class="glyphicon glyphicon-share-alt" style="margin-left: 5px;"></span></a></h5>    ' +
          "</div>" +
          "</div>"
      );

      var versionList = [
        { key: "2026_nonfellow", name: "Non-Fellowship" },
        { key: "2026_fellow", name: "Fellowship" }
      ];
      if (
        versionList.every(function (item) {
          return biosketch.version != item.key;
        })
      )
        biosketch.version = versionList[0].key;
      $("#biosketchVersion").empty();
      versionList.map(function (versionItem) {
        $("#biosketchVersion").append(
          $(
            '<option value="' +
              versionItem.key +
              '"' +
              (versionItem.key == biosketch.version ? " selected" : "") +
              ">" +
              versionItem.name +
              "</option>"
          )
        );
      });
      $("#biosketchVersion")
        .unbind("change")
        .on("change", function () {
          var biosketch = biosketchData[overviewState.biosketchId];
          biosketch.version = $(this).val();
          refreshSection();
        });

      $("#biosketchListDownload")
        .unbind("click")
        .on("click", function () {
          var biosketch = biosketchData[overviewState.biosketchId];
          var template = {
            "2026_nonfellow":
              "/templateFiles/nih_biosketch_non_fellowship_v1_0.docx",
            "2026_fellow": "/templateFiles/nih_biosketch_fellowship_v1_0.docx"
          }[biosketch.version];

          if (user.license == "locked") {
            $("#myAccount").trigger("click");
            return;
          }

          // var cvId = genOpts.id;
          var oReq = new XMLHttpRequest();
          oReq.open("GET", template, true);
          oReq.responseType = "arraybuffer";

          var getTextTable = function (sectionKey, opts) {
            opts = opts || {};
            var dataSet =
              opts.index == null
                ? biosketch[sectionKey]
                : biosketch[sectionKey][opts.index].set;
            var itemList = _.orderBy(
              _.flatten(
                keys(dataSet).map(function (fieldPlus) {
                  var field = fieldPlus.split("+")[0];
                  return keys(dataSet[fieldPlus])
                    .map(function (hash) {
                      var id = hash.split("+")[0];
                      var item = newDef.lists[field][id];
                      if (item == null) return;
                      return { id, field, fieldPlus, hash, time: item.time };
                    })
                    .filter(function (n) {
                      return n != null;
                    });
                })
              ),
              [
                function (info) {
                  // Start
                  return (
                    getYears((info.time || {}).start) * (flipChron ? -1 : 1)
                  );
                },
                function (info) {
                  // End
                  return getYears((info.time || {}).end) * (flipChron ? -1 : 1);
                }
              ]
            );

            return itemList
              .filter(function (info) {
                var item = newDef.lists[info.field][info.id];
                return item != null;
              })
              .map(function (info, i) {
                var item = newDef.lists[info.field][info.id];
                var subKey = info.fieldPlus.split("+")[1];
                var subI = info.hash.split("+")[1];
                var subItem =
                  subKey != null && subI != null ? item[subKey][subI] : null;

                if (!!opts.edu) {
                  return {
                    1: htmlToXML(item.location),
                    2: htmlToXML(
                      info.field == "education" ? item.degree : item.title
                    ),
                    3:
                      item?.time?.start == null
                        ? ""
                        : getDate(
                            { time: { start: item.time.start } },
                            "monthAndFullYear"
                          ),
                    4: getDate(
                      item?.time?.end != null && item?.time?.end != "ongoing"
                        ? { time: { start: item?.time?.end } }
                        : item,
                      "monthAndFullYear"
                    ),
                    5: htmlToXML(
                      info.field == "education"
                        ? item.secondaryInfo
                        : item.field
                    )
                  };
                } else {
                  return {
                    1:
                      opts.prefix == "index"
                        ? i + 1 + "."
                        : getDate(subItem || item, "monthAndFullYear"),
                    2: htmlToXML(
                      biosketchSections[sectionKey][info.fieldPlus](
                        item,
                        subItem
                      ).trim(),
                      {
                        italic: true,
                        font: "Arial",
                        citationNames: newDef["citationName"] || ""
                      }
                    )
                  };
                }
              });
          };

          oReq.onload = function (oEvent) {
            var arrayBuffer = oReq.response;

            // if you want to access the bytes:
            var byteArray = new Uint8Array(arrayBuffer);

            //Load the docx file as a binary
            var content = byteArray;

            var zip = new PizZip(content);
            var doc = new Docxtemplater();
            doc.loadZip(zip);
            doc.setOptions({ linebreaks: true });

            var last = getLastPosition(newDef, { format });

            var data = {
              name: biosketch.name,
              username: biosketch.username,
              title: last == null ? "" : last.title,
              edu: getTextTable("edu", { edu: true }),
              secA: htmlToXML(biosketch.personalText),
              pos: getTextTable("positions", { prefix: "date" }),
              hon: getTextTable("honors", { prefix: "date" }),
              secC: biosketch.sciContribution.map(function (item, i) {
                return {
                  text: htmlToXML(item.text),
                  c: getTextTable("sciContribution", {
                    index: i,
                    prefix: "index"
                  })
                };
              }),
              sch: (biosketch.schPerf || []).map((perfRow) => {
                return {
                  1: perfRow.year || "",
                  2: perfRow.title || "",
                  3: perfRow.grade || ""
                };
              })
            };

            doc.setData(data);

            console.log("Generating biosketch w/ data ", data);

            try {
              doc.render();
            } catch (error) {
              console.log({
                message: error.message,
                name: error.name,
                stack: error.stack,
                properties: error.properties
              });
              throw error;
            }

            var fileName =
              "NIH_Biosketch_" +
              cvOwner.name
                .split(",")
                .join("")
                .split(".")
                .join("")
                .split(" ")
                .join("_") +
              ".docx";

            backend.send("saveLog", {
              id: user.id,
              path: window.location.pathname,
              action: JSON.stringify({
                type: "download Biosketch",
                title: fileName.split("\n").join("")
              }),
              context: JSON.stringify({
                agent: window.navigator.userAgent,
                params: window.location.search,
                cvId: cvId,
                cvOwner: cvOwner.id
              })
            });

            saveAs(
              doc.getZip().generate({
                type: "blob",
                mimeType:
                  "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
              }),
              fileName.split("\n").join("")
            );
          };
          oReq.send();
        });

      $("#biosketchListChange")
        .unbind("change")
        .on("change", function () {
          var newId = $(this).val();
          if (newId == "new") {
            var newTitle = (prompt("Set Biosketch Name:") || "").trim();
            if (newTitle.length > 0) {
              var bId = getNewId(biosketchData, "b");
              biosketchData[bId] = {
                id: bId,
                title: newTitle,
                name: newDef.bio.name || "",
                username: "",
                personalText: "",
                positions: {},
                honors: {},
                sciContribution: [{ text: "", set: {} }],
                addInfo: [{ text: "", set: {} }]
              };
              overviewState.biosketchId = bId;
            }
          } else if (newId == "copy") {
            var newTitle = (
              prompt(
                "Set Biosketch Name (Copy of " +
                  biosketchData[overviewState.biosketchId].title +
                  "):"
              ) || ""
            ).trim();
            if (newTitle.length > 0) {
              var bId = getNewId(biosketchData, "b");
              biosketchData[bId] = clone(
                biosketchData[overviewState.biosketchId]
              );
              biosketchData[bId].title = newTitle;
              overviewState.biosketchId = bId;
            }
          } else {
            overviewState.biosketchId = newId;
          }
          refreshSection();
        });

      $("#biosketchListRename")
        .unbind("click")
        .on("click", function () {
          var biosketch = biosketchData[overviewState.biosketchId];
          var newTitle = (
            prompt("New Name For " + biosketch.title) || ""
          ).trim();
          if (newTitle.length > 0) biosketch.title = newTitle;
          refreshSection();
        });

      $("#biosketchListRemove")
        .unbind("click")
        .on("click", function () {
          var biosketch = biosketchData[overviewState.biosketchId];
          if (
            !confirm("Are you sure you want to delete " + biosketch.title + "?")
          )
            return;
          delete biosketchData[overviewState.biosketchId];
          overviewState.biosketchId = null;
          refreshSection();
        });

      $("#biosketchName")
        .unbind("input")
        .on("input", function () {
          var biosketch = biosketchData[overviewState.biosketchId];
          biosketch.name = $(this).val();
          checkSave();
        });
      $("#biosketchUsername")
        .unbind("input")
        .on("input", function () {
          var biosketch = biosketchData[overviewState.biosketchId];
          biosketch.username = $(this).val();
          checkSave();
        });

      $("#fieldList").append(
        "<div>" +
          "  <h4>Education / Training" +
          '<span class="btn biosketchHistory" data-type="edu" style="margin-left: 10px;" data-toggle="popover" data-placement="bottom" data-content="History"><span class="glyphicon glyphicon-time"></span></span>' +
          '<span class="btn"><span class="glyphicon glyphicon-info-sign autoTip" data-tip="' +
          encodeURIComponent(tips.edu) +
          '" data-source="https://grants.nih.gov/grants/forms/biosketch.htm"></span></span>' +
          "</h4>" +
          '  <table style="width: 100%;">' +
          "    <tr>" +
          "      <td>INSTITUTION AND LOCATION</td>" +
          "      <td>DEGREE (if applicable)</td>" +
          (biosketch.version == "2026_fellow"
            ? "      <td>Start Date MM/YYYY</td>"
            : "") +
          "      <td>Completion Date MM/YYYY</td>" +
          "      <td>FIELD OF STUDY</td>" +
          "      <td></td>" +
          "    </tr>" +
          makeBiosketchTable("edu", newDef, {
            edu: true,
            biosketch,
            flipChron
          }) +
          "  </table>" +
          '  <div><input id="biosketchSearchEdu" class="form-control" placeholder="Search to add"></div>' +
          "</div>"
      );

      var searchEduBox = new Awesomplete($("#biosketchSearchEdu")[0], {
        list: biosketchSearchItems.edu,
        minChars: 1,
        autoFirst: true,
        maxItems: 8,
        data: function (item, input) {
          return {
            label:
              item.text.length > 100
                ? item.text.slice(0, 100) + ".."
                : item.text,
            text: item.text,
            value: item
          };
        },
        replace: function (item) {
          return item.label;
        }
      });

      var awesompleteElement = $("#biosketchSearchEdu").parent();
      var inputGroup = $(
        '    <div class="input-group container" style="width: 130px; padding-left: 0px; margin-left:5px; padding-right: 0px; margin-top:5px;">' +
          '      <span class="input-group-addon btn btn-default biosketchSearchEdu_open"><span class="glyphicon glyphicon-search"></span></span>' +
          '      <span class="input-group-addon btn btn-default biosketchSearchEdu_addAll">Add All</span>' +
          "    </div>"
      );
      awesompleteElement.parent().append(inputGroup);
      inputGroup.prepend($("#biosketchSearchEdu"));
      inputGroup.prependTo(awesompleteElement);

      $(".biosketchSearchEdu_open").click(function () {
        $("#biosketchModal").modal({
          backdrop: "static",
          keyboard: false,
          show: true
        });
        $("#biosketchModalSearch").val("");
        biosketchModalData.sectionKey = "edu";
        biosketchModalData.index = null;
        refreshBiosketchModal();
        setTimeout(function () {
          setMaxHeight("#biosketchModal");
          $("#biosketchModalSearch").focus();
        }, 200);
      });

      $(".biosketchSearchEdu_addAll").click(function () {
        var biosketch = biosketchData[overviewState.biosketchId];
        biosketchSearchItems.edu.map(function (item) {
          getKey(biosketch.edu, item.field, {})[
            item.i + (item.subI != null ? "+" + item.subI : "")
          ] = true;
        });
        refreshSection();
      });

      $("#biosketchSearchEdu").parent().css("width", "100%");
      searchEduBox.container.addEventListener(
        "awesomplete-selectcomplete",
        function (element) {
          console.log(element.text);
          var item = element.text.value;

          var biosketch = biosketchData[overviewState.biosketchId];
          getKey(biosketch.edu, item.field, {})[
            item.i + (item.subI != null ? "+" + item.subI : "")
          ] = true;
          refreshSection();
        }
      );

      $("#fieldList").append(
        "<div>" +
          "  <h4>A. Personal Statement" +
          '<span class="btn biosketchHistory" data-type="personalText" style="margin-left: 10px;" data-toggle="popover" data-placement="bottom" data-content="History"><span class="glyphicon glyphicon-time"></span></span>' +
          '<span class="btn"><span class="glyphicon glyphicon-info-sign autoTip" data-tip="' +
          encodeURIComponent(tips.personalText) +
          '" data-source="https://grants.nih.gov/grants/forms/biosketch.htm"></span></span>' +
          "</h4>" +
          '  <div id="biosketchPersonalText" style="min-height: 150px;border-radius: 5px;border: 1px solid #afafaf; padding: 5px;">' +
          escapeHtml((biosketch.personalText || "").split("\n").join("<br>"), {
            allowTags: true
          }) +
          "</div>" +
          "</div>"
      );

      tinymce.remove("#biosketchPersonalText");
      tinymce.init({
        selector: "#biosketchPersonalText",
        statusbar: false,
        inline: true,
        theme: "modern",
        menubar: false,
        elementpath: false,
        plugins: "paste link",
        paste_word_valid_elements: "b,strong,i,u,em,br,sup,sub,p",
        default_link_target: "_blank",
        target_list: false,
        link_title: false,
        formats: { underline: { inline: "u", exact: true } },
        paste_enable_default_filters: false,
        paste_preprocess: preprocessText(contextState),
        force_br_newlines: true,
        force_p_newlines: false,
        forced_root_block: "",
        entity_encoding: "raw",
        toolbar1:
          "bold italic underline subscript superscript | link unlink | removeformat",
        setup: function (editor) {
          var updatePersonalText = function (text) {
            var biosketch = biosketchData[overviewState.biosketchId];
            biosketch.personalText = unescapeHTML(text.trim(), {
              allowTags: true
            });
            checkSave();
          };
          editor.on("input", function () {
            updatePersonalText(editor.getContent());
          });
          editor.on("change", function () {
            updatePersonalText(editor.getContent());
          });
        }
      });

      $("#fieldList").append(
        "<div>" +
          "  <h4>B. Positions, Scientific Appointments, and Honors</h4>" +
          "  <h4>Positions and Scientific Appointments" +
          '<span class="btn biosketchHistory" data-type="positions" style="margin-left: 10px;" data-toggle="popover" data-placement="bottom" data-content="History"><span class="glyphicon glyphicon-time"></span></span>' +
          '<span class="btn"><span class="glyphicon glyphicon-info-sign autoTip" data-tip="' +
          encodeURIComponent(tips.positions) +
          '" data-source="https://grants.nih.gov/grants/forms/biosketch.htm"></span></span>' +
          "</h4>" +
          '  <div><input id="biosketchSearchPositions" class="form-control" placeholder="Search to add"></div>' +
          '  <table style="margin-top: 10px; margin-bottom: 10px;">' +
          makeBiosketchTable("positions", newDef, { biosketch, flipChron }) +
          "</table>" +
          "  <h4>Honors" +
          '<span class="btn biosketchHistory" data-type="honors" style="margin-left: 10px;" data-toggle="popover" data-placement="bottom" data-content="History"><span class="glyphicon glyphicon-time"></span></span>' +
          '<span class="btn"><span class="glyphicon glyphicon-info-sign autoTip" data-tip="' +
          encodeURIComponent(tips.positions) +
          '" data-source="https://grants.nih.gov/grants/forms/biosketch.htm"></span></span>' +
          "</h4>" +
          '  <div><input id="biosketchSearchHonors" class="form-control" placeholder="Search to add"></div>' +
          '  <table style="margin-top: 10px; margin-bottom: 10px;">' +
          makeBiosketchTable("honors", newDef, { biosketch, flipChron }) +
          "</table>" +
          "</div>"
      );

      var searchPositionsBox = new Awesomplete(
        $("#biosketchSearchPositions")[0],
        {
          list: biosketchSearchItems["positions"],
          minChars: 1,
          autoFirst: true,
          maxItems: 8,
          data: function (item, input) {
            return {
              label:
                item.text.length > 100
                  ? item.text.slice(0, 100) + ".."
                  : item.text,
              text: item.text,
              value: item
            };
          },
          replace: function (item) {
            return item.label;
          }
        }
      );

      var awesompleteElement = $("#biosketchSearchPositions").parent();
      var inputGroup = $(
        '    <div class="input-group container" style="width: 130px; padding-left: 0px; margin-left:5px; padding-right: 0px; margin-top:5px;">' +
          '      <span class="input-group-addon btn btn-default biosketchSearchPositions_open"><span class="glyphicon glyphicon-search"></span></span>' +
          "    </div>"
      );
      awesompleteElement.parent().append(inputGroup);
      inputGroup.prepend($("#biosketchSearchPositions"));
      inputGroup.prependTo(awesompleteElement);

      $(".biosketchSearchPositions_open").click(function () {
        $("#biosketchModal").modal({
          backdrop: "static",
          keyboard: false,
          show: true
        });
        $("#biosketchModalSearch").val("");
        biosketchModalData.sectionKey = "positions";
        biosketchModalData.index = null;
        refreshBiosketchModal();
        setTimeout(function () {
          setMaxHeight("#biosketchModal");
          $("#biosketchModalSearch").focus();
        }, 200);
      });

      $("#biosketchSearchPositions").parent().css("width", "100%");
      searchPositionsBox.container.addEventListener(
        "awesomplete-selectcomplete",
        function (element) {
          console.log(element.text);
          var item = element.text.value;

          var biosketch = biosketchData[overviewState.biosketchId];
          getKey(biosketch.positions, item.field, {})[
            item.i + (item.subI != null ? "+" + item.subI : "")
          ] = true;
          refreshSection();
        }
      );

      var searchHonorsBox = new Awesomplete($("#biosketchSearchHonors")[0], {
        list: biosketchSearchItems["honors"],
        minChars: 1,
        autoFirst: true,
        maxItems: 8,
        data: function (item) {
          return {
            label:
              item.text.length > 100
                ? item.text.slice(0, 100) + ".."
                : item.text,
            text: item.text,
            value: item
          };
        },
        replace: function (item) {
          return item.label;
        }
      });

      var awesompleteElement = $("#biosketchSearchHonors").parent();
      var inputGroup = $(
        '    <div class="input-group container" style="width: 130px; padding-left: 0px; margin-left:5px; padding-right: 0px; margin-top:5px;">' +
          '      <span class="input-group-addon btn btn-default biosketchSearchHonors_open"><span class="glyphicon glyphicon-search"></span></span>' +
          "    </div>"
      );
      awesompleteElement.parent().append(inputGroup);
      inputGroup.prepend($("#biosketchSearchHonors"));
      inputGroup.prependTo(awesompleteElement);

      $(".biosketchSearchHonors_open").click(function () {
        $("#biosketchModal").modal({
          backdrop: "static",
          keyboard: false,
          show: true
        });
        $("#biosketchModalSearch").val("");
        biosketchModalData.sectionKey = "honors";
        biosketchModalData.index = null;
        refreshBiosketchModal();
        setTimeout(function () {
          setMaxHeight("#biosketchModal");
          $("#biosketchModalSearch").focus();
        }, 200);
      });

      $("#biosketchSearchHonors").parent().css("width", "100%");
      searchHonorsBox.container.addEventListener(
        "awesomplete-selectcomplete",
        function (element) {
          console.log(element.text);
          var item = element.text.value;
          var biosketch = biosketchData[overviewState.biosketchId];
          getKey(biosketch.honors, item.field, {})[
            item.i + (item.subI != null ? "+" + item.subI : "")
          ] = true;
          refreshSection();
        }
      );

      $("#fieldList").append(
        "" +
          "<div>" +
          "<h4>C.	Contributions to Science" +
          '<span class="btn biosketchHistory" data-type="sciContribution" style="margin-left: 10px;" data-toggle="popover" data-placement="bottom" data-content="History"><span class="glyphicon glyphicon-time"></span></span>' +
          '<span class="btn"><span class="glyphicon glyphicon-info-sign autoTip" data-tip="' +
          encodeURIComponent(tips.sciContribution) +
          '" data-source="https://grants.nih.gov/grants/forms/biosketch.htm"></span></span>' +
          "</h4>" +
          biosketch.sciContribution
            .map(function (bSection, i) {
              return (
                "" +
                '<div id="biosketchSciContributionsText_' +
                i +
                '" style="min-height: 150px;border-radius: 5px;border: 1px solid #afafaf; padding: 5px; margin-bottom: 10px;">' +
                escapeHtml((bSection.text || "").split("\n").join("<br>"), {
                  allowTags: true
                }) +
                "</div>" +
                '<div><input id="biosketchSearchSciContributions_' +
                i +
                '" class="form-control" placeholder="Search to add"></div>' +
                '<table style="margin-top: 10px; margin-bottom: 10px;">' +
                makeBiosketchTable("sciContribution", newDef, {
                  index: i,
                  prefix: "index",
                  biosketch,
                  flipChron
                }) +
                "</table>"
              );
            })
            .join("") +
          '<div id="addSciContribution" class="btn btn-default" style="">Add Text</div>' + //)+
          "</div>"
      );

      $("#addSciContribution")
        .unbind("click")
        .click(function () {
          biosketch.sciContribution.push({ text: " ", set: {} });
          refreshSection();
        });

      biosketch.sciContribution.map(function (bSection, i) {
        tinymce.remove("#biosketchSciContributionsText_" + i);
        tinymce.init({
          selector: "#biosketchSciContributionsText_" + i,
          statusbar: false,
          inline: true,
          theme: "modern",
          menubar: false,
          elementpath: false,
          plugins: "paste link",
          paste_word_valid_elements: "b,strong,i,u,em,br,sup,sub,p",
          default_link_target: "_blank",
          target_list: false,
          link_title: false,
          formats: { underline: { inline: "u", exact: true } },
          paste_enable_default_filters: false,
          paste_preprocess: preprocessText(contextState),
          force_br_newlines: true,
          force_p_newlines: false,
          forced_root_block: "",
          entity_encoding: "raw",
          toolbar1:
            "bold italic underline subscript superscript | link unlink | removeformat",
          setup: function (editor) {
            var updateSciContributionsText = function (text) {
              var biosketch = biosketchData[overviewState.biosketchId];
              biosketch.sciContribution[i].text = unescapeHTML(text.trim(), {
                allowTags: true
              });
              if (biosketch.sciContribution[i].text.length > 0) checkSave();
              else refreshSection();
            };
            editor.on("input", function () {
              updateSciContributionsText(editor.getContent());
            });
            editor.on("change", function () {
              updateSciContributionsText(editor.getContent());
            });
          }
        });

        var searchSciContributionsBox = new Awesomplete(
          $("#biosketchSearchSciContributions_" + i)[0],
          {
            list: biosketchSearchItems["sciContribution"],
            minChars: 1,
            autoFirst: true,
            maxItems: 8,
            data: function (item) {
              return {
                label:
                  item.text.length > 100
                    ? item.text.slice(0, 100) + ".."
                    : item.text,
                text: item.text,
                value: item
              };
            },
            replace: function (item) {
              return item.label;
            }
          }
        );

        var awesompleteElement = $(
          "#biosketchSearchSciContributions_" + i
        ).parent();
        var inputGroup = $(
          '    <div class="input-group container" data-index="' +
            i +
            '" style="width: 130px; padding-left: 0px; margin-left:5px; padding-right: 0px; margin-top:5px;">' +
            '      <span class="input-group-addon btn btn-default biosketchSearchSciContributions_' +
            i +
            '_open"><span class="glyphicon glyphicon-search"></span></span>' +
            "    </div>"
        );
        awesompleteElement.parent().append(inputGroup);
        inputGroup.prepend($("#biosketchSearchSciContributions_" + i));
        inputGroup.prependTo(awesompleteElement);

        $(".biosketchSearchSciContributions_" + i + "_open").click(function () {
          $("#biosketchModal").modal({
            backdrop: "static",
            keyboard: false,
            show: true
          });
          $("#biosketchModalSearch").val("");
          biosketchModalData.sectionKey = "sciContribution";
          biosketchModalData.index = getProp(this, "index");
          refreshBiosketchModal();
          setTimeout(function () {
            setMaxHeight("#biosketchModal");
            $("#biosketchModalSearch").focus();
          }, 200);
        });

        $("#biosketchSearchSciContributions_" + i)
          .parent()
          .css("width", "100%");
        searchSciContributionsBox.container.addEventListener(
          "awesomplete-selectcomplete",
          function (element) {
            console.log(element.text);
            var item = element.text.value;
            var biosketch = biosketchData[overviewState.biosketchId];
            getKey(biosketch.sciContribution[i].set, item.field, {})[item.i] =
              true;
            refreshSection();
          }
        );
      });

      if (biosketch.version == "2026_fellow") {
        getKey(biosketch, "schPerf", [{}]);

        biosketch.schPerf = biosketch.schPerf.filter((row) => row != null);

        biosketch.schPerf = _.sortBy(biosketch.schPerf, (n) =>
          parseInt(n.year || "")
        );

        $("#fieldList").append(
          "" +
            "<div>" +
            "<h4>D.	Scholastic Performance" +
            '<span class="btn biosketchHistory" data-type="schPerf" style="margin-left: 10px;" data-toggle="popover" data-placement="bottom" data-content="History"><span class="glyphicon glyphicon-time"></span></span>' +
            '<span class="btn"><span class="glyphicon glyphicon-info-sign autoTip" data-tip="' +
            encodeURIComponent(tips.schPerf) +
            '" data-source="https://grants.nih.gov/grants/forms/biosketch.htm"></span></span>' +
            "</h4>" +
            '<table style="margin-top: 10px; margin-bottom: 10px;width: 100%;">' +
            '<tr><td style="width: 150px; font-weight: bold;">Year</td><td style="font-weight: bold;">Course Title</td><td style="font-weight: bold; width: 150px;">Grade</td><td style="width: 10px;"></td></tr>' +
            biosketch.schPerf
              .map(function (rowData, i) {
                return (
                  '<tr data-rowI="' +
                  i +
                  '">' +
                  '<td><input class="form-control schPerfData" data-key="year" value="' +
                  escapeHtml(rowData.year || "") +
                  '"/></td>' +
                  '<td><input class="form-control schPerfData" data-key="title" value="' +
                  escapeHtml(rowData.title || "") +
                  '"/></td>' +
                  '<td><input class="form-control schPerfData" data-key="grade" value="' +
                  escapeHtml(rowData.grade || "") +
                  '"/></td>' +
                  '<td><div class="btn icon-remove removeSchPerf" style="cursor: pointer;font-size: 16px;"></div></td>' +
                  "</tr>"
                );
              })
              .join("") +
            "</table>" +
            '<div id="addSchPerf" class="btn btn-default" style="">Add Row</div>' + //)+
            "</div>"
        );

        $(".schPerfData")
          .unbind("input")
          .on("input", function () {
            var biosketch = biosketchData[overviewState.biosketchId];
            var rowI = getProp(this, "rowI");
            var key = getProp(this, "key");
            biosketch.schPerf[rowI][key] = $(this).val();
            checkSave();
          });

        $(".removeSchPerf")
          .unbind("click")
          .on("click", function () {
            var biosketch = biosketchData[overviewState.biosketchId];
            var rowI = getProp(this, "rowI");
            biosketch.schPerf.splice(rowI, 1);
            refreshSection();
          });

        $("#addSchPerf")
          .unbind("click")
          .on("click", function () {
            var biosketch = biosketchData[overviewState.biosketchId];
            biosketch.schPerf.push({});
            refreshSection();
          });
      }

      $(".removeBiosketchItem")
        .unbind("click")
        .click(function () {
          var key = getProp(this, "key");
          var field = getProp(this, "field");
          var index = getProp(this, "index");
          var hash = getProp(this, "hash");
          var biosketch = biosketchData[overviewState.biosketchId];
          delete (index != null ? biosketch[key][index].set : biosketch[key])[
            field
          ][hash];
          refreshSection();
        });

      $(".biosketchHistory")
        .unbind("click")
        .click(function () {
          var type = getProp(this, "type");
          backend.send(
            "getHistoryByPath",
            {
              cvId: newDef.id,
              path: "biosketch." + overviewState.biosketchId + "." + type
            },
            function (recentChanges) {
              recentChanges = recentChanges.filter(function (n) {
                try {
                  var fromObj = JSON.parse(n.from);
                  var toObj = JSON.parse(n.to);
                  delete fromObj.id;
                  delete toObj.id;
                  return isDiff(fromObj, toObj);
                } catch (e) {}
                return true;
              });

              biosketchHistoryData.type = type;
              biosketchHistoryData.history = recentChanges;
              biosketchHistoryData.index = 0;
              console.log(biosketchHistoryData);
              refreshBiosketchHistoryModal();
              $("#biosketchHistoryModal").modal({
                backdrop: "static",
                keyboard: false,
                show: true
              });
            }
          );
        });
    }
  };
}
