import objectSupport from "dayjs/plugin/objectSupport.js";
import _ from "lodash";
import dayjs from "../../lib/dayjs.js";
dayjs.extend(objectSupport);

export const MAX_DATETIME = 8640000000000000;

var months = {
  Jan: 1,
  January: 1,
  Feb: 2,
  February: 2,
  March: 3,
  Mar: 3,
  April: 4,
  Apr: 4,
  May: 5,
  June: 6,
  Jun: 6,
  July: 7,
  Jul: 7,
  August: 8,
  Aug: 8,
  September: 9,
  Sept: 9,
  Sep: 9,
  October: 10,
  Oct: 10,
  November: 11,
  Nov: 11,
  December: 12,
  Dec: 12
};
export { months };

var numToMonths = {
  1: "Jan",
  2: "Feb",
  3: "Mar",
  4: "Apr",
  5: "May",
  6: "Jun",
  7: "Jul",
  8: "Aug",
  9: "Sep",
  10: "Oct",
  11: "Nov",
  12: "Dec"
};
export { numToMonths };

export const timeOverlap = function (aTime, bTime) {
  var aStart = getYears(aTime.start);
  var aEnd = aTime.end == null ? aStart : getYears(aTime.end, { end: true });
  var bStart = getYears(bTime.start);
  var bEnd = bTime.end == null ? bStart : getYears(bTime.end, { end: true });
  // aStart in between bStart-bEnd, aEnd in between bStart-bEnd or aStart-aEnd straddles bStart-bEnd
  return (
    (aStart >= bStart && aStart <= bEnd) ||
    (aEnd >= bStart && aEnd <= bEnd) ||
    (aStart <= bStart && aEnd >= bEnd)
  );
};

export const getYears = function (time, opts = {}) {
  if (time == "ongoing") return opts.limitOngoing ? Date.now() : MAX_DATETIME;
  if (!time?.year) return 0;

  let noMonth = time.month == null || !!opts.ignoreMonth;
  let noDay = noMonth || time.date == null || !!opts.ignoreDay;

  let returnValue;
  if (noMonth) {
    returnValue = (
      opts.end ? new Date(time.year + 1, 0, 0) : new Date(time.year, 0, 1)
    ).getTime();
  } else if (noDay) {
    returnValue = (
      opts.end
        ? new Date(time.year, time.month, 0)
        : new Date(time.year, time.month - 1, 1)
    ).getTime();
  } else {
    returnValue = new Date(time.year, time.month - 1, time.date).getTime();
  }

  if (noDay) returnValue--;
  if (noMonth) returnValue--;
  return returnValue;
};

export const timeScore = function (time, opts) {
  var score = 0;
  if (time != null) {
    if (time.start != null) score += 100 * getYears(time.start, opts);
    if (time.end != null && time.end != "ongoing")
      score += getYears(time.end, opts) / 1000;
  }
  return score;
};

function createPrintFriendlyDate(epoch) {
  let friendly_date = new Date(0);
  friendly_date.setUTCSeconds(epoch);
  return friendly_date;
}
export { createPrintFriendlyDate };

export const formatDate = function (date) {
  return (
    date.getMonth() + 1 + "/" + date.getDate() + "/" + (date.getYear() + 1900)
  );
};

export const lastTwo = function (num) {
  var text = "" + num;
  return text.slice(text.length - 2, text.length);
};

/**
 *
 * @param { {time: {start: { year: string, month:string, date:string},
 *                  end: "ongoing"|{ year: string, month:string, date:string} } } } item
 * @param { "yearRange" | "monthRange" | "limitedMonthRange" |
 *           "monthAndFullYear" | "limitedMonthDayYear" |
 *           "limitedMonthAndFullYear" | "mdy"} type
 * @param { { noComment: boolean,
 *            contextState?: { customExportSettings?: { ongoingExtended: boolean } } } } generateOpts
 * @returns string
 */
export const getDate = function (item, type = "yearRange", generateOpts = {}) {
  // yearRange, monthRange, monthAndFullYear, limitedMonthAndFullYear, limitedMonthRange

  if (item == null || item.time == null) return "";
  var result = "";
  /**
   *
   * @param { { year: string, month: string, date: string}} item
   * @returns nothing; modifies result
   */
  var add = function (item) {
    if (item == null || item.year == null) return;
    if (type == "yearRange") result += item.year;
    else if (type == "monthRange")
      result +=
        (item.month != null ? zeroPad(item.month, 2) + "/" : "") +
        (item.month != null ? lastTwo(item.year) : item.year);
    else if (type == "monthAndFullYear")
      result +=
        (item.month != null ? zeroPad(item.month, 2) + "/" : "") + item.year;
    else if (type == "limitedMonthDayYear")
      result += `${item.month != null ? `${item.month}/` : ""}${
        item.date != null ? `${item.date}/` : ""
      }${item.year}`;
    else if (type == "limitedMonthAndFullYear")
      result += (item.month != null ? item.month + "/" : "") + item.year;
    else if (type == "limitedMonthRange")
      result +=
        (item.month != null ? item.month + "/" : "") + lastTwo(item.year);
    else if (type == "mdy")
      result += `${item.month != null ? item.month : "1"}/${
        item.date != null ? item.date : "1"
      }/${item.year}`;
  };

  add(item.time.start);
  if (item.time.end != null) {
    result += "-";
    if (item.time.end == "ongoing") {
      var ongoingExtended =
        !!generateOpts?.contextState?.customExportSettings?.ongoingExtended;
      if (ongoingExtended) result += "present";
    } else if (item.time.end.year != null) {
      // && (generateOpts == null || generateOpts.type != 'partial' || getYears(item.time.end) <= getYears(generateOpts.end))) {
      add(item.time.end);
    }
  }

  if (
    !generateOpts?.noComment &&
    item["dateComment"] != null &&
    item["dateComment"].trim().length > 0
  ) {
    result += "\n" + item["dateComment"].trim();
  }

  return result;
};

export const isDate = function (bit) {
  return parseDate(bit)[0] != null;
};

var bundlePartList = function (partList) {
  var dateObj = {};
  if (partList.slice(-1)[0] == "-") {
    partList = partList.slice(0, -1);
    dateObj.start = true;
  }

  var slashesUsed = partList.indexOf("/") >= 0;
  if (slashesUsed) {
    // Every other must be '/', or else reject
    if (!partList.every((n, i) => i % 2 == 0 || n == "/")) {
      return null;
    }
    partList = partList.filter((n) => n != "/");
  }

  if (partList.length == 1) {
    // Y or M (incomplete)
    if (partList[0][0] == "Y") {
      dateObj.year = parseInt(partList[0][1]);
      return dateObj;
    } else if (partList[0][0] == "M" || partList[0][0] == "2D") {
      // Incomplete
      dateObj.month = parseInt(partList[0][1]);
      dateObj.incomplete = true;
      return dateObj;
    }
  } else if (partList.length == 2) {
    // M / Y or M / D (incomplete)
    if (
      (partList[0][0] == "M" || partList[0][0] == "2D") &&
      (partList[1][0] == "Y" ||
        (partList[1][0] == "2D" && partList[1][1].length == 2 && slashesUsed)) // Y in M/Y can only be 1-2D if it's 2 digits and uses a '/'
    ) {
      dateObj.month = parseInt(partList[0][1]);
      if (partList[1][0] == "2D") {
        dateObj.year =
          parseInt(partList[1][1]) +
          (parseInt(partList[1][1]) > 40 ? 1900 : 2000); // Expand 2D year to Year
      } else dateObj.year = parseInt(partList[1][1]);
      return dateObj;
    } else if (
      (partList[0][0] == "M" || partList[0][0] == "2D") &&
      partList[1][0] == "2D"
    ) {
      dateObj.month = parseInt(partList[0][1]);
      dateObj.date = parseInt(partList[1][1]);
      dateObj.incomplete = true;
      return dateObj;
    }
  } else if (partList.length == 3) {
    // M / D / Y
    if (
      (partList[0][0] == "M" || partList[0][0] == "2D") &&
      (partList[1][0] == "D" || partList[1][0] == "2D") &&
      (partList[2][0] == "Y" ||
        (partList[2][0] == "2D" && partList[2][1].length == 2 && slashesUsed)) //  Y in M/D/Y can only be 1-2D if it's 2 digits and uses a '/'
    ) {
      dateObj.month = parseInt(partList[0][1]);
      dateObj.date = parseInt(partList[1][1]);
      if (partList[2][0] == "2D") {
        dateObj.year =
          parseInt(partList[2][1]) +
          (parseInt(partList[2][1]) > 40 ? 1900 : 2000); // Expand 2D year to Year
      } else dateObj.year = parseInt(partList[2][1]);
      return dateObj;
    }
  }
};

export const parseDate = (text) => {
  text = text
    .split(/(?:–|‑| to )/)
    .join("-")
    .split(/ongoing|present/)
    .join("")
    .replace(/<[^\<]*>/g, "");
  var monthList = _.sortBy(Object.keys(months), (n) => -n.length);
  var rawDateReg = new RegExp(
    "^(?: |-|,|\\.|\\/|\\d{4}|\\d{1,2}|" +
      monthList.map((n) => n + "|" + n.toLowerCase()).join("|") +
      ")+"
  );
  var rawDate = (text.match(rawDateReg) || [])[0] || "";

  var datePartsReg = new RegExp(
    "(?:-|\\/|\\d{4}|\\d{1,2}|" +
      monthList.map((n) => n + "|" + n.toLowerCase()).join("|") +
      ")",
    "g"
  );
  var dateParts = [...rawDate.matchAll(datePartsReg)];

  let firstYear = 0;
  var parsed = dateParts.map((n, index) => {
    var part = n[0];
    var monthMatch = Object.keys(months).find(
      (m) => m.toLowerCase() == part.toLowerCase()
    );
    if (/\d{4}/.test(part)) {
      // Year
      if (index === 0) {
        firstYear = parseInt(part);
      }
      return ["Y", part];
    } else if (/\d{1,2}/.test(part)) {
      // 2D
      if (index === 2 && firstYear > 0) {
        const prefix = firstYear < 2000 && parseInt(part) > 40 ? "19" : "20";
        if (parseInt(prefix + part) >= firstYear) {
          return ["Y", prefix + part];
        }
      }
      return ["2D", part];
    } else if (monthMatch != null) {
      // Month
      return ["M", "" + months[monthMatch]];
    } else if (part == "-" || part == "/") {
      return part;
    } else {
      debugger;
    }
  });

  var grouped = [];
  var inProg = [];
  var secondProg = [];
  parsed.map((part) => {
    var group = bundlePartList(inProg.concat([part]));
    if (group != null) {
      inProg.push(part);
      if (secondProg.length > 0) secondProg.push(part);
    } else if (inProg.length > 0) {
      var bundle = bundlePartList(inProg);
      if (!bundle.incomplete) {
        if (secondProg.length > 0) {
          bundle.start = true;
          grouped = grouped.concat([bundle, bundlePartList(secondProg)]);
        } else grouped.push(bundle);
      } else if (
        inProg.length >= 2 &&
        inProg.slice(-1)[0] == "-" &&
        inProg.slice(-2)[0][0] == part[0]
      ) {
        // See if a mid-date range (M/D-D/Y or M-M/Y)
        secondProg = inProg.slice(0, -2).concat([part]);
        inProg = inProg.slice(0, -1);
        return;
      }
      inProg = [];
      secondProg = [];

      var nextGroup = bundlePartList(inProg.concat([part]));
      if (nextGroup != null) inProg.push(part);
    }
  });

  if (inProg.length > 0) {
    var bundle = bundlePartList(inProg);
    if (!bundle.incomplete) {
      if (secondProg.length > 0) {
        bundle.start = true;
        grouped = grouped.concat([bundle, bundlePartList(secondProg)]);
      } else grouped.push(bundle);
    }
  }

  var final = [];
  var heldStart = null;
  grouped.map((dateObj) => {
    if (dateObj.start) {
      heldStart = dateObj;
    } else if (heldStart != null) {
      delete heldStart.start;
      final.push({ start: heldStart, end: dateObj });
      heldStart = null;
    } else {
      final.push({ start: dateObj });
    }
  });
  if (heldStart != null) {
    delete heldStart.start;
    final.push({ start: heldStart, end: "ongoing" });
  }

  return final;
};

export const zeroPad = function (num, places) {
  var zero = places - num.toString().length + 1;
  return Array(+(zero > 0 && zero)).join("0") + num;
};

export const day = 1000 * 60 * 60 * 24;

/**
 *
 * @param { { start?: { date: string, month: string, year: string } | "ongoing",
 *            end?: { date: string, month: string, year: string } | "ongoing" } } time
 * @param { Date } startDate
 * @param { Date } endDate
 * @param { { ignoreEnd: boolean } } opts
 * @returns { boolean }
 */
// Typescript will help us here eventually, but for now:
// startDate and endDate MUST be JS date objects, not `{m: 1, d: 1, y: 2024}` format
export const isTimeWithinRange = function (time, startDate, endDate, opts) {
  opts = opts || {};

  if (!time?.start) {
    return false;
  }

  if (opts.ignoreEnd) {
    endDate = dayjs();
  }

  const itemStart = convertToDayjs(time.start);

  let dayjsStartDate = dayjs(startDate);
  let dayjsEndDate = dayjs(endDate);

  const granularity = time.start.date
    ? "day"
    : time.start.month
      ? "month"
      : "year";

  const startOverlaps = itemStart.isBetween(
    dayjsStartDate,
    dayjsEndDate,
    granularity,
    "[]"
  );

  if (!time.end || opts.ignoreEnd) {
    return startOverlaps;
  }

  const itemEnd =
    time.end === "ongoing"
      ? dayjs()
      : dayjs({
          year: time.end.year,
          // We store months 1-12, dayjs is 0 indexed
          month: (time.end.month || 1) - 1,
          date: (time.start.date || 1) - 1
        });

  // const startOverlaps = itemStart.isBetween(dayjs(startDate), dayjs(endDate), 'month', '[]');
  const endOverlaps = itemEnd.isBetween(
    dayjsStartDate,
    dayjsEndDate,
    time.end.month ? "month" : "year",
    "[]"
  );

  const spansPeriod =
    itemEnd.isAfter(dayjsEndDate, "month") &&
    itemStart.isBefore(dayjsStartDate, "month");
  return startOverlaps || endOverlaps || spansPeriod;
};

/**
 *
 * @param { Object[] } list
 * @param { Date } startDate
 * @param { Date } endDate
 * @param { { ignoreEnd: boolean } } opts
 * @returns { Object[] }
 */
// If start or end is within range, include
export const getItemsWithinDateRange = function (
  list,
  startDate,
  endDate,
  opts
) {
  opts = opts || {};
  if (!Array.isArray(list)) {
    // Backup list conversion from set. Try not to rely on this.
    list = _.values(list).filter(function (item) {
      return item != null;
    });
  }

  const itemsWithinDateRange = list.filter(function (item) {
    return isTimeWithinRange(item.time, startDate, endDate, opts);
  });
  // console.log('list: ', list, 'filtered: ', itemsWithinDateRange)
  return itemsWithinDateRange;
};

/**
 *
 * @param { Object } item
 * @returns { boolean }
 */
export const getIsPast = (item) => {
  var isPast = true;
  if (item.time != null) {
    if (
      item.time.end == "ongoing" ||
      (item.time.end != null &&
        getYears(item.time.end, { end: true }) > Date.now())
    ) {
      isPast = false;
    }
    if (
      item.time.end == null &&
      item.time.start != null &&
      item.time.start.year == new Date().getFullYear() &&
      (item.time.start.month == null ||
        item.time.start.month == new Date().getMonth() + 1)
    ) {
      isPast = false;
    }
  }
  return isPast;
};

/**
 *  Convert one of the { year: int, month: int, date: int } objects into a dayjs object.
 *  @param {{year: number, month: number, day?: number, date?: number}} dateObject - Object to convert
 */
export const convertToDayjs = (dateObject) => {
  return dayjs({
    year: dateObject.year || dayjs().year(),
    month: (dateObject.month || 1) - 1,
    day: dateObject.day ?? dateObject.date ?? 1
  });
};

export const getStringFromDateObject = (dateObject, format = "MM/DD/YYYY") => {
  if (dateObject == "" || !dateObject) return "";
  return convertToDayjs(dateObject.start).format(format);
};

export const getPeriodFromDateObject = (
  objectWithPeriod,
  ongoingText = "Ongoing"
) => {
  if (objectWithPeriod.time != undefined)
    objectWithPeriod = objectWithPeriod.time;
  let parsed = "";
  let format = "MM/DD/YYYY";
  if (objectWithPeriod.start) {
    if (
      objectWithPeriod.start.day == undefined &&
      objectWithPeriod.start.date == undefined
    ) {
      format = objectWithPeriod.start.month == undefined ? "YYYY" : "MM/YYYY";
    }
    parsed += convertToDayjs(objectWithPeriod.start).format(format);
  }
  if (objectWithPeriod.end && typeof objectWithPeriod.end !== "string") {
    if (
      objectWithPeriod.end.day == undefined &&
      objectWithPeriod.end.date == undefined
    ) {
      format = objectWithPeriod.end.month == undefined ? "YYYY" : "MM/YYYY";
    }
    parsed += " - " + convertToDayjs(objectWithPeriod.end).format(format);
  } else if (parsed.length) {
    if (typeof objectWithPeriod.end === "string") {
      if (objectWithPeriod.end.toLowerCase() === "ongoing") {
        parsed += " - " + ongoingText;
      }
    }
  }
  return parsed;
};

/**
 *
 * @param { { start?: { date: string, month: string, year: string } | "ongoing",
 *            end?: { date: string, month: string, year: string } | "ongoing" }} time
 * @returns { boolean }
 */
export function isTodayWithinRange(time) {
  if (time == undefined || time.start == undefined) return false;
  if (time?.end == "ongoing") {
    return true;
  }

  let today = {
    start: {
      year: dayjs().year(),
      month: dayjs().month() + 1,
      date: dayjs().date()
    }
  };

  // if the time doesn't have day or month; then make our 'today' match it
  // so we compare if in the same month or year
  if (!time.start?.date) {
    delete today.start.date;
    if (!time.start?.month) {
      delete today.start.month;
    }
  }

  if (time?.end == null) {
    // If no end date, then check if the same year for current
    delete today.start.date;
    delete today.start.month;
  }

  let start = convertToDayjs(time?.start);
  let end;
  if (time?.end) {
    end = convertToDayjs(time?.end);
  } else {
    end = convertToDayjs(time?.start);
  }
  return isTimeWithinRange(today, start, end);
}
