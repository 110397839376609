var ua = window.navigator.userAgent;
var isIE = /MSIE|Trident/.test(ua);

import { validEmail, capFirst, empty } from "../lib/utilities.js";
import {
  getProp,
  isStrongPass,
  getParameterByName,
  setMaxHeight
} from "../lib/frontendUtilities.js";
import { makeHierarchy, fullyDetailed } from "../lib/hierarchyUtilities.js";

$(function () {
  var type = "easycv";
  if (homeMessageText) {
    $("#homeMessage").show();
    document.getElementById("homeMessageText").innerHTML =
      _.unescape(homeMessageText);
  } else {
    $("#homeMessage").hide();
  }

  $("#ieWarning").toggle(isIE);

  var refreshType = function () {
    $(".loginType")
      .toggleClass("btn-primary", false)
      .toggleClass("btn-default", true);
    $('.loginType[data-type="' + type + '"]')
      .toggleClass("btn-primary", true)
      .toggleClass("btn-default", false);

    $("#passwordSection, #reset, #requestAccount").toggle(type == "easycv");
    $("#loginError").hide();
  };

  $(".loginType").click(function () {
    type = getProp(this, "type");
    refreshType();
    $(this).blur();
  });

  var getHash = function (txt) {
    return CryptoJS.MD5(txt + "_cv").toString();
  };

  var year = new Date().getYear() + 1900;
  $(".copyright").html(
    "© 2019-" + year + " EasyCV, LLC. All Rights Reserved."
  );

  $("#email").on("input", function () {
    var email = $("#email").val().toLowerCase();
    $("#reset").toggle(email.length > 0 && type == "easycv");
  });

  localStorage.removeItem("format");
  localStorage.removeItem("section");
  localStorage.removeItem("focusType");

  $("#login").click(function () {
    var email = $("#email").val().toLowerCase();

    if (type == "easycv") {
      var passHash = getHash($("#password").val());

      if (email.length != 0 && validEmail.test(email) && passHash.length != 0) {
        $.post(
          "./login",
          {
            passHash: passHash,
            email: email,
            type: type
          },
          function (response) {
            // Check password for strength. If not strong enough, redirect to /resetPassword?err=strength
            if (response.success) {
              var log = {
                category: isLocal ? "Test" : "Live",
                action: capFirst(response.user.type) + " Log In",
                label:
                  response.user.name +
                  " - " +
                  response.user.id +
                  (response.user.cvId != null ? " - " + response.user.cvId : "")
              };

              gtag("event", log.action, {
                event_category: log.category,
                event_label: log.label
              });

              var toParam = getParameterByName("to");
              var toUrl = decodeURIComponent(toParam);
              if (!isStrongPass($("#password").val())) {
                var redirectUrl =
                  "/resetPassword?err=strength" +
                  (toParam != null ? "&to=" + toParam : "");
              } else {
                var redirectUrl =
                  toParam != null &&
                  toUrl[0] == "/" &&
                  toUrl.indexOf("//") == -1 &&
                  toUrl.indexOf(":") == -1
                    ? toUrl
                    : "/";
              }
              window.location.href = redirectUrl;
            } else {
              $("#email")
                .parent()
                .toggleClass("has-error", response.error != null); //== 'Email Not Found');
              $("#password")
                .parent()
                .toggleClass("has-error", response.error != null); // 'Email Not Found');
              $("#loginError").show().text(response.error);
            }
          }
        );
      }
    } else {
      var domain = getEmailDomain(email);
      if (domain == null) {
        $("#loginError")
          .show()
          .text("No matching institution. Please use the EasyCV login.");
        return;
      }

      var toParam = getParameterByName("to");
      var toUrl = decodeURIComponent(toParam);
      if (
        toParam == null ||
        toUrl[0] != "/" ||
        toUrl.indexOf("//") != -1 ||
        toUrl.indexOf(":") != -1
      )
        toParam = "";

      var redirectUrl =
        "/login/" + domain + (toParam == "" ? "" : "?RelayState=" + toParam);
      console.log("redirecting to " + redirectUrl);
      window.location.href = redirectUrl;
    }
  });

  $(window).keyup(function (event) {
    if (event.keyCode === 13) {
      $("#login").click();
    }
  });

  $("#reset").click(function () {
    var email = $("#email").val().toLowerCase();

    $.post(
      "./reset",
      {
        email: email,
        type: type
      },
      function (response) {
        if (response.success) {
          $("#reset").text("Email Sent");
        } else if (response.err != null) {
          $("#reset").text(response.err);
        }
      }
    );
  });

  var newUserObj = {
    name: "",
    email: "",
    position: "faculty",
    requestedPaths: [""],
    paths: [""]
  };

  var refreshEditUser = function () {
    var userObj = newUserObj;

    $(".newUserPath").toggle(
      newUserObj.requestedPaths.some(function (path) {
        return fullyDetailed(path, newUserObj.position, hierarchy);
      })
    );

    $(".newUserPath")
      .unbind("click")
      .click(function () {
        if (newUserObj.requestedPaths.indexOf("") == -1)
          newUserObj.requestedPaths.push("");
        refreshEditUser();
      });

    var limitFilter = {};

    if (newUserObj.position == "student") {
      // Limit to just the med student hospital? Need a more scalable way to indicate this
      limitFilter = { h328: true };
      makeHierarchy(newUserObj, {
        container: $("#newStudentPaths"),
        pathKey: "requestedPaths",
        prefix: "newStudent",
        limitFilter: limitFilter,
        hierarchy,
        hierarchyNames,
        onChange: refreshEditUser,
        fullyDetailed: true,
        divisionDropdown: true,
        accessFilters: []
      });
    } else if (newUserObj.position == "app") {
      makeHierarchy(newUserObj, {
        container: $("#newAppPaths"),
        pathKey: "requestedPaths",
        prefix: "newApp",
        limitFilter: {},
        hierarchy,
        hierarchyNames,
        onChange: refreshEditUser,
        fullyDetailed: true,
        divisionDropdown: true,
        accessFilters: []
      });
    } else {
      makeHierarchy(newUserObj, {
        container: $("#newUserPaths"),
        pathKey: "requestedPaths",
        prefix: "newUser",
        limitFilter: limitFilter,
        hierarchy,
        hierarchyNames,
        onChange: refreshEditUser,
        fullyDetailed: true,
        divisionDropdown: true,
        accessFilters: []
      });
    }
  };

  $("#newStudentName, #newAppName").on("input", function () {
    newUserObj.name = $(this).val().trim();
  });

  $("#newStudentEmail, #newAppEmail").on("input", function () {
    var newEmail = $(this).val().toLowerCase().trim();
    newUserObj.email =
      newEmail.trim().length == 0 ||
      !validEmail.test(newEmail.trim()) ||
      newEmail.indexOf("@") == -1 ||
      newEmail.indexOf(",") >= 0
        ? ""
        : newEmail;
  });

  $("#newStudentBackupEmail, #newAppBackupEmail").on("input", function () {
    var newBackupEmail = $(this).val().toLowerCase().trim();
    newUserObj.backupEmail =
      newBackupEmail.trim().length == 0 ||
      !validEmail.test(newBackupEmail.trim()) ||
      newBackupEmail.indexOf("@") == -1 ||
      newBackupEmail.indexOf(",") >= 0
        ? ""
        : newBackupEmail;
  });

  $("#newUserName").on("input", function () {
    newUserObj.name = $(this).val().trim();
  });

  $("#newUserEmail").on("input", function () {
    var newEmail = $(this).val().toLowerCase().trim();
    newUserObj.email =
      newEmail.trim().length == 0 ||
      !validEmail.test(newEmail.trim()) ||
      newEmail.indexOf("@") == -1 ||
      newEmail.indexOf(",") >= 0
        ? ""
        : newEmail;
  });

  $("#newUserBackupEmail").on("input", function () {
    var newBackupEmail = $(this).val().toLowerCase().trim();
    newUserObj.backupEmail =
      newBackupEmail.trim().length == 0 ||
      !validEmail.test(newBackupEmail.trim()) ||
      newBackupEmail.indexOf("@") == -1 ||
      newBackupEmail.indexOf(",") >= 0
        ? ""
        : newBackupEmail;
  });

  $("#newUserPosition").on("change", function () {
    var newPosition = $(this).val();
    newUserObj.position = newPosition;
  });

  $(".userRequestType").click(function () {
    var type = getProp(this, "type");

    if (type == "user") {
      $("#userRequestUserInput").show();
      newUserObj.position = "faculty";
    } else if (type == "medStudent") {
      $("#userRequestMedStudentInput").show();
      newUserObj.position = "student";
    } else if (type == "app") {
      $("#userRequestAppInput").show();
      newUserObj.position = "app";
    } else if (type == "del") {
      $("#userRequestDelInput").show();
    } else if (type == "dept") {
      $("#userRequestDeptInput").show();
    }
    $("#userRequestScreen").hide();
    refreshEditUser();
  });

  $("#requestAccount").click(function () {
    newUserObj = {
      email: "",
      name: "",
      position: "faculty",
      requestedPaths: [""],
      paths: [""]
    };
    $("#newUserName").val("");
    $("#newUserEmail").val("");
    $("#newUserBackupEmail").val("");
    refreshEditUser();

    $("#userRequestScreen").show();
    $(
      "#userRequestUserInput, #userRequestMedStudentInput, #userRequestAppInput, #userRequestDelInput, #userRequestDeptInput, #userRequestFinal, #userRequestDuplicate"
    ).hide();

    $("#userRequestModal").modal({
      backdrop: "static",
      keyboard: false,
      show: true
    });
    setTimeout(function () {
      setMaxHeight("#userRequestModal");
    }, 200);
  });

  $(window).on("resize", function () {
    setMaxHeight("#userRequestModal");
  });

  $(".requestSubmit").click(function () {
    if (newUserObj.position == "student") {
      $("#newStudentName")
        .parent()
        .toggleClass("has-error", newUserObj.name.trim().length == 0);
      $("#newStudentEmail")
        .parent()
        .toggleClass(
          "has-error",
          newUserObj.email.trim().length == 0 ||
            !validEmail.test(newUserObj.email)
        );
      $("#newStudentBackupEmail")
        .parent()
        .toggleClass(
          "has-error",
          newUserObj.email.trim().length != 0 &&
            !validEmail.test(newUserObj.email)
        );
      $(".newStudentHospitalInput, .newStudentDeptInput")
        .parent()
        .toggleClass(
          "has-error",
          newUserObj.requestedPaths.some(function (path) {
            return !fullyDetailed(path, newUserObj.position, hierarchy);
          })
        );
      if (
        newUserObj.name.trim().length == 0 ||
        newUserObj.email.trim().length == 0 ||
        !validEmail.test(newUserObj.email) ||
        newUserObj.requestedPaths.some(function (path) {
          return !fullyDetailed(path, newUserObj.position, hierarchy);
        })
      )
        return;

      newUserObj.requestedPaths = newUserObj.requestedPaths.filter(
        function (n) {
          return n != "";
        }
      );

      $.post(
        "/request",
        {
          data: newUserObj
        },
        function (res) {
          $("#userRequestMedStudentInput").hide();
          if (res.reset != null) {
            $("#userRequestDuplicate").show();
          } else {
            $("#userRequestFinal").show();
          }
        }
      );
    } else if (newUserObj.position == "app") {
      $("#newAppName")
        .parent()
        .toggleClass("has-error", newUserObj.name.trim().length == 0);
      $("#newAppEmail")
        .parent()
        .toggleClass(
          "has-error",
          newUserObj.email.trim().length == 0 ||
            !validEmail.test(newUserObj.email)
        );
      $("#newAppBackupEmail")
        .parent()
        .toggleClass(
          "has-error",
          newUserObj.email.trim().length != 0 &&
            !validEmail.test(newUserObj.email)
        );
      $(".newAppHospitalInput, .newAppDeptInput")
        .parent()
        .toggleClass(
          "has-error",
          newUserObj.requestedPaths.some(function (path) {
            return !fullyDetailed(path, newUserObj.position, hierarchy);
          })
        );
      if (
        newUserObj.name.trim().length == 0 ||
        newUserObj.email.trim().length == 0 ||
        !validEmail.test(newUserObj.email) ||
        newUserObj.requestedPaths.some(function (path) {
          return !fullyDetailed(path, newUserObj.position, hierarchy);
        })
      )
        return;

      newUserObj.requestedPaths = newUserObj.requestedPaths.filter(
        function (n) {
          return n != "";
        }
      );

      $.post(
        "/request",
        {
          data: newUserObj
        },
        function (res) {
          $("#userRequestAppInput").hide();
          if (res.reset != null) {
            $("#userRequestDuplicate").show();
          } else {
            $("#userRequestFinal").show();
          }
        }
      );
    } else {
      $("#newUserName")
        .parent()
        .toggleClass("has-error", newUserObj.name.trim().length == 0);
      $("#newUserEmail")
        .parent()
        .toggleClass(
          "has-error",
          newUserObj.email.trim().length == 0 ||
            !validEmail.test(newUserObj.email)
        );
      $("#newUserBackupEmail")
        .parent()
        .toggleClass(
          "has-error",
          newUserObj.email.trim().length != 0 &&
            !validEmail.test(newUserObj.email)
        );
      $(".newUserHospitalInput, .newUserDeptInput")
        .parent()
        .toggleClass(
          "has-error",
          newUserObj.requestedPaths.some(function (path) {
            return !fullyDetailed(path, newUserObj.position, hierarchy);
          })
        );
      if (
        newUserObj.name.trim().length == 0 ||
        newUserObj.email.trim().length == 0 ||
        !validEmail.test(newUserObj.email) ||
        newUserObj.requestedPaths.some(function (path) {
          return !fullyDetailed(path, newUserObj.position, hierarchy);
        })
      )
        return;

      newUserObj.requestedPaths = newUserObj.requestedPaths.filter(
        function (n) {
          return n != "";
        }
      );

      $.post(
        "/request",
        {
          data: newUserObj
        },
        function (res) {
          $("#userRequestUserInput").hide();
          if (res.reset != null) {
            $("#userRequestDuplicate").show();
          } else {
            $("#userRequestFinal").show();
          }
        }
      );
    }
  });
});

var getEmailDomain = function (email) {
  if (!!/@easycv\.me$/.test(email)) return "onelogin";
  if (!!/stanford\.edu$/.test(email)) return "stanford";
  return null;
};
