import { getNewFieldId } from "../lib/cvDataUtilities.js";
import { formatDate, getDate } from "../lib/dateHelpers.js";
import { escapeHtml, getProp } from "../lib/frontendUtilities.js";
import { entrezSummary, makeCitation } from "../lib/pubMed.js";
import { getKey } from "../lib/utilities.js";

/** @param {string} [titleOverride="ACGME Survey"] - Title override for the tab, used by twiz */
export default function (getACGMEResult, titleOverride = "ACGME Survey") {
  return {
    field: "acgme",
    type: "custom",
    title: titleOverride,
    filter: ({ contextConstants }) =>
      ["faculty", "fellow", "resident"].indexOf(
        contextConstants.cvOwner.position
      ) >= 0,
    setup: function ({ contextMethods, contextConstants, contextState }) {
      var updateACGME_HTML = function () {
        let { refreshSection, updateLayout, checkSave } = contextMethods;
        let { cvOwner, formats } = contextConstants;
        let { section, sectionState, canEdit, newDef, viewAsState, format } =
          contextState;

        var overviewState = getKey(sectionState, section, {});
        var acgmeUpdated = newDef["acgmeUpdated"];

        $("#fieldList").empty();
        $("#fieldList").append(
          $(
            '<h4 style="display: inline-block; margin-top: 5px;vertical-align: top;">Last Updated: ' +
              (acgmeUpdated == null
                ? "Never"
                : formatDate(new Date(acgmeUpdated))) +
              "</h4>" +
              '<div class="btn btn-default acgmeMarkComplete" style="display: inline-block; margin-left: 10px;"><span class="glyphicon glyphicon-ok" style="margin-right: 5px; color: green;"></span>Mark All As Updated</div><br>' +
              '<div id="acgmeSection"></div>'
          )
        );

        if (overviewState.backYears == null) overviewState.backYears = 0;

        var year = new Date().getYear() + 1900;
        var transitionDate = new Date("9/30/" + year);
        var pastDateShift =
          (Date.now() > transitionDate.getTime() ? 0 : 1) +
          overviewState.backYears;
        var startDate = new Date("7/1/" + (year - pastDateShift)).getTime();
        var endDate = new Date("6/30/" + (year + 1 - pastDateShift)).getTime();

        var ACGME_result = getACGMEResult(newDef, startDate, endDate);
        console.log("ACGME Result", ACGME_result);

        var dateRange =
          new Date(startDate).getYear() +
          1900 +
          "-" +
          (new Date(endDate).getYear() + 1900);
        getKey(newDef, "acgmeEdits", {});

        delete newDef.acgmeEdits.dateRange;
        delete newDef.acgmeEdits.selectOverrides;
        delete newDef.acgmeEdits.additionalCounts;

        var acgmeEdits = getKey(newDef.acgmeEdits, dateRange, {
          dateRange: dateRange,
          selectOverrides: {},
          additionalCounts: {}
        });

        refreshSection({ noRefresh: true });

        var getTitle = function (item) {
          return (
            "- " +
            escapeHtml(item.title || item.line || item.journal || "Untitled", {
              allowTags: true
            }) +
            " (" +
            getDate(item, "monthAndFullYear") +
            ")"
          );
        };
        var getAcgmeSection = function (acgmeEdits, opts) {
          opts = opts || {};

          var hasChoice = opts.list == null || opts.list.length == 0;

          // If list has stuff, it has to be positive. If not, let override prevail
          var selection = !hasChoice
            ? "Yes"
            : acgmeEdits.selectOverrides[opts.title]
              ? "Yes"
              : "No";
          var additionalCount =
            acgmeEdits.additionalCounts[opts.title] != null
              ? acgmeEdits.additionalCounts[opts.title]
              : 0;

          var sectionNames = (opts.sections || [])
            .map(function (section) {
              var inputFormat = formats[format].input || [];
              var sectionFormat = inputFormat.find(function (sectionFormat) {
                return (
                  (sectionFormat.section || sectionFormat.field) == section
                );
              });
              if (sectionFormat == null) return null;
              return (
                '<u class="sectionTab" data-section="' +
                section +
                '">' +
                sectionFormat.title +
                "</u>"
              );
            })
            .filter(function (n) {
              return n != null;
            });

          return (
            '<div class="section" data-dateRange="' +
            opts.dateRange +
            '" data-title="' +
            opts.title +
            '" style="display: block;margin-top: 15px;">' +
            '<h5 style="display: block;margin-bottom: 5px;">' +
            '<span class="toggle_ACGME_list toggle_ACGME_list_icon glyphicon glyphicon-triangle-right" style="width: 20px;' +
            (!hasChoice ? "color: #288e28;" : "") +
            '"></span>' +
            '<span class="toggle_ACGME_list" style="margin-bottom: 5px; display: inline-block;"> ' +
            opts.title +
            ": </span>" +
            (opts.count != null
              ? '<span style="margin-left: 5px;" class="count">' +
                (opts.list.length + additionalCount) +
                "</span>" +
                '<span class="toggle_ACGME_list glyphicon glyphicon-pencil" style="margin-left: 10px;"></span>'
              : '<div class="btn-group" role="group" style="margin-left: 5px;">' +
                '<button type="button" data-value="Yes" class="' +
                (hasChoice && canEdit ? "acgmeSelect " : "") +
                (canEdit ? "" : "disabled") +
                " btn btn-" +
                (selection == "Yes"
                  ? hasChoice
                    ? "primary"
                    : "success"
                  : "default") +
                ' btn-sm">Yes</button>' +
                '<button type="button" data-value="No" class="' +
                (hasChoice && canEdit ? "acgmeSelect " : "") +
                (canEdit ? "" : "disabled") +
                " btn btn-" +
                (selection == "No" ? "primary" : "default") +
                " btn-sm " +
                (!hasChoice ? "disabled" : "") +
                '">No</button>' +
                "</div>") +
            (hasChoice
              ? ""
              : '<span class="toggle_ACGME_list" style="margin-left:10px; color: #288e28;">Auto Populated</span>') +
            "</h5>" +
            '<h5 class="toggle_ACGME_list" style="margin-left: 23px;font-weight: normal;margin-top: 2px;display: block;">' +
            opts.description +
            "</h5>" +
            '<div class="ACGME_list" data-baseCount="' +
            (opts.list || []).length +
            '" style="display: none; margin-left: 20px;">' +
            '<span style="display: block;font-style: italic;margin-bottom: 5px;">' +
            (opts.list != null && sectionNames.length > 0
              ? "Found in the " +
                sectionNames.join(", and ") +
                " section" +
                (sectionNames.length > 1 ? "s" : "") +
                ":"
              : "Unable to be extracted") +
            "</span>" +
            (opts.list != null ? opts.list.map(getTitle).join("<br>") : "") +
            (opts.count != null
              ? '<h5 style="display: block;">Additional Count: <input ' +
                (canEdit ? "" : "readonly") +
                ' class="form-control acgmeAdditionalCount" style="width: 50px; height: 25px;" value="' +
                additionalCount +
                '"></input></h5>'
              : "") +
            "</div>" +
            "</div>"
          );
        };

        if (cvOwner.position == "faculty") {
          var ACGME_HTML =
            "" +
            "<div>" +
            '<h5 style="display: inline-block; margin-bottom: 10px; margin-top: 15px;">' +
            '  <div class="btn btn-sm btn-default acgmePrev" style="margin-right: 5px;">' +
            '    <span class="glyphicon glyphicon-chevron-left"></span>' +
            "  </div>" +
            '  <span style="margin-right: 5px;">' +
            formatDate(new Date(startDate)) +
            " - " +
            formatDate(new Date(endDate)) +
            "</span>" +
            '  <div class="btn btn-sm btn-default acgmeNext">' +
            '    <span class="glyphicon glyphicon-chevron-right"></span>' +
            "  </div>" +
            "</h5>" +
            "<br>" +
            '<h5 style="display: inline-block; margin-bottom: 15px;">' +
            ACGME_result.endResult.PMIDs.slice(0, 4).length +
            "/4 PMIDs: " +
            ACGME_result.endResult.PMIDs.slice(0, 4).join(", ") +
            "</h5>" +
            '<span style="display: inline-block; font-style: italic; margin-bottom: 5px; margin-left: 5px;">(from <u class="sectionTab" data-section="scholarship">Scholarship</u>)</span>' +
            (!canEdit
              ? ""
              : '<div style="margin-top:7px;"><h5 style="display:inline-block; vertical-align:top; margin-top:7px;margin-right: 7px;">Add PMID: </h5><input id="addPMID_input" class="form-control" placeholder="PMID" style="width:100px; height: 29px; vertical-align:top;margin-right: 5px;">' +
                '<div class="btn-sm btn btn-default" id="addPMID_ACGME"><span class="glyphicon glyphicon-plus"></span></div>' +
                "</div>") +
            getAcgmeSection(acgmeEdits, {
              dateRange: dateRange,
              count: true,
              title: "Non-PMID Peer Review Publications",
              list: ACGME_result.nonPMIDs,
              sections: ["scholarship"],
              description:
                "Number of peer-reviewed publications without a PMID, which are not recognized by the National Library of Medicine."
            }) +
            getAcgmeSection(acgmeEdits, {
              dateRange: dateRange,
              count: true,
              title: "Other Publications",
              list: ACGME_result.otherPubs,
              sections: ["scholarship"],
              description:
                "Number of other articles/publications without PMIDs and are not peer-reviewed. Examples include editorials, online magazines, or activities related to item-writing (eg. board examination questions) during the previous academic year."
            }) +
            getAcgmeSection(acgmeEdits, {
              dateRange: dateRange,
              count: true,
              title: "Conference Presentations",
              list: ACGME_result.abstractsAndPresentations,
              sections: ["presentations", "scholarship"],
              description:
                "Abstracts, posters, and presentations at international, national, state, or regional meetings during the previous academic year."
            }) +
            getAcgmeSection(acgmeEdits, {
              dateRange: dateRange,
              count: true,
              title: "Other Presentations",
              list: ACGME_result.otherPresentations,
              sections: ["presentations", "scholarship"],
              description:
                "Other presentations (grand rounds, invited professorships), materials developed (such as computer-based modules), or work presented in non-peer review publications during the previous academic year."
            }) +
            getAcgmeSection(acgmeEdits, {
              dateRange: dateRange,
              count: true,
              title: "Chapters / Textbooks",
              list: ACGME_result.chaptersOrTextbooks,
              sections: ["scholarship"],
              description:
                "Chapters or textbooks published during the previous academic year."
            }) +
            getAcgmeSection(acgmeEdits, {
              dateRange: dateRange,
              count: true,
              title: "Grant Leadership",
              list: ACGME_result.grantsLed,
              sections: ["projects"],
              description:
                "Grants for which faculty member had a leadership role (PI, Co-PI, or site director) during the previous academic year."
            }) +
            '<h4 style="margin-top: 30px; margin-bottom: 0px;">Involved In:</h4>' +
            getAcgmeSection(acgmeEdits, {
              dateRange: dateRange,
              title: "Leadership or Peer-Review Role",
              list: ACGME_result.leadership,
              sections: [
                "committeeService",
                "adminService",
                "editorialActivities",
                "publicPolicy"
              ],
              description:
                "Active leadership role (such as serving on committees or governing boards) in international, national, state, or regional medical organizations or served as reviewer or editorial board member for a peer-reviewed journal during the previous academic year."
            }) +
            getAcgmeSection(acgmeEdits, {
              dateRange: dateRange,
              title: "Formal Courses",
              description:
                "Responsible for seminars, conference series, or course coordination (such as arrangement of presentations and speakers, organization of materials) during the previous academic year. This includes developing training modules for medical students, residents, fellows and other health professionals (eg. simulation). Program didactics and/or conferences are not considered formal courses."
            }) +
            getAcgmeSection(acgmeEdits, {
              dateRange: dateRange,
              title: "Research",
              list: ACGME_result.involvement.research,
              sections: ["scholarship"],
              description:
                "Research in basic science, translational science, patient care, or population health"
            }) +
            getAcgmeSection(acgmeEdits, {
              dateRange: dateRange,
              title: "Grants",
              list: ACGME_result.involvement.grants,
              sections: ["projects"],
              description: "Peer-reviewed grants"
            }) +
            getAcgmeSection(acgmeEdits, {
              dateRange: dateRange,
              title: "Quality",
              description:
                "Quality Improvement and/or patient safety initiatives"
            }) +
            getAcgmeSection(acgmeEdits, {
              dateRange: dateRange,
              title: "Reviews",
              list: ACGME_result.involvement.reviews,
              sections: ["scholarship"],
              description:
                "Systematic reviews, meta-analysis, review articles, chapters in medical textbooks, or case reports"
            }) +
            getAcgmeSection(acgmeEdits, {
              dateRange: dateRange,
              title: "Curricula",
              description:
                "Creation of curricula, evaluation tools, didactic educational activities, or electronic educational materials"
            }) +
            getAcgmeSection(acgmeEdits, {
              dateRange: dateRange,
              title: "Committees",
              list: ACGME_result.involvement.committees,
              sections: ["committeeService", "adminService"],
              description:
                "Contribution to professional committees, educational organizations, or editorial boards"
            }) +
            getAcgmeSection(acgmeEdits, {
              dateRange: dateRange,
              title: "Innovations",
              list: ACGME_result.involvement.innovations,
              sections: [
                "teachingAndEducationInnovations",
                "technologyAndScienceInnovations",
                "clinicalActivityAndInnovations"
              ],
              description: "Innovations in education"
            }) +
            '<div class="btn btn-default acgmeMarkComplete" style="display: inline-block;margin-left: 10px;margin-top: 10px;">' +
            '<span class="glyphicon glyphicon-ok" style="margin-right: 5px; color: green;"></span>Mark All As Updated' +
            "</div>" +
            "</div>";
        } else if (
          cvOwner.position == "resident" ||
          cvOwner.position == "fellow"
        ) {
          var ACGME_HTML =
            "" +
            "<div>" +
            '<h5 style="display: inline-block; margin-bottom: 10px; margin-top: 15px;">' +
            '  <div class="btn btn-sm btn-default acgmePrev" style="margin-right: 5px;">' +
            '    <span class="glyphicon glyphicon-chevron-left"></span>' +
            "  </div>" +
            '  <span style="margin-right: 5px;">' +
            formatDate(new Date(startDate)) +
            " - " +
            formatDate(new Date(endDate)) +
            "</span>" +
            '  <div class="btn btn-sm btn-default acgmeNext">' +
            '    <span class="glyphicon glyphicon-chevron-right"></span>' +
            "  </div>" +
            "</h5>" +
            "<br>" +
            '<h5 style="display: inline-block; margin-bottom: 15px;">' +
            ACGME_result.endResult.PMIDs.slice(0, 3).length +
            "/3 PMIDs: " +
            ACGME_result.endResult.PMIDs.slice(0, 3).join(", ") +
            "</h5>" +
            '<span style="display: inline-block; font-style: italic; margin-bottom: 5px; margin-left: 5px;">(from <u class="sectionTab" data-section="scholarship">Scholarship</u>)</span>' +
            '<div style="margin-top:7px;"><h5 style="display:inline-block; vertical-align:top; margin-top:7px;margin-right: 7px;">Add PMID: </h5><input id="addPMID_input" class="form-control" placeholder="PMID" style="width:100px; height: 29px; vertical-align:top;margin-right: 5px;"><div class="btn-sm btn btn-default" id="addPMID_ACGME"><span class="glyphicon glyphicon-plus"></span></div></div>' +
            getAcgmeSection(acgmeEdits, {
              dateRange: dateRange,
              title: "Other Publications",
              count: true,
              list: ACGME_result.otherPubs,
              sections: ["scholarship"],
              description:
                "Articles without PMIDs, non-peer reviewed publications, peer-reviewed publications which are not recognized by the National Library of Medicine, and activities related to item-writing during the previous academic year"
            }) +
            getAcgmeSection(acgmeEdits, {
              dateRange: dateRange,
              title: "Conference Presentations",
              count: true,
              list: ACGME_result.abstractsAndPresentations,
              sections: ["presentations", "scholarship"],
              description:
                "Abstracts, posters, and presentations at international, national, state, or regional meetings during the previous academic year."
            }) +
            getAcgmeSection(acgmeEdits, {
              dateRange: dateRange,
              title: "Chapters / Textbooks",
              count: true,
              list: ACGME_result.chaptersOrTextbooks,
              sections: ["scholarship"],
              description:
                "Chapters or textbooks published during the previous academic year."
            }) +
            '<h4 style="margin-top: 30px; margin-bottom: 0px;">Involved In:</h4>' +
            getAcgmeSection(acgmeEdits, {
              dateRange: dateRange,
              title: "Participated in Research",
              list: ACGME_result.involvement.research,
              sections: ["scholarship"],
              description:
                "Participated in funded or non-funded basic science or clinical outcomes research project during the previous academic year."
            }) +
            getAcgmeSection(acgmeEdits, {
              dateRange: dateRange,
              title: "Teaching / Presentations",
              list: ACGME_result.involvement.teaching,
              sections: ["teaching", "presentations"],
              description:
                "Lecture, or presentation (such as grand rounds or case presentations) of at least 30 minute duration within the sponsoring institution or program during the previous academic year."
            }) +
            '<div class="btn btn-default acgmeMarkComplete" style="display: inline-block;margin-left: 10px;margin-top: 10px;">' +
            '<span class="glyphicon glyphicon-ok" style="margin-right: 5px; color: green;"></span>Mark All As Updated' +
            "</div>" +
            "</div>";
        }

        $("#acgmeSection").html(ACGME_HTML);

        $("#addPMID_ACGME").parent().toggle(canEdit);
        $("#addPMID_ACGME").on("click", function () {
          var id = $("#addPMID_input").val();
          $.getJSON(
            "/PMproxy?path=" +
              encodeURIComponent(entrezSummary + "&id=" + id + ""),
            function (data) {
              $("#addPMID_input").val("");
              if (
                data.result == null ||
                data.result[id] == null ||
                data.result[id].uid != id
              ) {
                alert("PMID not found");
                return;
              }
              var date = new Date(data.result[id].pubdate);
              var citation = makeCitation(data.result[id], null);

              var newId = getNewFieldId("scholarship", newDef);
              newDef.lists["scholarship"][newId] = {
                type: "Research Investigations",
                line: citation,
                PMID: id,
                created: Date.now(),
                time: {
                  start: {
                    year: date.getYear() + 1900,
                    month: date.getMonth() + 1
                  }
                }
              };
              refreshSection();
            }
          );
        });

        $(".acgmeMarkComplete")
          .toggle(canEdit)
          .unbind("click")
          .click(function () {
            newDef["acgmeUpdated"] = Date.now();
            updateACGME_HTML();
            refreshSection();
          });

        $(".acgmeNext")
          .unbind("click")
          .click(function () {
            overviewState.backYears--;
            updateACGME_HTML();
          });

        $(".acgmePrev")
          .unbind("click")
          .click(function () {
            overviewState.backYears++;
            updateACGME_HTML();
          });

        $(".acgmeSelect")
          .unbind("click")
          .click(function () {
            var title = getProp(this, "title");
            var dateRange = getProp(this, "dateRange");
            var value = getProp(this, "value");
            var currentValue = newDef.acgmeEdits[dateRange].selectOverrides[
              title
            ]
              ? "Yes"
              : "No";
            if (
              (currentValue == value && currentValue == "Yes") ||
              value == "No"
            ) {
              delete newDef.acgmeEdits[dateRange].selectOverrides[title];
            } else {
              newDef.acgmeEdits[dateRange].selectOverrides[title] = true;
            }

            refreshSection();
          });

        $(".acgmeAdditionalCount")
          .unbind("input")
          .on("input", function () {
            var title = getProp(this, "title");
            var dateRange = getProp(this, "dateRange");
            var baseCount = parseInt(getProp(this, "baseCount"));
            var count = parseInt($(this).val());
            if (isNaN(count)) return;

            $(this)
              .closest(".section")
              .find(".count")
              .text(count + baseCount);
            newDef.acgmeEdits[dateRange].additionalCounts[title] = count;
            checkSave();
          });

        $(".toggle_ACGME_list")
          .unbind("click")
          .click(function () {
            var list = $(this).closest(".section").find(".ACGME_list");
            if (list.length == 0) return;
            list.toggle(!list.is(":visible"));
            $(this)
              .closest(".section")
              .find(".toggle_ACGME_list_icon")
              .toggleClass("glyphicon-triangle-right", !list.is(":visible"))
              .toggleClass("glyphicon-triangle-bottom", list.is(":visible"));
          });

        updateLayout();
      };

      updateACGME_HTML();
    },
    checkErrors: function (newDef, sectionState) {
      var acgmeUpdated = newDef["acgmeUpdated"];

      var overviewState = getKey(sectionState, "acgme", {});
      if (overviewState.backYears == null) overviewState.backYears = 0;

      var year = new Date().getYear() + 1900;
      var transitionDate = new Date("9/30/" + year);
      var pastDateShift =
        (Date.now() > transitionDate.getTime() ? 0 : 1) +
        overviewState.backYears;
      var startDate = new Date("7/1/" + (year - pastDateShift)).getTime();

      return acgmeUpdated == null || acgmeUpdated < startDate;
    }
  };
}
