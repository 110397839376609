import _ from "lodash";
import { escapeHtml, getProp } from "./frontendUtilities.js";
import { clone, empty, getKey, keys, numKeys } from "./utilities.js";

/**
 *
 * @param { string[] } paths
 * @param { import("@easycv/common/types/util").Hierarchy } hierarchy
 * @param { import("@easycv/common/types/util").HierarchyNames } hierarchyNames
 * @returns { string }
 */
var pathsToText = function (paths, hierarchy, hierarchyNames) {
  var groupedPaths = {};
  paths.map(function (path) {
    path = getPath(breakPath(path, hierarchy, hierarchyNames), hierarchy); // Filter out bad paths
    var rootPath = path.split("/").slice(0, 2).join("/");
    // Verify that this path really exists on this root
    getKey(groupedPaths, rootPath, { root: rootPath, paths: [] }).paths.push(
      path
    );
  });

  return _.values(groupedPaths)
    .filter(function (pathObj) {
      var badRoot =
        getPath(
          breakPath(pathObj.root, hierarchy, hierarchyNames),
          hierarchy
        ) != pathObj.root;
      return !badRoot;
    })
    .map(function (pathObj) {
      var divisions = [];
      pathObj.paths.map(function (subPath) {
        var splitSubPath = subPath.split("/");
        if (splitSubPath.length == 3) divisions.push(splitSubPath[2]);
      });

      return pathObj.root == ""
        ? "All"
        : pathObj.root
            .split("/")
            .map(function (n) {
              return hierarchyNames[n];
            })
            .join(" / ") +
            (divisions.length == 0
              ? ""
              : " / " +
                divisions
                  .map(function (n) {
                    return hierarchyNames[n];
                  })
                  .join(", "));
    })
    .join(" and ");
};
export { pathsToText };

/**
 *
 * @param { string[] } paths
 * @param { import("@easycv/common/types/util").Hierarchy } hierarchy
 * @param { import("@easycv/common/types/util").HierarchyNames } hierarchyNames
 * @returns { { root: string,
 *              department: string,
 *              divisions: string[] }[] }
 */
export function getPathsBreakdown(paths, hierarchy, hierarchyNames) {
  if (!Array.isArray(paths)) paths = [paths];
  const grouped = {};
  paths.map((path) => {
    path = getPath(breakPath(path, hierarchy, hierarchyNames), hierarchy);
    const root = path.split("/").splice(0, 2);
    (grouped[root] ??= { root: root, paths: [] }).paths.push(path);
  });
  return Object.values(grouped).map((obj) => {
    const divisions = [];
    obj.paths.map((subPath) => {
      const split = subPath.split("/");
      if (split.length == 3) divisions.push(split[2]);
    });
    const [root, department] = obj.root;
    return {
      root: hierarchyNames[root],
      department: hierarchyNames[department],
      divisions: divisions.map((div) => hierarchyNames[div])
    };
  });
}

/**
 *
 * @param {*} path
 * @param { "nonclinical" | "student" | "resident" | "postdoc" | "faculty" | "fellow" | "app" } position
 * @param { import("@easycv/common/types/util").Hierarchy } hierarchy
 *
 * @returns
 */
let fullyDetailed = function (path, position, hierarchy) {
  if (["nonclinical", "student", "resident"].includes(position)) return true;
  if (path.trim().length == 0) return false;
  let parts = path.split("/");

  if (hierarchy[parts[0]] == null) return false;
  else {
    let deptSet = hierarchy[parts[0]].depts;
    if (empty(deptSet)) return true;
    if (_.isUndefined(parts[1])) return false;

    let divSet = deptSet[parts[1]];
    // Division not required for students or residents
    if (["student", "resident"].includes(position) || empty(divSet))
      return true;
    if (_.isUndefined(parts[2])) return false;

    return !_.isUndefined(divSet[parts[2]]);
  }
};
export { fullyDetailed };

/**
 *
 * @param { import("@easycv/common/types/user.js").User } user
 * @param { import("@easycv/common/types/user.js").User} cvOwner
 * @param { import("@easycv/common/types/conference").Conference } conference
 * @returns
 */
export function canUserViewConference(user, cvOwner, conference) {
  // Firewall BWH Anesthesia career conference data from Gavin
  if (user.email === "gavin@easycv.me" && matchesFilter("h1/h26")(conference)) {
    return false;
  }

  let overlapsUser = anyPathOverlap(conference.paths, cvOwner.paths);
  let isConferenceMentor =
    (conference.mentors[user.id] || {})[cvOwner.id] != null;
  let hasAdminConfTabAccess =
    ["readOnly", "editAccess"].includes(user.settings.conferenceTabAccess) &&
    anyPathWithin({
      filterList: user.settings.accessFilters,
      targetList: conference.paths
    });

  let hasMentor = false;
  for (let mentorId of Object.keys(conference.mentors)) {
    let menteeIdList = conference.mentors[mentorId];
    for (let menteeId of Object.keys(menteeIdList)) {
      if (menteeIdList[menteeId] && menteeId == cvOwner.id) hasMentor = true;
    }
  }

  return (
    overlapsUser &&
    hasMentor &&
    (user.id === cvOwner.id || isConferenceMentor || hasAdminConfTabAccess)
  );
}

export const positionOptions = [
  { key: "faculty", name: "Faculty", degree: "md" },
  { key: "fellow", name: "Fellow", degree: "md" },
  { key: "resident", name: "Resident", degree: "md" },
  { key: "app", name: "Advanced Practice Provider", degree: "app" },
  { key: "student", name: "Student", degree: "md" },
  { key: "nonclinical", name: "Non Clinical", degree: "other" }
];

/**
 *
 * @param { { [key:string]: string | string[] }} obj
 * @param { { pathKey?: string, // key for the path property on "obj" parameter
 *            divisionDropdown?: boolean,
 *            container: JQuery<HTMLElement>,
 *            user?: import("@easycv/common/types/user.js").User,
 *            prefix?: string,
 *            limitFilter?: {},
 *            removeAny?: boolean,
 *            fullyDetailed?: boolean,
 *            accessFilters?: string[],
 *            onChange?: () => void,
 *            hierarchy: import("@easycv/common/types/util").Hierarchy,
 *            hierarchyNames: import("@easycv/common/types/util").HierarchyNames,
 *            noAllHospitals?: boolean,
 *             }} opts
 */
var makeHierarchy = function (obj, opts) {
  var container = opts.container;
  var prefix = opts.prefix || "";
  var limitFilter = opts.limitFilter || {};
  var onChange = opts.onChange || function () {};
  var hierarchy = opts.hierarchy;
  var hierarchyNames = opts.hierarchyNames;

  container.empty();
  var pathsChanged = false;
  var drawing = true;

  var pathOptions = obj[opts.pathKey ?? "paths"] || [];

  if (opts.divisionDropdown) {
    // Group things by hos+dept into array of arrays.
    var groupedPaths = {};
    pathOptions.map(function (path) {
      path = getPath(breakPath(path, hierarchy, hierarchyNames), hierarchy); // Filter out bad paths
      var rootPath = path.split("/").slice(0, 2).join("/");
      // Verify that this path really exists on this root
      getKey(groupedPaths, rootPath, { root: rootPath, paths: [] }).paths.push(
        path
      );
    });
    pathOptions = _.values(groupedPaths);
  }

  pathOptions.map(function (rawPath, index) {
    var path = rawPath.root != null ? rawPath.root : rawPath;
    if (path == "") opts.noAllHospitals = false; // If set to all, then have to be able to see all

    var splitPath = path.split("/");
    if (
      typeof opts.user != "undefined" &&
      obj.id != opts.user.id &&
      splitPath[0] != "" &&
      !empty(limitFilter) &&
      (limitFilter[splitPath[0]] == null ||
        (splitPath[1] != null &&
          !empty(limitFilter[splitPath[0]]) &&
          limitFilter[splitPath[0]][splitPath[1]] == null) ||
        (splitPath[1] != null &&
          splitPath[2] != null &&
          !empty(limitFilter[splitPath[0]]?.[splitPath[1]]) &&
          limitFilter[splitPath[0]][splitPath[1]][splitPath[2]] == null))
    ) {
      // If every filter is out of your scope, don't allow editing (unless it's your own)
      container.append(
        $(
          '<h5 style="margin-top: 15px;color: #7b7b7b;margin-left: 4px;font-size: 16px;">' +
            (path == ""
              ? "All"
              : path
                  .split("/")
                  .map(function (n) {
                    return escapeHtml(hierarchyNames[n]);
                  })
                  .join(" / ")) +
            "</h5>"
        )
      );
      return;
    }

    var pathEl = $(
      `<div data-index="${index}" data-path="${path}" class="${prefix}Path" style="margin-bottom: 5px;">
        <div class="${prefix}HospitalSection" style="margin-bottom: 10px; display: inline-block; margin-left: 5px;">
          <h5 style="display: inline-block;">Hospital</h5>
          <select class="form-control ${prefix}HospitalInput" style="width: auto; margin-left: 5px;"></select>
        </div>
        <div class="${prefix}DeptSection" style="margin-bottom: 10px; display: inline-block; margin-left: 15px;">
          <h5 style="display: inline-block;">Department</h5>
          <select class="form-control ${prefix}DeptInput" style="width: auto; margin-left: 5px; display: none;"></select>
        </div>
        <div class="${prefix}DivisionSection" style="margin-bottom: 10px; display: inline-block; margin-left: 15px;">
          <h5 style="display: inline-block;">Division</h5>` +
        (opts.divisionDropdown
          ? `<div class="dropdown ${prefix}DivisionInput" style="margin-left: 5px;vertical-align: top;display: inline-block;">
              <button class="btn btn-default dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true" style="display: inline-block;white-space: pre-line;text-align: left;"></button>
              <ul class="dropdown-menu" style="overflow-y: scroll; max-height: 400px;"></ul>
            </div>`
          : `<select class="form-control ${prefix}DivisionInput" style="width: auto; margin-left: 5px; display: none;"></select>`) +
        "  </div>" +
        (pathOptions.length > 1 || !!opts.removeAny
          ? `<span class="glyphicon glyphicon-remove ${prefix}PathRemove" style="padding-top: 10px;padding-left: 5px;padding-right: 0px; cursor: pointer;"></span>`
          : "") +
        "</div>"
    );
    container.append(pathEl);

    updateHierarchySelector({
      hospital: `.${prefix}Path[data-index="${index}"] .${prefix}HospitalInput`,
      sep1: `.${prefix}Path[data-index="${index}"] .${prefix}DeptSection`,
      dept: `.${prefix}Path[data-index="${index}"] .${prefix}DeptInput`,
      sep2: `.${prefix}Path[data-index="${index}"] .${prefix}DivisionSection`,
      division: `.${prefix}Path[data-index="${index}"] .${prefix}DivisionInput`,
      divisionDropdown: opts.divisionDropdown,
      prefix,
      hierarchy: hierarchy,
      hierarchyNames: hierarchyNames,
      noAllHospitals: opts.noAllHospitals,
      limitFilter: limitFilter,
      path: rawPath,
      fullyDetailed: opts.fullyDetailed,
      accessFilters: opts.accessFilters,
      updatePath: function (newPath, noRefresh = false) {
        if (opts.divisionDropdown) {
          var newPaths = Array.isArray(newPath) ? newPath : [newPath];
          var existingPaths = obj[opts.pathKey ?? "paths"].filter(
            function (oPath) {
              return oPath.split("/").slice(0, 2).join("/") == path;
            }
          );

          // Update existingPaths with changes from newPaths
          newPaths.map(function (nPath) {
            if (existingPaths.indexOf(nPath) == -1)
              obj[opts.pathKey ?? "paths"].push(nPath);
          });
          existingPaths.map(function (ePath) {
            if (newPaths.indexOf(ePath) == -1) {
              var index = obj[opts.pathKey ?? "paths"].indexOf(ePath);
              if (index >= 0) obj[opts.pathKey ?? "paths"].splice(index, 1);
              // obj.paths = obj.paths.filter(function(n) {
              //  return n != ePath;
              //})
            }
          });
        } else {
          if (
            obj[opts.pathKey ?? "paths"].some(function (path) {
              return path == newPath;
            })
          ) {
            // If this is the same as another, then remove this
            obj[opts.pathKey ?? "paths"].splice(index, 1);
          } else {
            obj[opts.pathKey ?? "paths"][index] = newPath;
          }
        }

        pathsChanged = true;
        if (!drawing && !noRefresh) onChange();
      }
    });

    $("." + prefix + "PathRemove")
      .unbind("click")
      .click(function () {
        // var index = parseInt(getProp(this, 'index'));
        var path = getProp(this, "path");
        if (path == undefined) path = "";

        // if (!!opts.divisionDropdown) debugger;

        if (path.length > 0) {
          var check = confirm(
            "Are you sure you want to remove " +
              path
                .split("/")
                .map((n) => hierarchyNames[n])
                .join("/") +
              "?"
          );
          if (!check) return;
        }

        var removedIndexes = [];
        obj[opts.pathKey ?? "paths"].map(function (oPath, i) {
          if (oPath.split("/").slice(0, 2).join("/") == path)
            removedIndexes.push(i);
        });
        var numRemoved = 0;
        removedIndexes.map(function (index) {
          obj[opts.pathKey ?? "paths"].splice(index - numRemoved, 1);
          numRemoved++;
        });

        obj[opts.pathKey ?? "paths"] = obj[opts.pathKey ?? "paths"].filter(
          function (path) {
            var badPath =
              getPath(breakPath(path, hierarchy, hierarchyNames), hierarchy) !=
              path;
            return !badPath;
          }
        );

        // obj[opts.pathKey ?? 'paths'].splice(index, 1);
        onChange();
      });
  });
  if (pathsChanged) onChange();
  drawing = false;
};
export { makeHierarchy };

/**
 *
 * @param { { path: { root?: string, paths?: string[] },
 *          sep1?: string,
 *          sep2?: string,
 *          hierarchy: import("@easycv/common/types/util").Hierarchy,
 *          hierarchyNames: import("@easycv/common/types/util").HierarchyNames,
 *          dept: string,
 *          division: string,
 *          hospital: string,
 *          limitFilter: {},
 *          noAllHospitals?: boolean,
 *          accessFilters?: string[],
 *          fullyDetailed?: boolean,
 *          divisionDropdown?: boolean,
 *          prefix?: string,
 *          updatePath: (newPaths: string, noRefresh: boolean) => void
 *          }} opts
 */
var updateHierarchySelector = function (opts) {
  var path = opts.path.root != null ? opts.path.root : opts.path;
  var pathObj = breakPath(path, opts.hierarchy, opts.hierarchyNames);

  opts.sep1 = opts.sep1 || opts.dept;
  opts.sep2 = opts.sep2 || opts.division;

  $(opts.dept + ", " + opts.sep1).hide();
  if (opts.division != null) $(opts.division + ", " + opts.sep2).hide();
  $(opts.hospital).empty();

  opts.updatePath = opts.updatePath || function () {};

  var limitFilterFocus = opts.limitFilter;
  if (numKeys(limitFilterFocus) != 1) {
    if (!opts.noAllHospitals)
      $(opts.hospital).append($('<option value="All" selected>All</option>'));
  } else {
    pathObj.hospitalId = keys(limitFilterFocus)[0];
    pathObj.hospital = opts.hierarchyNames[pathObj.hospitalId];
  }
  _.sortBy(keys(opts.hierarchy), function (key) {
    return opts.hierarchyNames[key];
  }).map(function (key) {
    if (
      !!opts.hierarchy[key].hidden &&
      pathObj.hospitalId != key &&
      !(opts.accessFilters ?? []).some(
        (path) => path == "" || path.split("/")[0] == key
      )
    ) {
      return;
    }
    if (empty(limitFilterFocus) || limitFilterFocus[key]) {
      $(opts.hospital).append(
        $(
          `<option value="${key}">${escapeHtml(
            opts.hierarchyNames[key]
          )}</option>`
        )
      );
    }
  });

  if (pathObj.hospital != null) {
    $(opts.hospital).val(pathObj.hospitalId);

    var limitFilterFocus = limitFilterFocus[pathObj.hospitalId] || {};
    $(opts.dept + ", " + opts.sep1).toggle(
      numKeys(opts.hierarchy[pathObj.hospitalId].depts) > 0
    );
    $(opts.dept).empty();

    // if (numKeys(limitFilterFocus) != 1) {
    $(opts.dept).append($('<option value="All" selected>All</option>'));
    // } else {
    //   pathObj.deptId = keys(limitFilterFocus)[0];
    //   pathObj.dept = opts.hierarchyNames[pathObj.deptId];
    // }
    _.sortBy(keys(opts.hierarchy[pathObj.hospitalId].depts), function (key) {
      return opts.hierarchyNames[key];
    }).map(function (key) {
      if (
        empty(limitFilterFocus) ||
        limitFilterFocus[key] ||
        pathObj.deptId == key
      ) {
        $(opts.dept).append(
          $(
            '<option value="' +
              key +
              '">' +
              escapeHtml(opts.hierarchyNames[key]) +
              "</option>"
          )
        );
      }
    });

    if (!empty(opts.hierarchy[pathObj.hospitalId].depts)) {
      if (
        pathObj.dept != null &&
        opts.hierarchy[pathObj.hospitalId].depts[pathObj.deptId] != null
      ) {
        if (opts.fullyDetailed) $(opts.dept).parent().removeClass("has-error");
        $(opts.dept).val(pathObj.deptId);
        var limitFilterFocus = limitFilterFocus[pathObj.deptId] || {};

        if (!empty(opts.hierarchy[pathObj.hospitalId].depts[pathObj.deptId])) {
          if (opts.division != null) {
            if (opts.fullyDetailed)
              $(opts.division).parent().removeClass("has-error");
            $(opts.division + ", " + opts.sep2).toggle(
              numKeys(
                opts.hierarchy[pathObj.hospitalId].depts[pathObj.deptId]
              ) > 0
            );
            var divEl = $(opts.division);

            if (!opts.divisionDropdown) {
              if (opts.fullyDetailed)
                divEl
                  .parent()
                  .toggleClass(
                    "has-error",
                    opts.hierarchy[pathObj.hospitalId].depts[pathObj.deptId][
                      pathObj.divisionId
                    ] == null
                  );

              divEl.empty();
              // if (numKeys(limitFilterFocus) != 1) {
              divEl.append($('<option value="All" selected>All</option>'));
              // } else {
              //   pathObj.divisionId = keys(limitFilterFocus)[0];
              //   pathObj.division = opts.hierarchyNames[pathObj.divisionId];
              // }
              _.sortBy(
                keys(opts.hierarchy[pathObj.hospitalId].depts[pathObj.deptId]),
                function (key) {
                  return opts.hierarchyNames[key];
                }
              ).map(function (key) {
                if (empty(limitFilterFocus) || limitFilterFocus[key]) {
                  divEl.append(
                    $(
                      '<option value="' +
                        key +
                        '">' +
                        escapeHtml(opts.hierarchyNames[key]) +
                        "</option>"
                    )
                  );
                }
              });

              if (pathObj.division != null) divEl.val(pathObj.divisionId);
            } else {
              var titleEl = divEl.find(".dropdown-toggle");

              var shownDivisions = opts.path.paths
                .map(function (p) {
                  return p.split("/")[2];
                })
                .filter(function (n) {
                  return n != null;
                });

              if (opts.fullyDetailed)
                divEl
                  .parent()
                  .toggleClass("has-error", shownDivisions.length == 0);
              titleEl.html(
                '<span class="divisionNames" style="margin-right: 5px;">' +
                  (shownDivisions.length == 0
                    ? "All"
                    : shownDivisions
                        .map((n) => opts.hierarchyNames[n])
                        .join(", ")) +
                  '</span><span class="caret"></span>'
              );

              var listEl = divEl.find("ul");
              listEl.empty();
              _.sortBy(
                keys(opts.hierarchy[pathObj.hospitalId].depts[pathObj.deptId]),
                (key) => opts.hierarchyNames[key]
              ).map(function (key) {
                if (empty(limitFilterFocus) || limitFilterFocus[key]) {
                  listEl.append(
                    $(
                      `<li class="divisionChoice" data-id="${key}">
                        <a href="#"><input type="checkbox" ${
                          shownDivisions.indexOf(key) >= 0 ? "checked" : ""
                        } style="margin-right: 10px;vertical-align: top;margin-bottom: 5px;">
                          ${escapeHtml(opts.hierarchyNames[key])}
                        </a>
                      </li>`
                    )
                  );
                }
              });

              listEl
                .find(".divisionChoice")
                .unbind("click")
                .click(function (e) {
                  var rootPath = getProp(this, "path");
                  var id = getProp(this, "id");
                  var selectPath = rootPath + "/" + id;
                  var wasSelected = opts.path.paths.indexOf(selectPath) >= 0;
                  var newPaths = clone(opts.path.paths);
                  if (wasSelected) {
                    newPaths = newPaths.filter(function (n) {
                      return n != selectPath;
                    });
                    if (newPaths.length == 0) newPaths = [rootPath];
                  } else {
                    newPaths.push(selectPath);
                    newPaths = newPaths.filter(function (n) {
                      return n != rootPath;
                    });
                  }

                  $(e.target)
                    .closest(".divisionChoice")
                    .find("input")
                    .prop("checked", !wasSelected);

                  var shownDivisions = newPaths
                    .map((p) => p.split("/")[2])
                    .filter((n) => n != null);

                  $(e.target)
                    .closest("." + opts.prefix + "Path")
                    .find("." + opts.prefix + "DivisionInput")
                    .find(".divisionNames")
                    .text(
                      shownDivisions.length == 0
                        ? "All"
                        : shownDivisions
                            .map((n) => opts.hierarchyNames[n])
                            .join(", ")
                    );
                  opts.updatePath(newPaths, true);
                  opts.path.paths = newPaths;
                  e.stopPropagation();
                });
            }
          }
        }
      } else {
        if (opts.fullyDetailed) $(opts.dept).parent().addClass("has-error");
      }
    }
  }

  if (getPath(pathObj, opts.hierarchy) != path)
    opts.updatePath(getPath(pathObj, opts.hierarchy));

  $(opts.hospital)
    .unbind("change")
    .on("change", function () {
      pathObj.hospitalId = $(this).val();
      pathObj.hospital = opts.hierarchyNames[pathObj.hospitalId];
      if (pathObj.hospital == "All") {
        pathObj.hospital = null;
        pathObj.hospitalId = null;
      }
      opts.updatePath(getPath(pathObj, opts.hierarchy));
    });

  $(opts.dept)
    .unbind("change")
    .on("change", function () {
      pathObj.deptId = $(this).val();
      pathObj.dept = opts.hierarchyNames[pathObj.deptId];
      if (pathObj.dept == "All") {
        pathObj.dept = null;
        pathObj.deptId = null;
      }
      opts.updatePath(getPath(pathObj, opts.hierarchy));
    });

  if (!opts.divisionDropdown) {
    $(opts.division)
      .unbind("change")
      .on("change", function () {
        pathObj.divisionId = $(this).val();
        pathObj.division = opts.hierarchyNames[pathObj.divisionId];
        if (pathObj.division == "All") {
          pathObj.division = null;
          pathObj.divisionId = null;
        }
        opts.updatePath(getPath(pathObj, opts.hierarchy));
      });
  }
};
export { updateHierarchySelector };

/**
 *
 * @param { { hospitalId: string,
 *            deptId: string,
 *            divisionId: string } } obj
 * @param { import("@easycv/common/types/util").Hierarchy } hierarchy
 * @returns
 */
var getPath = function (obj, hierarchy) {
  var title = [];
  if (hierarchy[obj.hospitalId] != null) {
    title.push(obj.hospitalId);
    if (hierarchy[obj.hospitalId].depts[obj.deptId] != null) {
      title.push(obj.deptId);
      if (hierarchy[obj.hospitalId].depts[obj.deptId][obj.divisionId] != null) {
        title.push(obj.divisionId);
      }
    }
  }

  return title.join("/");
};
export { getPath };

/**
 *
 * @param { string? } path
 * @param { import("@easycv/common/types/util").Hierarchy } hierarchy
 * @param { import("@easycv/common/types/util").HierarchyNames } hierarchyNames
 * @returns { { hospital: string,
 *              hospitalId: string,
 *              dept: string,
 *              deptId: string,
 *              division: string,
 *              divisionId: string }}
 */
var breakPath = function (path, hierarchy, hierarchyNames) {
  var splitPath = (path || "").split("/");
  var result = {};
  hierarchyNames = hierarchyNames || {};
  if (hierarchy[splitPath[0]] != null) {
    result.hospitalId = splitPath[0];
    result.hospital = hierarchyNames[splitPath[0]];
    if (hierarchy[splitPath[0]].depts[splitPath[1]] != null) {
      result.deptId = splitPath[1];
      result.dept = hierarchyNames[splitPath[1]];
      if (hierarchy[splitPath[0]].depts[splitPath[1]][splitPath[2]] != null) {
        result.divisionId = splitPath[2];
        result.division = hierarchyNames[splitPath[2]];
      }
    }
  }

  return result;
};
export { breakPath };

/**
 *
 * @param { string? } filter
 * @returns { (data: { paths: string[] }) => boolean }
 */
var matchesFilter = function (filter) {
  // Checks if data (often a user) is allowed within filter constraints.
  return function (data) {
    return (
      filter != null &&
      data.paths != null &&
      ((data.paths.length == 0 && filter == "") ||
        data.paths.some(function (path) {
          return (
            filter.length == 0 ||
            filter.split("/").every(function (item, i) {
              return item == path.split("/")[i];
            })
          );
        }))
    );
  };
};
export { matchesFilter };

/**
 *
 * @param { {filterList: string[], targetList: string[]}} param0
 * @returns
 */
var anyPathWithin = ({ filterList, targetList }) =>
  filterList.some(function (filter) {
    return matchesFilter(filter)({ paths: targetList });
  });
export { anyPathWithin };

// This takes two arrays of ['hos/dept/div', 'hos/dept/div'] and determine if there are any where a path in one list is either above or below the level of a path in the other list. For example, if one is the list of user paths and the other is a list of template paths, then a user at 'Hospital A/Dept A' will match with a template assigned to 'Hospital A' alone or 'Hospital A/Dept A/Division A' for example.
/**
 *
 * @param { string[] } pathList1
 * @param { string[] } pathList2
 * @returns { boolean }
 */
var anyPathOverlap = (pathList1, pathList2) =>
  anyPathWithin({ filterList: pathList1, targetList: pathList2 }) ||
  anyPathWithin({ filterList: pathList2, targetList: pathList1 });
export { anyPathOverlap };

/**
 *
 * @param { import("@easycv/common/types/user.js").User} user
 * @returns { boolean }
 */
export const checkIsSiteAdmin = function (user) {
  return (
    user != null && (user.settings?.accessFilters ?? []).some((n) => n == "")
  );
};

/**
 *
 * @param { import("@easycv/common/types/user.js").User } userDef
 * @param { import("@easycv/common/types/user.js").User } requestedBy
 * @param { { accessPositions: string[],
 *             paths: string[]
 *          }[] } paidDeptList
 */
export function validateRequestedPaths(userDef, requestedBy, paidDeptList) {
  // Auto-approve requested paths which are not for paid departments
  userDef.paths = (userDef.requestedPaths ?? []).filter((path) => {
    if (path.includes("h327")) return false; // Automatically remove Generic Hospital, re-added if none left below.
    if (userDef.paths.includes(path)) return true; // Already approved
    const noApprovalNeeded = !paidDeptList.some(
      (paidDept) =>
        anyPathWithin({ targetList: [path], filterList: paidDept.paths }) &&
        ((paidDept.accessPositions ?? {})[userDef.position] ?? true)
    );
    return (
      noApprovalNeeded ||
      (requestedBy != null &&
        (checkIsSiteAdmin(requestedBy) ||
          anyPathWithin({
            filterList: requestedBy?.settings?.accessFilters ?? [],
            targetList: [path]
          })))
    );
  });

  userDef.requestedPaths = userDef.requestedPaths.filter(
    (path) => !path.includes("h327")
  );

  if (userDef.paths.length === 0) {
    // Default to Generic Hospital if all paths require approval
    userDef.paths.push("h327");
    if (!userDef.requestedPaths.includes("h327"))
      userDef.requestedPaths.push("h327");
  }
}
