import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween.js";
import minMax from "dayjs/plugin/minMax.js";
import objectSupport from "dayjs/plugin/objectSupport.js";

dayjs.extend(isBetween);
dayjs.extend(objectSupport);
dayjs.extend(minMax);

export default dayjs;
