import _ from "lodash";
import { getYears, isTimeWithinRange, parseDate } from "./dateHelpers.js";
import { getResKey } from "./formatUtilities.js";
import { htmlToXML, reverseEscapeText } from "./frontendUtilities.js";
import { logDebug, logError } from "./logUtilities.js";
import { getPMdata } from "./pubMed.js";
import {
  after,
  breakUpType,
  clone,
  empty,
  getField,
  getKey,
  isDiff,
  isFunction,
  keys,
  removeSpecialChar
} from "./utilities.js";

// Could be automated from formats.
export const defaultCV = {
  lists: {},
  bio: {},
  narrative: {},
  notes: {},
  prefaces: {},
  type: "cv"
};

var sectionSort = function ({
  transform,
  flipChron,
  sortByEnd,
  customExportSettings,
  formatSettings,
  sectionFormat
}) {
  transform = transform ?? ((n) => n);

  let typeList =
    (sectionFormat?.items || []).find((item) => item.type == "type")?.types ??
    [];

  // Ugly logic :(
  // Need to unwrap the flipChron/reverseChron in all its uses eventually
  // I think flipChron is used as a UI sorting state, reverseChron is more template/output related
  let reverseChron = formatSettings?.order === "reverseChron";
  if (!!flipChron || !!customExportSettings?.reverseChron) {
    reverseChron = !reverseChron;
  }

  if (sectionFormat?.reverseChron) {
    reverseChron = !reverseChron;
  }

  let ignoreMonth = !!sectionFormat?.yearSortOnly;

  return [
    function (rawItem) {
      let item = transform(rawItem);
      return typeList != null && item != null
        ? typeList.findIndex((type) => breakUpType(type).val == item.type)
        : 0;
    },
    function (rawItem) {
      let item = transform(rawItem);
      if (sectionFormat == null) return 0;
      let tabItem = (sectionFormat.items || []).find(
        (item) => item.type == "customTab"
      );
      return tabItem != null
        ? tabItem.tabs.findIndex((tab) => tab.filter(item))
        : 0;
    },
    function (rawItem) {
      // Empty on top, unless submitted, then empty on bottom
      let item = transform(rawItem);
      return !(empty(item.time) || empty(item.time.start))
        ? 1
        : !!item.submitted || !!item.inPress
          ? 2
          : 0;
    }
  ]
    .concat(
      sortByEnd
        ? [
            function (rawItem) {
              // End (or start if no end)
              let key =
                (transform(rawItem).time || {}).end == null ? "start" : "end";
              return (
                (reverseChron ? -1 : 1) *
                getYears((transform(rawItem).time || {})[key], { ignoreMonth })
              );
            },
            function (rawItem) {
              // Start
              return (
                (reverseChron ? -1 : 1) *
                getYears((transform(rawItem).time || {}).start, { ignoreMonth })
              );
            }
          ]
        : [
            function (rawItem) {
              // Start
              return (
                (reverseChron ? -1 : 1) *
                getYears((transform(rawItem).time || {}).start, { ignoreMonth })
              );
            },
            function (rawItem) {
              // End
              return (
                (reverseChron ? -1 : 1) *
                getYears((transform(rawItem).time || {}).end, { ignoreMonth })
              );
            },
            function (rawItem) {
              // Start - Months Included
              return (
                (reverseChron ? -1 : 1) *
                getYears((transform(rawItem).time || {}).start)
              );
            },
            function (rawItem) {
              // End - Months Included
              return (
                (reverseChron ? -1 : 1) *
                getYears((transform(rawItem).time || {}).end)
              );
            }
          ]
    )
    .concat([
      function (rawItem) {
        let item = transform(rawItem);
        let title = item.PMID || "";
        return (title + "")
          .split(/(?:"|'|“)/)
          .join("")
          .trim();
      },
      function (rawItem) {
        // Last sort is alphabetical by the first field
        let item = transform(rawItem);
        let title = item.line || item.title || item.role || "";

        if (sectionFormat != null) {
          let flatItems = [];
          ((sectionFormat || { items: [] }).items || []).map(
            function (flatItem) {
              if (item.type == "group")
                flatItems = flatItems.concat(flatItem.includes);
              else flatItems.push(flatItem);
            }
          );

          let textItems = flatItems.filter(function (flatItem) {
            return (
              (flatItem.type == null || flatItem.type == "text") &&
              ["PMID", "comments"].indexOf(flatItem.name) == -1 &&
              (flatItem.filter == null || !!flatItem.filter(item))
            );
          });

          title = textItems
            .map((flatItem) => item[flatItem.name || ""])
            .join(" ")
            .toLowerCase();
        }

        return (title + "")
          .split(/(?:"|'|“)/)
          .join("")
          .trim();
      }
    ]);
};
export { sectionSort };

var sortField = function (
  fieldData,
  { sectionFormat, customExportSettings, formatSettings }
) {
  return _.orderBy(
    fieldData.filter((item) => item != null),
    sectionSort({ sectionFormat, customExportSettings, formatSettings })
  );
};
export { sortField };

var getNewFieldId = function (field, newDef) {
  return getNewId(newDef.lists[field], "i");
};
export { getNewFieldId };

var getNewId = function (list, prefix) {
  var id = null;
  var outOf = (Object.keys(list).length + 1) * 50;

  while (id == null || list[prefix + id] !== undefined) {
    id = Math.round(Math.random() * outOf);
  }

  return prefix + id;
};
export { getNewId };

var cvDiff = function (oldCV = {}, newCV = {}, noDepth) {
  const diffs = [];
  const checkObjDiffs = function (path, oldObj, newObj) {
    if (typeof oldObj !== "object" || typeof newObj !== "object") {
      if (!noDepth && oldObj !== newObj)
        diffs.push({ path: path, old: oldObj, new: newObj });
      return;
    }

    for (let i in newObj) {
      if (newObj[i] != null && oldObj[i] == null) {
        // New things
        diffs.push({ path: path + "." + i, old: null, new: newObj[i] });
      } else if (newObj[i] != null && isDiff(newObj[i], oldObj[i])) {
        if (noDepth)
          diffs.push({ path: path + "." + i, old: oldObj[i], new: newObj[i] });
        else checkObjDiffs(path + "." + i, oldObj[i], newObj[i]);
      }
    }

    for (let i in oldObj) {
      // Check for deletions
      if (newObj[i] == null && oldObj[i] != null) {
        diffs.push({ path: path + "." + i, old: oldObj[i], new: null });
      }
    }
  };

  let completeLists = [
    ...new Set(Object.keys(oldCV.lists).concat(Object.keys(newCV.lists)))
  ];

  for (let field of completeLists)
    checkObjDiffs(
      "lists." + field,
      oldCV?.lists[field] || {},
      newCV?.lists[field] || {}
    );

  // Object fields can be searched differently from lists
  const objFields = [
    "notes",
    "bio",
    "narrative",
    "prefaces",
    "researchInterests"
  ].concat(noDepth ? [] : ["acgmeEdits", "career", "biosketch"]); // For no depth, don't save acgmeEdits or career stuff to history
  objFields.map(function (objField) {
    checkObjDiffs(objField, oldCV[objField] || {}, newCV[objField] || {});
  });

  if (noDepth) {
    // Years or ids coming first
    ["acgmeEdits", "career", "biosketch"].map(function (objField) {
      var combinedKeys = _.uniq(
        Object.keys(oldCV[objField] || {}).concat(
          Object.keys(newCV[objField] || {})
        )
      );
      combinedKeys.map(function (key) {
        checkObjDiffs(
          objField + "." + key,
          (oldCV[objField] || {})[key] || {},
          (newCV[objField] || {})[key] || {}
        );
      });
    });
  }

  // For top level fields that aren't complex objects
  for (const [field, value] of Object.entries(newCV)) {
    if (_.isObject(value)) {
      continue;
    }

    if (_.isEqual(newCV[field], oldCV[field])) {
      continue;
    }

    diffs.push({ path: field, old: oldCV[field], new: value });
  }

  return diffs.filter((diff) => !["updatedAt"].includes(diff.path));
};
export { cvDiff };

// TODO This gives us an object:
//  {from: X, to: Y}
/**
 * Deep diff between two object-likes
 * @param  {Object} fromObject the original object
 * @param  {Object} toObject   the updated object
 * @return {Object}            a new object which represents the diff
 */
function deepDiff(fromObject, toObject) {
  const changes = {};

  const buildPath = (path, obj, key) =>
    _.isUndefined(path) ? key : `${path}.${key}`;

  const walk = (fromObject, toObject, path) => {
    for (const key of _.keys(fromObject)) {
      const currentPath = buildPath(path, fromObject, key);
      if (!_.has(toObject, key)) {
        changes[currentPath] = { from: _.get(fromObject, key) };
      }
    }

    for (const [key, to] of _.entries(toObject)) {
      const currentPath = buildPath(path, toObject, key);
      if (!_.has(fromObject, key)) {
        changes[currentPath] = { to };
      } else {
        const from = _.get(fromObject, key);
        if (!_.isEqual(from, to)) {
          if (_.isObjectLike(to) && _.isObjectLike(from)) {
            walk(from, to, currentPath);
          } else {
            changes[currentPath] = { from, to };
          }
        }
      }
    }
  };

  walk(fromObject, toObject);

  return changes;
}

// mom I'm on lodash
_.mixin({ deepDiff });

var findFirst = function (list, start, criteria) {
  var found_i = null;
  var found = null;
  list.some(function (section, i) {
    if (i < start) return;
    if (criteria(section[0], section[1])) {
      found_i = i;
      found = section;
      return true;
    }
  });

  return {
    index: found_i,
    element: found
  };
};
export { findFirst };

var findFirstLine = function (list, start, criteria) {
  var found_i = null;
  var found = null;
  list.some(function (section, i) {
    if (i < start) return;
    if (criteria(section)) {
      found_i = i;
      found = section;
      return true;
    }
  });

  return {
    index: found_i,
    element: found
  };
};
export { findFirstLine };

var flatten2 = function (data) {
  if (data == null) return "";
  if (data.name == "w:instrText") {
    return "";
  } else if (data.name == "w:br") {
    return "\n";
  } else if (data.name == "w:tab") {
    return "\t";
  } else if (data.text != null) {
    return data.text.slice(0, 9) == "HYPERLINK" ? "" : data.text;
  }

  return (
    reverseEscapeText(data.children.map(flatten2).join("")) +
    (data.name == "w:p" ? "\n" : "")
  );
};
export { flatten2 };

var flatten = function (data) {
  if (data == null) return "";
  return flatten2(data)
    .split("\n")
    .map(function (n) {
      return n.trim();
    })
    .filter(function (n) {
      return n.trim().length > 0;
    })
    .join("\n")
    .replace(/^\d+\./g, "")
    .trim();
};
export { flatten };

var importCV = function (document) {
  var sections = document.children
    .find(function (n) {
      return n.name == "w:body";
    })
    .children.map(function (data) {
      return [flatten(data), data];
    })
    .filter(function (pair) {
      return pair[0].length > 0;
    })
    .filter(function (item) {
      // Remove hidden tables
      var children = item[1].children.filter((n) => n.name == "w:tr");
      return (
        children.length == 0 ||
        !children.every((row) =>
          row.children[0].children.some((n) => n.name == "w:hidden")
        )
      );
    });

  // var headers = [
  //   'Education',
  //   'Postdoctoral Training',
  //   'Faculty Academic Appointments',
  //   'Appointments at Hospitals/Affiliated Institutions',
  //   'Other Professional Positions',
  //   'Major Administrative Leadership Positions' // ...
  // ];

  var guideTexts = ["For each item indicate", "List abstracts published"];

  var extractTable = function (opts) {
    opts = opts || {};
    var list = opts.list;
    var header = opts.header;
    var columns = opts.columns;
    var base = opts.base || {};
    var start = opts.start != null ? opts.start : 0;

    var firstData = findFirst(list, start, function (text, section) {
      text = text.split("\n")[0].toLowerCase();
      return (
        text
          .split("\n")
          .join("")
          .split(" ")
          .join("")
          .indexOf(header.toLowerCase().split(" ").join("")) >= 0 &&
        text.length < header.length * 3 + 30
      );
    });
    if (firstData.index == null) return {};

    var firstTables = [
      findFirst(list, firstData.index + 1, function (text, section) {
        if (columns.length == 1 && section.name == "w:p") return true; // For simple lines, okay to include lines.

        var realRows = section.children.filter(function (n) {
          return (
            flatten(n).length > 0 &&
            !n.children[0].children.some((n) => n.name == "w:hidden")
          );
        });
        if (realRows.length == 0) return false;
        var topCells = realRows[0].children.filter(function (n) {
          return flatten(n).length > 0;
        });
        if (topCells.length == 0) return false;
        var topLeftCell = flatten(topCells[0]);
        return (
          section.name == "w:tbl" &&
          guideTexts.every(function (n) {
            return topLeftCell.indexOf(n) == -1;
          }) &&
          (columns.indexOf("time") == -1 ||
            opts.timeOptional ||
            (/\d/.test(topLeftCell.trim().split("\n").join(" ")) &&
              topLeftCell.length < 100))
        );
      })
    ];

    if (opts.stopBefore != null) {
      // end of section (subheaders)
      var stopBeforeData = findFirst(
        list,
        firstData.index + 1,
        function (text, section) {
          text = text.split("\n")[0].toLowerCase();
          return opts.stopBefore.some(function (beforeHeader) {
            if (beforeHeader == header) return false;
            return (
              text
                .split("\n")
                .join("")
                .split(" ")
                .join("")
                .indexOf(beforeHeader.toLowerCase().split(" ").join("")) >= 0 &&
              text.length < beforeHeader.length * 3 + 30
            );
          });
        }
      );

      // Whoa too far (headers)
      if (opts.dontCross != null) {
        var dontCrossData = findFirst(
          list,
          start + 1,
          function (text, section) {
            text = text.split("\n")[0].toLowerCase();
            return opts.dontCross.some(function (beforeHeader) {
              if (beforeHeader == header) return false;
              return (
                text
                  .split("\n")
                  .join("")
                  .split(" ")
                  .join("")
                  .indexOf(beforeHeader.toLowerCase().split(" ").join("")) >=
                  0 && text.length < beforeHeader.length * 3 + 30
              );
            });
          }
        );

        // If dontCross is less than stopBefore, replace stopBefore with dontCross
        if (
          dontCrossData.index != null &&
          (stopBeforeData.index == null ||
            dontCrossData.index < stopBeforeData.index)
        )
          stopBeforeData = dontCrossData;
      }

      if (
        stopBeforeData.index != null &&
        stopBeforeData.index < firstTables[0].index
      ) {
        var content = list.slice(firstData.index + 1, stopBeforeData.index);
        var contentText = content
          .map(function (n) {
            return n[0];
          })
          .join("\n");
        // Content may be in text form here
        var rows = [];
        if (
          contentText.length > 0 &&
          opts.field == "editorialActivities" &&
          opts.base.type == "Ad hoc Reviewer"
        ) {
          // These are probably all just a list of ad hoc fields
          rows = contentText
            .split("\n")
            .filter(function (n) {
              return n.trim().length > 0;
            })
            .map(function (journalName) {
              var item = clone(base);
              item["journal"] = journalName;
              return item;
            });
        }

        if (contentText.length > 0) {
          console.log(header, columns, opts);
          console.log(contentText);
        }

        var set = {};
        rows.map(function (item) {
          var id = getNewId(set, "i");
          set[id] = item;
        });

        return set;
        // return rows;
      }
    }

    var nextItem = list[firstTables[0].index + 1];
    if (
      nextItem != null &&
      (nextItem[1].name == "w:tbl" ||
        nextItem == "" ||
        (nextItem[1].name == "w:p" && columns.length == 1))
    ) {
      // Get all other tables until there are no more tables
      list
        .slice(
          firstTables[0].index + 1,
          stopBeforeData.index != null ? stopBeforeData.index : undefined
        )
        .some(function (sectionData, sub_i) {
          if (
            sectionData[1].name != "w:tbl" &&
            sectionData[0] != "" &&
            !(nextItem[1].name == "w:p" && columns.length == 1)
          )
            return true;
          if (
            sectionData[1].name == "w:tbl" ||
            (nextItem[1].name == "w:p" && columns.length == 1)
          ) {
            firstTables.push({
              element: sectionData,
              index: firstTables[0].index + 1 + sub_i
            });
          }
        });
    }
    if (firstTables[0].index == null) return {};

    var rows = [];
    firstTables.map(function (firstTable) {
      var badRowCount = 0;
      if (firstTable.element[1].name == "w:p") {
        if (!Array.isArray(columns[0])) {
          var item = clone(base);
          item[columns[0]] = firstTable.element[0];
          rows.push(item);
        }
        return;
      }

      firstTable.element[1].children
        .filter((n) => n.name == "w:tr")
        .map(function (row) {
          if (
            flatten(row).length == 0 ||
            row.children[0].children.some((n) => n.name == "w:hidden")
          )
            return;
          var badRow = false;
          var item = clone(base);

          if (
            columns.length == 1 &&
            !Array.isArray(columns[0]) &&
            row.name == "w:tr" &&
            row.children.length == 1 &&
            row.children[0].children.length > 3
          ) {
            // Someone put many rows in a table with lines
            row.children
              .filter((n) => n.name == "w:tc")
              .map(function (cell, col_i) {
                cell.children
                  .filter((n) => n.name == "w:p")
                  .map(function (line, col_i) {
                    if (
                      flatten(line).length == 0 ||
                      opts.stopBefore.some(function (subheader) {
                        return (
                          flatten(line)
                            .toLowerCase()
                            .split(" ")
                            .join("")
                            .indexOf(
                              subheader.toLowerCase().split(" ").join("")
                            ) >= 0
                        );
                      })
                    )
                      return;
                    var item = clone(base);
                    item[columns[0]] = flatten(line);
                    rows.push(item);
                  });
              });
          } else if (
            columns.length == 1 &&
            !Array.isArray(columns[0]) &&
            row.name != "w:tc"
          ) {
            item[columns[0]] = flatten(row);
          } else {
            var col_shift = 0;
            row.children
              .filter((n) => n.name == "w:tc")
              .map(function (cell, i) {
                var col_i = i + col_shift;
                if (
                  opts.timeOptional &&
                  columns[col_i] == "time" &&
                  !/\d/.test(flatten(cell).trim().split("\n").join(" "))
                ) {
                  col_i++;
                  col_shift++;
                }
                if (columns[col_i] == null) return;
                if (badRow && rows.length > 0) item = rows.slice(-1)[0]; // Continue to other rows
                if (Array.isArray(columns[col_i])) {
                  var split = flatten(cell).split("\n");
                  columns[col_i].map(function (colName, col_i2_raw) {
                    if (badRow && col_i2_raw < badRowCount + 1) return;
                    var col_i2 = badRow
                      ? col_i2_raw - (1 + badRowCount)
                      : col_i2_raw;
                    if (col_i2 >= columns[col_i].length - 1) {
                      // Last one, keep all
                      var text = split.slice(col_i2).join("\n");
                      if (item[colName] == null) {
                        item[colName] =
                          colName == "time" ? parseDate(text)[0] : text;
                      } else if (colName != "time") {
                        item[colName] = item[colName] + "\n" + text;
                      }
                    } else {
                      var text = split[col_i2];
                      item[colName] =
                        colName == "time" ? parseDate(text)[0] : text;
                    }
                    if (colName == "time" && empty(parseDate(text)[0]?.start))
                      badRow = true;
                  });
                } else {
                  if (
                    columns[col_i] == "time" &&
                    empty(parseDate(flatten(cell))[0]?.start)
                  )
                    badRow = true;
                  item[columns[col_i]] =
                    columns[col_i] == "time"
                      ? parseDate(flatten(cell))[0]
                      : (item[columns[col_i]] != null
                          ? item[columns[col_i]] + "\n"
                          : "") + flatten(cell);
                }
              });
          }
          if (badRow) {
            badRowCount++;
          }
          if (!badRow && JSON.stringify(item) != JSON.stringify(base)) {
            rows.push(item);
            badRowCount = 0;
          }
        });
    });

    // Converting to sets
    var set = {};
    rows.map(function (item) {
      var id = getNewId(set, "i");
      set[id] = item;
    });

    return set; // return rows;
  };

  // Start extracting
  var newCV = {};

  // Get the bio
  var firstData = findFirst(sections, 0, function (text, section) {
    return text.indexOf("Curriculum Vitae") >= 0;
  });

  var bioTable = findFirst(sections, firstData.index, function (text, section) {
    return section.name == "w:tbl";
  });

  var bioRows = [
    {
      header: "Name",
      field: "name"
    },
    {
      header: "Office Address",
      field: "officeAddress"
    },
    {
      header: "Home Address",
      field: "homeAddress"
    },
    {
      header: "Work Phone",
      field: "workPhone"
    },
    {
      header: "Work Email",
      field: "workEmail"
    },
    {
      header: "Work FAX",
      field: "workFax"
    },
    {
      header: "Place of Birth",
      field: "birthplace"
    }
  ];

  newCV.bio = {};
  var rows = [];
  if (bioTable.element != null) {
    bioTable.element[1].children
      .filter((n) => n.name == "w:tr")
      .map(function (row) {
        if (flatten(row).length == 0) return;
        var columns = row.children.filter((n) => n.name == "w:tc");
        var cell1text = flatten(columns[0]);
        var cell2text = flatten(columns[1]);

        bioRows.map(function (bioRow) {
          if (cell1text.toLowerCase().indexOf(bioRow.header.toLowerCase()) >= 0)
            newCV.bio[bioRow.field] = cell2text;
        });
      });
  }

  // Try one for now.
  var tables = [
    {
      header: "Education",
      field: "education",
      columns: ["time", "degree", "secondaryInfo", "location"]
    },
    {
      header: "Postdoctoral Training",
      field: "postDoctoralTraining",
      columns: ["time", "title", "field", "location"]
    },
    {
      header: "Faculty Academic Appointments",
      field: "facultyAppointments",
      columns: ["time", "title", "field", "location"]
    },
    {
      header: "Appointments at Hospitals/Affiliated Institutions",
      field: "hospitalAppointments",
      columns: ["time", "title", "field", "location"]
    },
    {
      header: "Other Professional Positions",
      field: "otherPositions",
      columns: ["time", "title", "location"]
    },
    {
      header: "Major Administrative Leadership Positions",
      field: "administrativePositions",
      subheaders: [
        { subheader: "Local", type: "local" },
        { subheader: "Regional", type: "regional" },
        { subheader: "National", type: "national" },
        { subheader: "International", type: "international" }
      ],
      columns: ["time", "title", "location"]
    },
    {
      header: "Committee Service",
      field: "committeeService",
      subheaders: [
        { subheader: "Local", type: "local" },
        { subheader: "Regional", type: "regional" },
        { subheader: "National", type: "national" },
        { subheader: "International", type: "international" }
      ],
      columns: ["time", "title", ["location", "role"]]
    },
    {
      header: "Professional Societies",
      field: "societies",
      columns: ["time", "society", "title"]
    },
    {
      header: "Grant Review Activities",
      field: "grantReviewActivities",
      columns: ["time", "committee", ["location", "title"]]
    },
    {
      header: "Editorial Activities",
      field: "editorialActivities",
      subheaders: [
        {
          subheader: "Editorial Activities", // Ad Hoc Reviewer
          type: "Ad hoc Reviewer",
          timeOptional: true,
          columns: ["time", "journal"]
        },
        {
          subheader: "Reviewer", // Ad Hoc Reviewer
          type: "Ad hoc Reviewer",
          timeOptional: true,
          columns: ["time", "journal"]
        },
        {
          subheader: "Editorial Roles", // Other Editorial Roles
          type: "Other Editorial Roles",
          columns: ["time", "role", "journal"]
        }
      ]
    },
    {
      header: "Honors and Prizes",
      field: "honors",
      columns: ["time", "title", "location", "category"]
    },
    {
      header: "Report of Funded and Unfunded Projects",
      field: "projects",
      subheaders: [
        { subheader: "Past", type: "funded" },
        { subheader: "Current", type: "funded" },
        { subheader: "Current Unfunded", type: "unfunded" }
      ],
      columns: ["time", "description"]
    },
    {
      header: "Report of Local Teaching and Training",
      field: "teaching",
      subheaders: [
        {
          subheader: "Teaching of Students in Courses",
          columns: ["time", ["title", "audience"], ["location", "workTime"]]
        },
        {
          subheader:
            "Formal Teaching of Residents, Clinical Fellows and Research Fellows (post-docs)",
          columns: ["time", ["title", "audience"], ["location", "workTime"]]
        },
        {
          subheader: "Clinical Supervisory and Training Responsibilities",
          columns: ["time", ["title", "location"], "workTime"]
        },
        {
          subheader: "Research Supervisory and Training",
          type: "Laboratory and Other Research Supervisory and Training",
          columns: ["time", "title", "workTime"]
        },
        {
          subheader: "Formally Supervised Trainees",
          type: "Formally Mentored Trainees",
          columns: ["time", "name"]
        },
        {
          subheader: "Formally Mentored",
          type: "Formally Mentored Trainees",
          columns: ["time", "name"]
        },
        {
          subheader: "Mentored Trainees and Faculty",
          type: "Other Mentored Trainees and Faculty",
          columns: ["time", ["name", "description"]]
        },
        {
          subheader: "Formal Teaching of Peers",
          type: "Formal Teaching of Peers (e.g., CME and other continuing education courses)",
          columns: ["time", ["title", "name"], ["number", "location"]]
        }
      ]
    },
    {
      header: "Report of Local Teaching and Training", // Report of Regional, National and International Invited Teaching and Presentations, local comes first
      field: "presentations",
      subheaders: [
        {
          subheader: "Local Invited Presentations",
          type: "local"
        },
        {
          subheader: "Regional",
          type: "regional"
        },
        {
          subheader: "National",
          type: "national"
        },
        {
          subheader: "International",
          type: "international"
        }
      ],
      columns: ["time", ["title", "location"]]
    },
    {
      header: "Report of Clinical Activities and Innovations",
      field: "clinicalActivityAndInnovations",
      subheaders: [
        {
          subheader: "Current Licensure and Certification",
          columns: ["time", "title"]
        },
        {
          subheader: "Practice Activities",
          columns: ["time", "title", "location", "workTime"]
        },
        {
          subheader: "Clinical Innovations",
          columns: ["time", "description"]
        }
      ]
    },
    {
      header: "Report of Technological",
      field: "technologyAndScienceInnovations",
      columns: ["title", ["source", "description"]]
    },
    {
      header: "Report of Education of Patients and Service",
      field: "community",
      subheaders: [
        {
          subheader: "Activities",
          columns: ["time", ["role", "description"]]
        },
        {
          subheader:
            "Books, monographs, articles and presentations in other media",
          type: "Books, monographs, articles and presentations in other media",
          columns: ["time", "title", "contribution", "citation"]
        },
        {
          subheader:
            "Educational material or curricula developed for non-professional students",
          columns: ["time", "title", "contribution", "citation"]
        },
        {
          subheader: "Patient educational material",
          columns: ["time", "title", "contribution", "citation"]
        },
        {
          subheader: "Recognition",
          columns: ["time", "title", "organization"]
        }
      ]
    },
    {
      header: "Report of Scholarship",
      field: "scholarship",
      subheaders: [
        {
          subheader: ["Research Investigation"],
          type: "Research Investigations"
        },
        {
          subheader: ["Other peer-reviewed"],
          type: "Other peer-reviewed scholarship"
        },
        {
          subheader: ["Scholarship without named authorship"],
          type: "Scholarship without named authorship"
        },
        {
          subheader: [
            "Proceedings of meetings or other non-peer reviewed scholarship",
            "Proceedings"
          ],
          type: "Proceedings of meetings or other non-peer reviewed scholarship"
        },
        {
          subheader: [
            "Reviews, chapters, monographs and editorials",
            "Book chapters",
            "editorials"
          ],
          type: "Reviews, chapters, monographs and editorials"
        },
        {
          subheader: [
            "Books/textbooks for the medical or scientific community",
            "Books"
          ],
          type: "Books/textbooks for the medical or scientific community"
        },
        {
          subheader: ["Patents"],
          type: "Patents"
        },
        {
          subheader: ["Case reports"],
          type: "Case reports"
        },
        {
          subheader: ["Letters to the Editor"],
          type: "Letters to the Editor"
        },
        {
          subheader: [
            "Professional educational materials or reports, in print or other media"
          ],
          type: "Professional educational materials or reports, in print or other media"
        },
        {
          subheader: ["Clinical Guidelines and Reports"],
          type: "Clinical Guidelines and Reports"
        },
        {
          subheader: ["Thesis"],
          type: "Thesis"
        },
        {
          subheader: [
            "Abstracts, Poster Presentations and Exhibits Presented at Professional Meetings",
            "Abstracts"
          ],
          type: "Abstracts, Poster Presentations and Exhibits Presented at Professional Meetings"
        }
      ],
      columns: ["line"]
    }
  ];

  // Need to stop before next header

  newCV.lists = {};
  for (var field in defaultCV.lists) newCV.lists[field] = {};

  // Get table sections
  tables.map(function (tableData, t_i) {
    var nextHeaders = _.map(tables, "header"); // != null ? tables[t_i+1].header : null;
    nextHeaders.push("Narrative");
    // var nextHeader = tables[t_i+1] != null ? tables[t_i+1].header : null;

    if (tableData.header == "Report of Scholarship") {
      // Break into rows, then process

      // Don't remove tables with hidden rows here, sometimes embedded citation tools get hidden
      var allSections = document.children[0].children
        .map(function (data) {
          return [flatten(data), data];
        })
        .filter(function (pair) {
          return pair[0].length > 0;
        });

      var firstData = findFirst(allSections, 0, function (text, section) {
        text = text.toLowerCase();
        return (
          text
            .split("\n")
            .join("")
            .split(" ")
            .join("")
            .indexOf(tableData.header.toLowerCase().split(" ").join("")) >= 0 &&
          text.length < tableData.header.length * 3 + 30
        );
      });

      if (firstData.index == null) return;

      var stopBeforeData = findFirst(
        allSections,
        firstData.index + 1,
        function (text, section) {
          text = text.split("\n")[0].toLowerCase();
          return nextHeaders.some(function (beforeHeader) {
            return (
              text
                .split("\n")
                .join("")
                .split(" ")
                .join("")
                .indexOf(beforeHeader.toLowerCase().split(" ").join("")) >= 0 &&
              text.length < beforeHeader.length * 3 + 30
            );
          });
        }
      );

      var scholarshipSections = allSections.slice(
        firstData.index,
        stopBeforeData.index != null ? stopBeforeData.index : undefined
      );
      var scholarshipLines = [];
      scholarshipSections.map(function (sectionData) {
        scholarshipLines = scholarshipLines.concat(
          flatten(sectionData[1]).split("\n")
        );
      });

      var allSubheaders = [];
      tableData.subheaders.map(function (subheaderData) {
        allSubheaders = allSubheaders.concat(subheaderData.subheader);
      });

      var dontInclude = ["Non-peer reviewed scientific or medical publication"];
      allSubheaders = allSubheaders.concat(dontInclude);

      // Now go through subheaders to isolate sections.
      tableData.subheaders.map(function (subheaderData, sub_i) {
        var firstLine = findFirstLine(scholarshipLines, 0, function (text) {
          return subheaderData.subheader.some(function (subheader) {
            return (
              text
                .toLowerCase()
                .split("\n")
                .join("")
                .split(" ")
                .join("")
                .indexOf(subheader.toLowerCase().split(" ").join("")) >= 0 &&
              text.length < subheader.length * 3 + 30
            );
          });
        });
        if (firstLine.index == null) return;

        var avoidLines = [
          "For each item indica",
          "-Type of material ",
          "-If published ",
          "-Other peer-reviewed public",
          "-Research publications with",
          "-Description of ",
          "Research Investigation",
          "List abstracts published ",
          "indicates mentee",
          "Group materials into the following categories",
          "-Proceedings of meetings or",
          "-Reviews, chapters, monographs and editorials",
          "Non-peer reviewed scholarship in print or other media",
          "-Intended audience"
        ];

        var endLine = findFirstLine(
          scholarshipLines,
          firstLine.index + 1,
          function (text) {
            text = text.toLowerCase();
            if (text[0] == "-") return false;
            return allSubheaders.some(function (subheader) {
              if (subheaderData.subheader.indexOf(subheader) >= 0) return;
              return (
                text
                  .toLowerCase()
                  .split("\n")
                  .join("")
                  .split(" ")
                  .join("")
                  .indexOf(subheader.toLowerCase().split(" ").join("")) >= 0 &&
                text.length < subheader.length * 3 + 30
              );
            });
          }
        );

        var subset = scholarshipLines.slice(
          firstLine.index + 1,
          endLine.index != null ? endLine.index : undefined
        );
        subset = subset
          .map(function (text) {
            return text.replace(/^\d+\./g, "").trim(); // Remove any # before
          })
          .filter(function (text) {
            // Don't include headers
            if (
              text.trim().length == 0 ||
              avoidLines.some(function (avoidLine) {
                return text.indexOf(avoidLine) >= 0;
              })
            )
              return false;
            return !allSubheaders.some(function (subheader) {
              return (
                text
                  .toLowerCase()
                  .split("\n")
                  .join("")
                  .split(" ")
                  .join("")
                  .indexOf(subheader.toLowerCase().split(" ").join("")) >= 0 &&
                text.length < subheader.length * 3 + 30
              );
            });
          });

        if (subset.length > 0) {
          subset.map(function (line) {
            var row = clone({ type: subheaderData.type });

            var PMIDs = / [1-9]\d{6,10}/g.exec(line);
            if (PMIDs != null && PMIDs.length > 0) row.PMID = PMIDs[0].trim();

            row.line = line;

            var id = getNewId(getKey(newCV.lists, tableData.field, {}), "i");
            newCV.lists[tableData.field][id] = row;
            // return row;
          });
          // newCV.lists[tableData.field] = (newCV.lists[tableData.field] || []).concat();
        }
      });
    } else if (tableData.subheaders == null) {
      newCV.lists[tableData.field] = extractTable({
        list: sections,
        header: tableData.header,
        stopBefore: nextHeaders.slice(t_i + 1),
        columns: tableData.columns
      });
    } else {
      var firstData = findFirst(sections, 0, function (text, section) {
        text = text.toLowerCase();
        return (
          text
            .split("\n")
            .join("")
            .split(" ")
            .join("")
            .indexOf(tableData.header.toLowerCase().split(" ").join("")) >= 0 &&
          text.length < tableData.header.length * 3 + 30
        );
      });

      tableData.subheaders.map(function (subheaderData, sub_i) {
        var typeRows = extractTable({
          list: sections,
          header: subheaderData.subheader,
          field: tableData.field,
          timeOptional: subheaderData.timeOptional,
          columns: subheaderData.columns || tableData.columns,
          start: firstData.index, // Don't do +1 because of ad hoc editorial activities
          stopBefore: _.map(tableData.subheaders, "subheader"),
          dontCross: nextHeaders.slice(t_i + 1),
          base: {
            type: subheaderData.type || subheaderData.subheader
          }
        });

        getKey(newCV.lists, tableData.field, {});
        for (var id in typeRows) {
          if (newCV.lists[tableData.field][id] != null) {
            var newId = getNewId(newCV.lists[tableData.field], "i");
            newCV.lists[tableData.field][newId] = typeRows[id];
          } else newCV.lists[tableData.field][id] = typeRows[id];
        }
      });
    }
  });

  // Get everything after 'Narrative' to be Narrative
  var narrativeLine = findFirst(sections, 0, function (text, section) {
    return text.indexOf("Narrative") >= 0 && text.length < 100;
  });

  var narrSkip = [
    "The narrative should describe your major contributions",
    "In general, we suggest the following"
  ];

  if (narrativeLine.index != null) {
    newCV.narrative = { narrative: "" };
    sections.slice(narrativeLine.index + 1).map(function (section) {
      var narrativeText = flatten(section[1]);
      if (
        narrSkip.some(function (line) {
          return narrativeText.indexOf(line) >= 0;
        })
      )
        return;
      newCV.narrative.narrative += narrativeText;
    });
  }

  return newCV;
};
export { importCV };

var getSectionFormat = function (format, formats, section) {
  let sectionFormat = (formats[format]?.input ?? []).find(function (n) {
    return !_.isFunction(n) && (n.section || n.field) == section;
  });
  if (sectionFormat == null)
    throw new Error(
      "Section Format Undefined (" + format + "," + section + ")"
    );
  return sectionFormat;
};
export { getSectionFormat };

export const cvOutputData = function (cvDef, genOpts, callback) {
  var data = {};
  callback = callback || function () {};

  // set the templateVariables
  var now = new Date();
  data.genDate = dateFormat(now, "mmmm d, yyyy");
  data.lastUpdate = dateFormat(cvDef?.updatedAt ?? now, "mmmm d, yyyy");
  var outputSource = genOpts.outputSource;
  $("#cvExportLoading").css("display", "inline-block");

  var outputFilter = function (item) {
    if (genOpts.type == "full") return true;

    if (!item.time) {
      return false;
    }

    if (genOpts.filter) {
      return genOpts.filter(item.time);
    } else {
      return isTimeWithinRange(item.time, genOpts.start, genOpts.end);
    }
  };

  var getRes = function (map, item, i, opts) {
    opts = opts || {};

    var res = {};
    if (opts.field != null) res.field = opts.section || opts.field;
    if (item.id != null || opts.id != null) res.id = item.id || opts.id;
    for (var key in map) {
      if (isFunction(map[key])) {
        res[key] = map[key](item, i, cvDef, genOpts);
      } else {
        res[key] = map[key]
          .map(function (innerName) {
            if (isFunction(innerName)) {
              return removeSpecialChar(
                innerName(item, i, cvDef, genOpts) || ""
              );
            } else {
              return (
                getResKey(innerName, item, genOpts, {
                  details: opts.details,
                  groupDetails: opts.groupDetails,
                  outputSource: outputSource,
                  links: opts.links
                }) || ""
              );
            }
          })
          .filter(function (line) {
            return line.trim().length > 0;
          })
          .join("\n");

        if (opts.NL && res[key].length > 0) res[key] += "\n";
        if (["time", "t"].indexOf(key) == -1 && !genOpts.noXML && !opts.noXML)
          res[key] = htmlToXML(res[key], {
            font: outputSource.outputSettings.font,
            fontSize: outputSource.outputSettings.fontSize,
            links: opts.links
          }); // Everything except for times is now styled. Need to handle font differences.
      }
    }
    return res;
  };

  var getTable = function (list, map, sublistMaps, opts) {
    opts = opts || {};

    var data = [];
    var itemBundles = [];

    list.filter(outputFilter).map(function (item, i) {
      var subListData = [];
      for (var subList in sublistMaps) {
        (item[subList] || [])
          .filter(outputFilter)
          .filter((subItem, j) => subItem != null)
          .map(function (subItem, j) {
            var subRow = getRes(sublistMaps[subList], subItem, j, {
              id: item.id,
              field: opts.field,
              section: opts.section,
              noXML: true,
              links: opts.links,
              details: opts.details,
              groupDetails: opts.groupDetails
            }); // , NL: (j == item[subList].length - 1)
            subListData.push(subRow);
          });
      }

      var NL = opts.NL != null ? opts.NL : false;
      var newRow = getRes(map, item, i, {
        field: opts.field,
        section: opts.section,
        NL: NL,
        links: opts.links,
        details: opts.details,
        groupDetails: opts.groupDetails
      });

      var itemBundle = [];
      if (
        _.values(newRow).some(function (n) {
          return n != "";
        })
      )
        itemBundle.push(newRow);

      var seenSubListHashes = {};
      var removeIndexes = [];
      if (opts.sublistTimeKey != null) {
        subListData.map(function (subItem, j) {
          var hash = []; // Merging items with everything the same except for time and keeping only the first instance (with all the times listed)
          for (var k in subItem)
            if (
              !["id", "field", "t", "time"].includes(k) &&
              (opts.sublistTimeKey == null || k != opts.sublistTimeKey)
            )
              hash.push((subItem[k] + "").split("\n").join(""));

          if (hash.length == 0) return;
          var seenIndex = seenSubListHashes[hash.join(",")];
          if (seenIndex == null) {
            seenSubListHashes[hash.join(",")] = j;
          } else {
            subListData[seenIndex][opts.sublistTimeKey] =
              (subListData[seenIndex][opts.sublistTimeKey] + "")
                .split("\n")
                .join("") +
              ", " +
              subItem[opts.sublistTimeKey];
            removeIndexes.push(j);
          }
        });
      }

      subListData.map(function (subItem, j) {
        if (removeIndexes.indexOf(j) >= 0) return;
        subItem.parent = i;

        for (var key in subItem) {
          if (
            ["time", "t", "id", "parent", "field"].indexOf(key) == -1 &&
            !genOpts.noXML &&
            (subItem[key] || "").indexOf("<w:p>") == -1
          )
            subItem[key] = htmlToXML(subItem[key], {
              font: outputSource.outputSettings.font,
              fontSize: outputSource.outputSettings.fontSize,
              links: opts.links
            }); // Everything except for times is now styled. Need to handle font differences.
        }

        itemBundle.push(subItem);
      });

      itemBundles.push(itemBundle);
    });

    var seenHashes = {};
    var removeIndexes = [];
    itemBundles.map(function (bundle, i) {
      var item = bundle[0];
      var hash = []; // Merging items with everything the same except for time and keeping only the first instance (with all the times listed)
      for (var k in item)
        if (k != "time" && k != "t" && k != "id")
          hash.push((item[k] + "").split("\n").join(""));
      var seenIndex = seenHashes[hash.join(",")];
      if (seenIndex == null) {
        seenHashes[hash.join(",")] = i;
      } else if (!outputSource.settings?.disableAggregation) {
        var timeKey = item["t"] != null ? "t" : "time";
        itemBundles[seenIndex][0][timeKey] =
          (itemBundles[seenIndex][0][timeKey] + "").split("\n").join("") +
          ", " +
          item[timeKey]; // Merge dates
        itemBundles[seenIndex] = itemBundles[seenIndex].concat(bundle.slice(1)); // Add on all sublist
        removeIndexes.push(i);
      }
    });

    itemBundles.map(function (bundle, i) {
      if (removeIndexes.indexOf(i) >= 0) return;
      data = data.concat(bundle);
    });

    return data;
  };

  var getDataList = function (field, details, opts) {
    opts = opts || {};
    // If only one input format, filter by any types present
    var sectionFormat =
      (opts.formats[opts.contextState.format]?.input ?? []).filter(
        (n) =>
          !_.isFunction(n) && (n.section || n.field) == (opts.section ?? field)
      ).length == 1
        ? getSectionFormat(
            opts.contextState.format,
            opts.formats,
            opts.section ?? field
          )
        : null;

    var sortOpts = {
      formatSettings: opts.formats[opts.contextState.format]?.settings,
      customExportSettings: opts.contextState.customExportSettings,
      sectionFormat
    };

    var list = (
      opts.data != null
        ? opts.data(cvDef, details, opts)
        : sortField(
            getField(cvDef, field, { addId: true, sectionFormat }),
            sortOpts
          )
    )
      .filter(outputFilter)
      .filter((data) => data.hidden == null || !data.hidden);
    if (details.type != null)
      list = list.filter((data) => data.type == details.type);
    if (details.filter != null)
      list = list.filter((n) =>
        details.filter(n, {
          customExportSettings: opts.contextState.customExportSettings
        })
      );

    if (opts.data == null) {
      if (opts.detailFormat != null) {
        var timeType = null;
        ["t", "time"].map(function (key) {
          if (opts.detailFormat[key] != null)
            timeType = opts.detailFormat[key][0];
        });
        if (timeType != null && ["yearRange"].indexOf(timeType) >= 0) {
          sortOpts.ignoreMonth = true;
        }
      }
      if (details.sort != null && details.sort.type == "alphabetical") {
        list = _.sortBy(list, (item) =>
          (item[details.sort.key] || "").toLowerCase()
        );
      }
    }
    return list;
  };

  after(
    function (done) {
      var includeCitations = false;
      if (genOpts.contextState.customExportSettings != null) {
        includeCitations =
          (genOpts.contextState.customExportSettings.includeCitations != null &&
            !!genOpts.contextState.customExportSettings.includeCitations) ||
          (genOpts.contextState.customExportSettings.includeRCR != null &&
            !!genOpts.contextState.customExportSettings.includeRCR);
      }
      if (!includeCitations) return done();

      var allPMIDs = getField(cvDef, "scholarship")
        .filter(function (n) {
          return n.PMID != null;
        })
        .map(function (n) {
          return n.PMID;
        });

      getPMdata(allPMIDs, done, {
        checkCitationCounts: true,
        citationCountProgress: function (numDone) {
          $("#cvExportLoading").text(
            "Loading " + numDone + "/" + allPMIDs.length
          );
        },
        pmidList: genOpts.contextState.pmidList
      });
    },
    function () {
      for (const key in outputSource.output) {
        const outputDetails = outputSource.output[key];
        if (typeof outputDetails === "boolean") {
          data[key] = outputDetails;
        }
        const field = outputDetails.field;
        const section = outputDetails.section;
        const prefaces = cvDef.prefaces || {};

        let hasContent = false;

        const emptySections =
          !!genOpts.contextState?.customExportSettings?.emptySections;
        if (emptySections) hasContent = true;

        if (outputDetails.preface) {
          let mainPreface = null;
          if (outputDetails.type != null || outputDetails.key != null) {
            mainPreface =
              prefaces[
                (section || field) +
                  "+" +
                  (outputDetails.key || outputDetails.type).replace(/\./g, "")
              ];
          } else {
            mainPreface = prefaces[section || field];
          }
          if (mainPreface != null && mainPreface.trim().length > 0) {
            //} && (outputDetails.condition == null || outputDetails.condition(cvDef))) {
            data[key + "Pre"] = genOpts.noXML
              ? mainPreface
              : htmlToXML(mainPreface, {
                  font: outputSource.outputSettings.font,
                  fontSize: outputSource.outputSettings.fontSize,
                  links: genOpts.links
                }); // '\n\n'+preface+'\n';
            hasContent = true;
          } else {
            data[key + "Pre"] = "";
          }

          if (outputDetails.group) {
            for (const subKey in outputDetails.group) {
              const groupInfo = outputDetails.group[subKey];
              let groupPreface = null;
              if (groupInfo.preface != null && isFunction(groupInfo.preface)) {
                groupPreface = groupInfo.preface(cvDef);
              } else {
                const type = groupInfo.key || groupInfo.type;
                if (type == null) continue;
                groupPreface =
                  prefaces[(section || field) + "+" + type.replace(/\./g, "")];
              }
              if (groupPreface != null && groupPreface.trim().length > 0) {
                data[subKey + "Pre"] = genOpts.noXML
                  ? groupPreface
                  : htmlToXML(groupPreface, {
                      font: outputSource.outputSettings.font,
                      fontSize: outputSource.outputSettings.fontSize,
                      links: genOpts.links
                    }); //'\n\n'+preface+'\n';
                hasContent = true;
              } else {
                data[subKey + "Pre"] = "";
              }
            }
          }
        }

        if (outputDetails.toggle != null) {
          if (isFunction(outputDetails.toggle)) {
            data[key] = outputDetails.toggle(cvDef, outputFilter, {
              customExportSettings: genOpts.contextState.customExportSettings
            });
          } else {
            // toggle == field
            const sectionFormat = getSectionFormat(
              genOpts.contextState.format,
              genOpts.formats,
              outputDetails.toggle
            );
            data[key] =
              getField(cvDef, outputDetails.toggle, { sectionFormat })
                .filter(function (n) {
                  return outputDetails.filter != null
                    ? outputDetails.filter(n)
                    : true;
                })
                .some(outputFilter) ||
              hasContent ||
              emptySections;
          }
        } else if (outputDetails.totals) {
          data[key] = 0;
          outputDetails.totals.forEach((n) => {
            data[key] += data[n];
          });
        } else if (outputDetails.sections) {
          if (outputDetails.preface) {
            const type = outputDetails.key || outputDetails.type;
            const mainPreface =
              prefaces[
                (outputDetails.section || outputDetails.field) +
                  (type == null ? "" : "+" + type.replace(/\./g, ""))
              ];
            if (mainPreface != null && mainPreface.trim().length > 0) {
              data[key + "Pre"] = genOpts.noXML
                ? mainPreface
                : htmlToXML(mainPreface, {
                    font: outputSource.outputSettings.font,
                    fontSize: outputSource.outputSettings.fontSize,
                    links: genOpts.links
                  });
              hasContent = true; // Prefaces alone count as content
            } else {
              data[key + "Pre"] = "";
            }
          }

          const sectionList = isFunction(outputDetails.sections)
            ? outputDetails.sections(
                cvDef,
                genOpts,
                outputSource.outputSettings
              )
            : outputDetails.sections;
          const compiledSectionData = [];
          sectionList.map(function (secDetails) {
            const sectionField = secDetails.field || field;
            const sectionSection = secDetails.section || section;
            const list = getDataList(sectionField, secDetails, {
              data: secDetails.data || outputDetails.data,
              detailFormat: secDetails.format || outputDetails.format,
              contextState: genOpts.contextState,
              section: sectionSection ?? sectionField,
              formats: genOpts.formats
            });
            if (list.length > 0) hasContent = true;
            let sectionPreface = null;

            if (secDetails.preface != null && isFunction(secDetails.preface)) {
              sectionPreface = secDetails.preface(cvDef);
            } else {
              const type = secDetails.key || secDetails.type;
              sectionPreface =
                type == null
                  ? ""
                  : prefaces[
                      (sectionSection || sectionField) +
                        "+" +
                        type.replace(/\./g, "")
                    ];
            }
            let prefaceText = "";
            if (sectionPreface != null && sectionPreface.trim().length > 0) {
              prefaceText = genOpts.noXML
                ? sectionPreface
                : htmlToXML(sectionPreface, {
                    font: outputSource.outputSettings.font,
                    fontSize: outputSource.outputSettings.fontSize,
                    links: genOpts.links
                  }); //'\n\n'+preface+'\n';
              hasContent = true; // Prefaces alone count as content
            }

            const xmlOpts = {};
            xmlOpts.font = outputSource.outputSettings.font;
            xmlOpts.fontSize = outputSource.outputSettings.fontSize;
            for (const subKey in secDetails.titleStyle || {})
              xmlOpts[subKey] = secDetails.titleStyle[subKey];
            xmlOpts.noXML = !!genOpts.noXML;
            const title = !outputDetails.richTitle
              ? secDetails.title
              : htmlToXML(secDetails.title, xmlOpts);

            if (secDetails.header != null) {
              if (
                list.length > 0 ||
                emptySections ||
                outputDetails.allowEmptySections
              ) {
                compiledSectionData.push({
                  title: title,
                  header: true,
                  pre: prefaceText,
                  c: []
                });
              }
              return;
            }

            const content = getTable(
              list,
              secDetails.format || outputDetails.format,
              secDetails.sublist || outputDetails.sublist,
              {
                NL: outputDetails.NL,
                field: sectionField,
                section: sectionSection,
                links: genOpts.links,
                details: secDetails,
                groupDetails: outputDetails
              }
            );
            if (
              content.length == 0 &&
              prefaceText.length == 0 &&
              !emptySections &&
              !outputDetails.allowEmptySections
            )
              return;
            compiledSectionData.push({
              title: title,
              pre: prefaceText,
              c: content
            });
          });
          data[key + "Sections"] = compiledSectionData;
          if (!!outputSource.outputSettings.totalCount) {
            let entryTotal = 0;
            compiledSectionData.forEach((item) => {
              entryTotal += item.c.length;
            });
            data[key + "Total"] = entryTotal;
          }
          data[key + "Section"] = hasContent;
        } else if (outputDetails.group) {
          for (const groupKey in outputDetails.group) {
            const groupDetails = outputDetails.group[groupKey];
            const list = getDataList(field, groupDetails, {
              data: groupDetails.data || outputDetails.data,
              formats: genOpts.formats,
              section: outputDetails.section ?? field,
              contextState: genOpts.contextState
            });
            data[groupKey + "Section"] =
              list.length > 0 ||
              !!emptySections ||
              (data[groupKey + "Pre"] || "").length > 0;
            if (list.length > 0) hasContent = true;
            data[groupKey] = getTable(
              list,
              groupDetails.format || outputDetails.format,
              groupDetails.sublist || outputDetails.sublist,
              {
                NL: outputDetails.NL,
                field: field,
                section: section,
                links: genOpts.links,
                details: outputDetails,
                groupDetails: groupDetails
              }
            );
          }
          data[key + "Section"] = hasContent;
        } else if (field != null) {
          const list = getDataList(field, outputDetails, {
            data: outputDetails.data,
            formats: genOpts.formats,
            contextState: genOpts.contextState,
            section: outputDetails.section ?? outputDetails.field
          });
          data[key + "Section"] = list.length > 0 || hasContent;
          if (!!outputSource.outputSettings.totalCount) {
            data[key + "Total"] = list.length;
          }
          data[key] = getTable(
            list,
            outputDetails.format,
            outputDetails.sublist,
            {
              NL: outputDetails.NL,
              field: field,
              section: section,
              links: genOpts.links,
              details: outputDetails
            }
          );
        }
        if (outputDetails.map) {
          for (const mapKey in outputDetails.map) {
            const target = outputDetails.map[mapKey];
            const targetText = (field != null ? cvDef[field] || {} : cvDef)[
              target
            ];
            data[mapKey] = isFunction(target)
              ? target(cvDef, genOpts)
              : (targetText || "").trim().length == 0
                ? emptySections
                  ? " "
                  : null
                : removeSpecialChar(targetText || ""); // htmlToXML(targetText, { justText: true });
            if (!!outputDetails.xml && !genOpts.noXML && !isFunction(target))
              data[mapKey] =
                data[mapKey] == null
                  ? null
                  : htmlToXML(data[mapKey], {
                      font: outputSource.outputSettings.font,
                      fontSize: outputSource.outputSettings.fontSize,
                      links: genOpts.links
                    });
          }
        }
      }

      if (genOpts.contextState?.customExportSettings?.hideSections != null) {
        const hideSectionIndexes = {};
        for (const key in genOpts.contextState.customExportSettings
          ?.hideSections) {
          const splitKey = key.split("+");
          if (splitKey.length > 1) {
            // Section index
            getKey(hideSectionIndexes, splitKey[0], {})[splitKey[1]] = true;
          } else {
            data[key] = false;
          }
        }
        for (const sectionName in hideSectionIndexes) {
          data[sectionName] = data[sectionName].filter(
            (item, i) => hideSectionIndexes[sectionName][i] == null
          );
        }
      }

      $("#cvExportLoading").hide();
      callback(data);
    }
  );
};

var generateCV = function (cvDef, genOpts, callback) {
  genOpts = genOpts || {};
  callback = callback || function () {};

  var template =
    genOpts.outputSource.template || "/templateFiles/template_v2_4.docx?i=1";

  var oReq = new XMLHttpRequest();
  oReq.open("GET", template, true);
  oReq.responseType = "arraybuffer";

  oReq.onload = function (oEvent) {
    var arrayBuffer = oReq.response;
    var content = new Uint8Array(arrayBuffer);

    var convertSpaces = function (s) {
      return s.replace(new RegExp(String.fromCharCode(160), "g"), " ");
    };

    var Str2xml = function (str, errorHandler) {
      var parser = new DOMParser({
        errorHandler: errorHandler
      });
      return parser.parseFromString(str, "text/xml");
    };

    var decodeUtf8 = function (s) {
      var e;
      try {
        if (s === void 0) {
          return void 0;
        }
        return decodeURIComponent(encodeURIComponent(convertSpaces(s)));
      } catch (_error) {
        e = _error;
        console.error(s);
        console.error("could not decode");
        throw new Error("end");
      }
    };

    var maxArray = function (a) {
      return Math.max.apply(null, a);
    };

    var encodeUtf8 = function (s) {
      return decodeURIComponent(encodeURIComponent(s));
    };

    var xml2Str = function (xmlNode) {
      var a;
      a = new XMLSerializer();
      return a.serializeToString(xmlNode).split('xmlns="" ').join("");
    };

    var zip = new PizZip(content);

    var filePath = null;
    var loadFile = function (path) {
      filePath = path;
      return zip.files[filePath];
    };
    var file =
      loadFile("word/_rels/" + this.endFileName + ".xml.rels") ||
      loadFile("word/_rels/document.xml.rels") ||
      loadFile("ppt/slides/_rels/" + this.endFileName + ".xml.rels") ||
      loadFile("ppt/_rels/presentation.xml.rels");
    if (file === void 0) {
      debugger;
    }
    content = decodeUtf8(file.asText());

    var xmlDoc = Str2xml(content);
    var RidArray = function () {
      var _i, _len, _ref, _results;
      _ref = xmlDoc.getElementsByTagName("Relationship");
      _results = [];
      for (_i = 0, _len = _ref.length; _i < _len; _i++) {
        var tag = _ref[_i];
        _results.push(parseInt(tag.getAttribute("Id").substr(3)));
      }
      return _results;
    }.call(this);
    var maxRid = maxArray(RidArray);

    var addLink = function (id, url) {
      var relationships = xmlDoc.getElementsByTagName("Relationships")[0];
      var newTag = xmlDoc.createElement("Relationship");
      // newTag.namespaceURI = null;
      newTag.setAttribute("namespaceURI", null);
      newTag.setAttribute("Id", "rId" + id);
      newTag.setAttribute(
        "Type",
        "http://schemas.openxmlformats.org/officeDocument/2006/relationships/hyperlink"
      );
      newTag.setAttribute("Target", url);
      newTag.setAttribute("TargetMode", "External");
      return relationships.appendChild(newTag);
    };

    genOpts.links = {
      lastId: maxRid,
      set: {}
    };

    maxRid++;
    zip.file(filePath, encodeUtf8(xml2Str(xmlDoc)), {});

    var doc = new Docxtemplater();
    doc.loadZip(zip);
    let paragraphLoop = _.isUndefined(genOpts.outputSource.paragraphLoop)
      ? false
      : genOpts.outputSource.paragraphLoop;
    doc.setOptions({ linebreaks: true, paragraphLoop: paragraphLoop });

    cvOutputData(cvDef, genOpts, function (data) {
      for (var rId in genOpts.links.set) addLink(rId, genOpts.links.set[rId]);
      logDebug("cvOutputData.data", data);
      doc.setData(data);
      try {
        doc.render();
      } catch (error) {
        logError("generateCV", {
          message: error.message,
          name: error.name,
          stack: error.stack,
          properties: error.properties
        });
        throw error;
      }
      callback(
        doc.getZip().generate({
          type: "blob",
          mimeType:
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        })
      );
    });
  };
  oReq.send();
};
export { generateCV };

/**
 * @param { {} } itemDef
 * @param { { date?: number, month?: number, year?: number }} timeObj
 * @param { { monthRequired?: boolean,
 *            dateNotRequired?: boolean,
 *            monthOnly?: boolean,
 *            anyErr?: boolean } } opts
 */
var monthErr = function (timeObj, itemDef, opts) {
  opts = opts || {};
  var dateNotRequired =
    !!opts.dateNotRequired ||
    (itemDef != null && itemDef.inPress != null && !!itemDef.inPress);
  if (timeObj != null && timeObj.year != null) {
    if (timeObj.year > new Date().getFullYear() + 400)
      return "Too far in the future";
    if (timeObj.month == null && !!opts.monthRequired && !dateNotRequired) {
      if (timeObj.date == null && opts.dayRequired)
        return "Date and Month Required";
      return "Month Required";
    } else if (
      timeObj.month != null &&
      timeObj.date == null &&
      opts.dayRequired
    ) {
      return "Date Required";
    }
    if (
      timeObj.month == null &&
      timeObj.year > new Date().getFullYear() - 2 &&
      !dateNotRequired
    )
      return (
        "Month Required For " + (new Date().getFullYear() - 1) + " and Beyond"
      );
    if (timeObj.month != null && timeObj.month > 12) return "Impossible Month";
  } else if (timeObj != null && timeObj.month != null && !dateNotRequired)
    return "No Year";
  else return !dateNotRequired ? "Date Required" : null;
  return opts.monthOnly
    ? null
    : hasTimeErr(itemDef.time, itemDef, {
        noMonths: true,
        monthRequired: opts.monthRequired,
        dateNotRequired: opts.dateNotRequired
      });
};
export { monthErr };

/**
 * @param { {} } itemDef
 * @param { { date?: number,
 *            month?: number,
 *            year?: number }
 *         |{ start: { date?: number,
 *                     month?: number,
 *                     year?: number },
 *            end: { date?: number,
 *                   month?: number,
 *                   year?: number } } } timeDef
 * @param { { monthRequired?: boolean,
 *            dateNotRequired?: boolean,
 *            monthOnly?: boolean,
 *            anyErr?: boolean,
 *            noMonths?: boolean,
 *            endDateRequired?: boolean } } opts
 */
var hasTimeErr = function (timeDef, itemDef, opts) {
  opts = opts || {};
  var dateNotRequired =
    !!opts.dateNotRequired ||
    (itemDef != null && itemDef.inPress != null && !!itemDef.inPress); // Allowed to have no time if in press
  if (timeDef == null) return !dateNotRequired ? "No Date" : null;
  if (opts.noMonths) {
    if (
      timeDef.start != null &&
      !empty(timeDef.end) &&
      timeDef.end != "ongoing" &&
      getYears(timeDef.start) > getYears(timeDef.end)
    )
      return "Start after End";
    return null;
  }

  var monthTimeErrOpts = {
    monthOnly: true,
    monthRequired: opts.monthRequired,
    dateNotRequired: opts.dateNotRequired
  };
  if (
    !!opts.anyErr &&
    monthErr(timeDef.start, itemDef, monthTimeErrOpts) != null
  )
    return "Start " + monthErr(timeDef.start, itemDef, monthTimeErrOpts);
  if (
    (!empty(timeDef.end) && timeDef.end != "ongoing") ||
    Boolean(opts.endDateRequired)
  ) {
    if (
      !!opts.anyErr &&
      monthErr(timeDef.end, itemDef, monthTimeErrOpts) != null
    )
      return monthErr(timeDef.end, itemDef, monthTimeErrOpts);
    if (getYears(timeDef.start) > getYears(timeDef.end))
      return "Start after End";
  }
  return null;
};
export { hasTimeErr };

var getCareerAvailableYears = function (
  cvDef,
  conference,
  user,
  cvOwner,
  opts
) {
  opts = opts || {};
  var readAccessOverride =
    (user.settings.accessFilters || []).length > 0 &&
    (user.settings.conferenceTabAccess == "editAccess" ||
      user.settings.conferenceTabAccess == "readOnly");
  var mentorAccess =
    cvOwner != null &&
    ((conference.mentors[user.id] || {})[cvOwner.id] != null ||
      readAccessOverride);

  // Find which years are available. Should be just years which have data or which are open, or if you have mentorAccess
  var availableYearSet = {};

  if (cvDef != null && !opts.openOnly) {
    getKey(cvDef, "career", {});
    for (let year in cvDef.career) {
      if (!isNaN(parseInt(year)) && cvDef.career[year].status != "mentee") {
        availableYearSet[year] = true;
      }
    }
  }
  for (let year in conference.openYears) {
    if (!isNaN(parseInt(year)) && conference.openYears[year].open) {
      availableYearSet[year] = true;
    }
  }

  var year = new Date().getFullYear();
  var cutoff = conference.cutoffDate || "1/1"; // Before this = last year. New section starts on this date
  var month = cutoff.split("/")[0] || "1";
  var date = cutoff.split("/")[1] || "1";
  var transitionDate = new Date(month + "/" + date + "/" + year);
  var pastDateShift = Date.now() > transitionDate.getTime() ? 0 : 1;
  var baseYear = year - pastDateShift;

  if (availableYearSet[baseYear] == null && keys(availableYearSet).length > 0) {
    baseYear = _.sortBy(keys(availableYearSet), function (availYear) {
      return Math.abs(
        new Date(month + "/" + date + "/" + availYear).getTime() -
          transitionDate.getTime()
      );
    })[0]; // Closest option
  }

  if (!opts.openOnly && mentorAccess) {
    // Mentor Access gives you access to this year and two years back
    availableYearSet[baseYear] = true;
    availableYearSet[baseYear - 1] = true;
  }

  var availableYears = _.sortBy(
    keys(availableYearSet).map(function (n) {
      return parseInt(n);
    })
  );

  return { availableYears: availableYears, baseYear: baseYear };
};
export { getCareerAvailableYears };

// TODO Only used for logging, can we remove and use something else?
export function getDeltaCV(preCV, postCV) {
  var deltaObj = {};

  for (var key in postCV) {
    if (key == "lists") {
      for (var section in postCV.lists) {
        for (var id in postCV.lists[section]) {
          if (
            isDiff(postCV.lists[section][id], preCV?.lists?.[section]?.[id])
          ) {
            getKey(getKey(deltaObj, "lists", {}), section, {})[id] =
              postCV.lists[section][id];
          }
        }
      }
    } else if (["acgmeEdits", "career", "biosketch"].includes(key)) {
      for (var year in postCV[key]) {
        if (isDiff(postCV[key][year], preCV?.[key]?.[year])) {
          getKey(deltaObj, key, {})[year] = postCV[key][year];
        }
      }
    } else {
      if (isDiff(postCV[key], preCV[key])) deltaObj[key] = postCV[key];
    }
  }

  return deltaObj;
}

export const addEntryCountToSectionTitle = function (
  section,
  fieldName,
  cvDef
) {
  let field = getField(cvDef, fieldName);

  let entryCount = field.filter((item) => {
    let typeMatch =
      section.type == null ||
      (section.type != null && item.type == section.type);
    let filterMatch =
      section.filter == null ||
      (section.filter != null && section.filter(item));
    return typeMatch && filterMatch;
  }).length;

  let newSection = {
    type: section.type,
    title: section.title + " (" + entryCount + ")",
    filter: section.filter
  };

  return newSection;
};

export const generateSectionsFromSectionsArray = function (
  localThis,
  cvDef,
  outputSettings
) {
  let field = localThis.field;
  let isIncluded = outputSettings.totalCount;
  return localThis.sectionsArray.map(function (item) {
    return isIncluded ? addEntryCountToSectionTitle(item, field, cvDef) : item;
  });
};
